import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { EntityPageFocusType } from '@xing-com/crate-common-graphql-types';
import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { useFeatureSwitch, useViewerData } from '@xing-com/hub';

import type { AppcuesCompanyData } from '../../types/appcues';
import { GetAppcuesDataDocument } from './GetCompanyInfo.gql-types';
import type { GetAppcuesDataQuery } from './GetCompanyInfo.gql-types';

const APPCUES_FOCUSTYPES: Array<string> = [EntityPageFocusType.Company];
export const APPCUES_FEATURESWITCH = 'use_appcues';

export type HookAppcuesData = {
  hasAppcues: boolean;
  user?: Record<string, unknown>;
  appcuesComanyData: AppcuesCompanyData;
};

export const useQueryAppcuesData = (): HookAppcuesData => {
  const intl = useIntl();
  const { data } = useViewerData();
  const user = data?.user;
  const { pageContext } = usePageContext() ?? {};
  const { isEditor } = useEditContext();

  const companyId = pageContext?.companyId;
  const focusType = pageContext?.focusType || '';

  const hasFeatureSwitch = useFeatureSwitch(APPCUES_FEATURESWITCH, false);
  const hasFocusType = Boolean(APPCUES_FOCUSTYPES.includes(focusType));
  const hasRole = Boolean(isEditor);

  const hasAppcues = hasFeatureSwitch && hasFocusType && hasRole;

  const [appcuesComanyData, setAppcuesCompanyData] =
    useState<AppcuesCompanyData>(null);

  const onCompletedQuery = (data: GetAppcuesDataQuery) => {
    setAppcuesCompanyData({
      companyId: companyId ?? '',
      permissions: data?.company?.userContext?.userRole ?? '',
      companyName: data?.company?.companyName ?? '',
      contractType: data?.company?.contractPackageName ?? '',
      companySizeId: data?.company?.companySizeId ?? '',
      companySize: data?.company?.companySize ?? '',
      createdDate: data?.company?.createdAt ?? '',
      hasXamAccount: data?.company?.hasXamAccount ?? '',
      industry: data?.company?.industry?.localizationValue ?? '',
      industryId: data?.company?.industry?.id ?? '',
      language: intl?.locale ?? '',
      pageSlug: pageContext?.pageSlug ?? '',
    });
  };

  useQuery(GetAppcuesDataDocument, {
    variables: {
      id: companyId ?? '',
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    skip: !hasAppcues || !companyId,
    onCompleted: onCompletedQuery,
  });

  return {
    hasAppcues,
    user,
    appcuesComanyData: hasAppcues ? appcuesComanyData : null,
  };
};

export default useQueryAppcuesData;
