import { useQuery } from '@apollo/client';

import {
  ModuleHeader,
  usePageContext,
  useEditContext,
  EmptyState,
  useModuleContext,
  WarningMessageBox,
} from '@xing-com/crate-entity-pages-common';

import MapIcon from '../../assets/mapIcon.svg';
import { LocationsDocument } from '../../graphql/queries/locations.gql-types';
import { Location } from '../location/location';
import { LocationSkeleton } from './location-container.skeleton';

type RenderErrorType = {
  refetch: () => void;
};

type LocationsContainerType = {
  pageSlug: string;
};

const RenderError = ({ refetch }: RenderErrorType) => (
  <div data-testid={'errorContainer'}>
    <WarningMessageBox
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

const LocationsContainer = ({ pageSlug }: LocationsContainerType) => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const { moduleContext } = useModuleContext();

  const { data, loading, error, refetch } = useQuery(LocationsDocument, {
    variables: { id: pageSlug },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const mainLocation = data?.entityPageLocations?.collection.find(
    (location) => location.highlighted === true
  );

  const showLocationEmptyState =
    !error && !loading && !mainLocation && isEditing;

  if (loading) {
    return (
      <>
        <ModuleHeader headlineCopyKey="EP_LOCATIONS_HEADLINE" />
        <LocationSkeleton />
      </>
    );
  }

  if (showLocationEmptyState) {
    return (
      <>
        <ModuleHeader
          editTestId="EDIT_BUTTON_ON_LOCATIONS"
          headlineCopyKey="EP_LOCATIONS_HEADLINE"
          editTo={`${pageContext?.basePath}/${pageSlug}/edit/locations`}
        />
        <EmptyState
          icon={MapIcon}
          buttonProps={{
            to: `/pages/${pageSlug}/edit/locations`,
            // @ts-expect-error FIXME: SC6
            copyKey: 'EP_LOCATION_EMPTY_STATE_BUTTON',
          }}
          headlineCopyKey={'EP_LOCATION_EMPTY_STATE_TITLE'}
          textCopyKey={'EP_LOCATION_EMPTY_STATE_TEXT'}
        />
      </>
    );
  }

  if (mainLocation) {
    return (
      <div data-testid="LOCATIONS_MODULE">
        <ModuleHeader
          editTestId="EDIT_BUTTON_ON_LOCATIONS"
          headlineCopyKey="EP_LOCATIONS_HEADLINE"
          editTo={`${pageContext?.basePath}/${pageSlug}/edit/locations`}
        />
        <Location
          label={mainLocation.label}
          city={mainLocation.city}
          address={mainLocation.address}
          postcode={mainLocation.postcode}
          country={mainLocation.country}
          coordinates={mainLocation.coordinates}
          email={mainLocation.email}
          phoneNumber={mainLocation.phoneNumber}
          faxNumber={mainLocation.faxNumber}
          websiteURL={mainLocation.websiteURL}
          intersected={!!moduleContext?.intersected}
          trackingModuleType="Main"
        />
      </div>
    );
  }

  return (
    <>
      <ModuleHeader
        editTestId="EDIT_BUTTON_ON_LOCATIONS"
        headlineCopyKey="EP_LOCATIONS_HEADLINE"
        editTo={`${pageContext?.basePath}/${pageSlug}/edit/locations`}
      />
      <RenderError refetch={refetch} />
    </>
  );
};

export default LocationsContainer;
