import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceM, spaceXL } from '@xing-com/tokens';

export const ContactsWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: ${spaceL};

  @media ${mediaConfined} {
    grid-template-columns: repeat(2, 50%);
    gap: ${spaceXL};
  }
`;

// Skeleton

export const SkeletonContactsWrapper = styled.div`
  display: flex;
  width: 380px;
`;

export const SkeletonProfileImage = styled.div`
  height: 68px;
  width: 68px;
  flex-shrink: 0;
  margin-right: ${spaceM};
`;

export const SkeletonProfileInfo = styled.div`
  width: 100%;
`;
