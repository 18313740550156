import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  EntityPageCoverMediaType,
  EntityPageFocusType,
} from '@xing-com/crate-common-graphql-types';
import type {
  ErrorType,
  ImageUploadOnPublishData,
} from '@xing-com/crate-entity-pages-common';
import {
  ExternalEditInfoBanner,
  ImageUpload,
  DialogContextProvider,
  useDialogContext,
  NotSavedDialog,
  SmallGrid,
  usePageContext,
  useErrorContext,
} from '@xing-com/crate-entity-pages-common';
import {
  trackEditOpening,
  trackEditSaving,
  trackDelete,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { useFeatureSwitch } from '@xing-com/hub';
import { IconImage, IconUpload, IconTrash } from '@xing-com/icons';
import { Menu } from '@xing-com/menu';
import { OmView } from '@xing-com/platform-layout-om';
import { usePopOver } from '@xing-com/pop-over';

import { EntityPageDeleteCoverDocument } from '../../../graphql/mutations/delete-cover.gql-types';
import { EntityPageUpdateCoverDocument } from '../../../graphql/mutations/update-cover.gql-types';
import * as Styled from './edit-cover.styles';
import { UpdateCover } from './update-cover';
import { UpsellBannerCompanyHeaderBanner } from './upsell-banner/upsell-banner-company-header-banner';
import { UpsellBannerContainer } from './upsell-banner/upsell-banner-container';
import { UpsellBannerUploadImage } from './upsell-banner/upsell-banner-upload-image';

export const ENTITY_PAGES_UNFENCE_HEADER = 'entity_pages_unfence_header';

type RenderEditCoverMenuProps = {
  hasCover?: boolean;
  isFreePage?: boolean;
  onDeleteCover?: () => void;
  onUpdateCover?: () => void;
};
const RenderEditCoverMenu: FC<RenderEditCoverMenuProps> = ({
  hasCover,
  isFreePage,
  onDeleteCover = () => undefined,
  onUpdateCover = () => undefined,
}) => {
  const { $t } = useIntl();
  const popOver = usePopOver();
  const { showError } = useErrorContext();
  const { pageContext } = usePageContext() ?? {};
  const {
    setDataChanged,
    dataChanged,
    dialogConfirmation,
    setDialogConfirmation,
  } = useDialogContext();

  const [isEditCrop, setIsEditCrop] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hasFenceFS = useFeatureSwitch(ENTITY_PAGES_UNFENCE_HEADER, false);
  const isFreeHeaderProfile =
    hasFenceFS &&
    isFreePage &&
    pageContext?.focusType === EntityPageFocusType.Company;

  const omViewHandle = useRef<any | undefined>(null);
  const closeOmView = useRef<() => void | null>();

  const displayError = (error: ErrorType) => {
    showError({
      message: 'EP_GENERAL_FEEDBACK_ERROR',
      error,
    });
  };

  const [updateCover] = useMutation(EntityPageUpdateCoverDocument, {
    onCompleted: (data) =>
      data?.entityPageUpdateCover?.error &&
      displayError(data.entityPageUpdateCover.error),
    onError: (error) => displayError(error),
  });
  const [deleteCover] = useMutation(EntityPageDeleteCoverDocument, {
    onCompleted: (data) => {
      if (data?.entityPageDeleteCover?.error) {
        displayError(data.entityPageDeleteCover.error);
      }
    },
    onError: (error) => displayError(error),
  });

  const handleClickMenuItem = (isEditCrop = false) => {
    if (isFreeHeaderProfile) {
      if (pageContext?.focusType && pageContext?.pageId) {
        trackEditOpening({
          isFreePage,
          focusType: pageContext?.focusType,
          itemId: pageContext?.pageId,
          module: 'header',
          part: 'image',
        });
      }
    } else {
      if (pageContext?.focusType && pageContext?.pageId) {
        trackEditOpening({
          focusType: pageContext?.focusType,
          itemId: pageContext?.pageId,
          module: 'header',
          part: 'image',
        });
      }
    }

    setIsEditCrop(isEditCrop);
    popOver.handleHide();
    omViewHandle.current();
  };

  const handleDeleteClick = () => {
    popOver.handleHide();
    setShowDialog(true);
  };
  const handleConfirmDelete = async () => {
    setIsLoading(true);
    const { data } = await deleteCover({
      variables: {
        id: pageContext?.pageSlug as string,
      },
    });

    if (!data?.entityPageDeleteCover?.error) {
      if (isFreeHeaderProfile) {
        if (pageContext?.focusType && pageContext?.pageId) {
          trackDelete({
            focusType: pageContext?.focusType,
            itemId: pageContext?.pageId,
            isFreePage: !!isFreePage,
          });
        }
      }

      onDeleteCover();
    }
    setIsLoading(false);
    setShowDialog(false);
  };

  const handleDiscard = () => {
    if (dataChanged) {
      setDialogConfirmation({
        dialogAction: () => {
          setDialogConfirmation(null);
          setDataChanged(false);
          closeOmView.current?.();
        },
      });
    } else {
      closeOmView.current?.();
    }
  };

  const handleOnUpload = async ({
    cropped,
    original,
  }: ImageUploadOnPublishData) => {
    const { data } = await updateCover({
      variables: {
        id: pageContext?.pageSlug as string,
        coverMediaType: EntityPageCoverMediaType.Image,
        coverMediaUploadId: cropped.id,
        originalCoverMediaUploadId: original?.id,
      },
    });

    const error = data?.entityPageUpdateCover?.error;

    if (error) {
      displayError(error);
    } else {
      setDataChanged(false);
      closeOmView.current?.();
      onUpdateCover();
    }
  };
  const handleOnUpdateCover = ({
    cropped,
    original,
  }: ImageUploadOnPublishData) => {
    if (pageContext?.pageId && pageContext?.focusType) {
      trackEditSaving({
        focusType: pageContext?.focusType,
        itemId: pageContext?.pageId,
        module: 'header',
        part: 'image',
      });
    }
    handleOnUpload({ cropped, original });
  };

  const addMobileGuideline = () => {
    const mobileGuideline = document.getElementById('mobileGuideline');
    if (!mobileGuideline) {
      const node = document.createElement('div');
      node.id = 'mobileGuideline';
      node.className = 'mobileGuidelineContainer';
      const x = document.getElementsByClassName('reactEasyCrop_CropArea')[0];

      x.appendChild(node);
    }
  };

  useEffect(() => {
    return () => {
      closeOmView.current && closeOmView.current();
    };
  }, []);

  return (
    <>
      <Styled.GlobalEditMenuStyles />
      <Styled.Dialog
        show={showDialog}
        data-testid="DELETE_DIALOG"
        isDestructive={true}
        loading={isLoading}
        headline={$t({ id: 'EP_DELETE_COVER_DIALOG_HEADLINE' })}
        text={$t({ id: 'EP_DELETE_COVER_DIALOG' })}
        cancelLabel={$t({ id: 'EP_DELETE_COVER_DIALOG_KEEP' })}
        confirmLabel={$t({ id: 'EP_DELETE_COVER_DIALOG_CONFIRM' })}
        onCancel={() => setShowDialog(false)}
        onConfirm={handleConfirmDelete}
      />
      <Styled.CoverEditButton
        size="small"
        showIcon
        data-cy="EDIT_BUTTON_ON_COVER_IMAGE"
        data-testid={'EDIT_BUTTON_ON_COVER_IMAGE'}
        onClick={popOver.handleShow}
        innerRef={popOver.triggerRef}
      />
      <OmView
        interceptClose={() => handleDiscard()}
        trigger={(activateOmView: () => void) => {
          omViewHandle.current = activateOmView;
          return (
            <Menu
              dimmerTitle="close menu"
              onOutsideClick={popOver.handleHide}
              triggerRef={popOver.triggerRef}
              show={popOver.show}
            >
              <Styled.ListWrapper>
                <Styled.ListItem
                  data-testid="COVER_IMAGE_UPLOAD"
                  onClick={() => handleClickMenuItem()}
                >
                  <IconUpload width={24} height={24} />
                  <Styled.Link noMargin size="small">
                    {$t({ id: 'EP_UPLOAD_COVER' })}
                  </Styled.Link>
                </Styled.ListItem>
                {hasCover && (
                  <>
                    <Styled.ListItem
                      data-testid="COVER_IMAGE_EDIT"
                      onClick={() => handleClickMenuItem(true)}
                    >
                      <IconImage width={24} height={24} />
                      <Styled.Link noMargin size="small">
                        {$t({ id: 'EP_EDIT_SECTION' })}
                      </Styled.Link>
                    </Styled.ListItem>
                    <Styled.ListItem
                      data-testid="COVER_IMAGE_DELETE"
                      onClick={handleDeleteClick}
                    >
                      <IconTrash width={24} height={24} />
                      <Styled.Link noMargin size="small">
                        {$t({ id: 'EP_DELETE_COVER' })}
                      </Styled.Link>
                    </Styled.ListItem>
                  </>
                )}
              </Styled.ListWrapper>
            </Menu>
          );
        }}
      >
        {({ handleClose }: { handleClose: () => void }) => {
          closeOmView.current = handleClose;

          return (
            <SmallGrid>
              {isFreeHeaderProfile && (
                <UpsellBannerContainer
                  initialStep={0}
                  isEditCrop={!!isEditCrop}
                >
                  <UpsellBannerUploadImage
                    onDiscard={handleDiscard}
                    onCancel={() => handleClose()}
                    onDragCrop={() => setDataChanged(true)}
                    onCropReady={() => addMobileGuideline()}
                  />
                  <UpsellBannerCompanyHeaderBanner
                    onClickBanner={handleOnUpload}
                  />
                </UpsellBannerContainer>
              )}
              {!isFreePage && (
                <>
                  {isEditCrop ? (
                    <UpdateCover
                      aspectRatio={8 / 2}
                      imageType="cover"
                      isCover={true}
                      descriptionCopyKey="EP_COVER_CROP_DESCRIPTION"
                      onPublish={handleOnUpdateCover}
                      onCancel={() => handleClose()}
                      onDiscard={() => handleDiscard()}
                      onDragCrop={() => setDataChanged(true)}
                      onCropReady={() => addMobileGuideline()}
                    />
                  ) : (
                    <ImageUpload
                      aspectRatio={8 / 2}
                      shouldUploadOriginal={true}
                      onCropReady={() => addMobileGuideline()}
                      onDiscard={() => {
                        if (dataChanged) handleDiscard();
                        else handleClose();
                      }}
                      onFileSelected={() => setDataChanged(true)}
                      onPublish={handleOnUpdateCover}
                      descriptionCopyKey="EP_COVER_CROP_DESCRIPTION"
                      imageUploadCopy={{
                        headline: $t({ id: 'EP_CHOOSE_COVER' }),
                        hint: $t({ id: 'EP_UPLOAD_IMAGE_HINT' }),
                        label: $t({ id: 'EP_UPLOAD_COVER_LABEL' }),
                        onDragLabel: $t({ id: 'EP_UPLOAD_IMAGE_DRAG_LABEL' }),
                      }}
                    />
                  )}
                  <ExternalEditInfoBanner
                    copyKey="EP_CHOOSE_COVER_INFO_BANNER"
                    ctaUrl={`https://help.onlyfy.com/hc/de/articles/8803872010001-Erstbef%C3%BCllung-des-Employer-Branding-Profils-auf-XING#titel--/-headerbild`}
                    ctaCopyKey="EP_CHOOSE_COVER_INFO_BANNER_CTA"
                  />
                </>
              )}
            </SmallGrid>
          );
        }}
      </OmView>
      {dataChanged && dialogConfirmation?.dialogAction && <NotSavedDialog />}
    </>
  );
};

export const EditCoverMenu: FC<RenderEditCoverMenuProps> = (props) => (
  <DialogContextProvider>
    <RenderEditCoverMenu {...props} />
  </DialogContextProvider>
);
