import type { FC } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { useHeaderContext } from '../../hooks/use-header-context/use-header-context';
import { EntityInfoContainer } from '../entity-info/entity-info-container';
import HeaderActions from '../header-actions';
import { SocialProof } from '../social-proof/social-proof';
import * as Styled from './secondary-area.styles';

export const SecondaryArea: FC = () => {
  const { isSubpage, hasTertiaryArea, ctaType } = useHeaderContext();
  const { pageContext } = usePageContext() ?? {};
  const { isLoggedOut } = useLoginState();

  const socialProof = pageContext?.socialProof;

  return (
    <Styled.SecondaryArea className="secondaryArea">
      {!isSubpage && (
        <div className="secondaryMainArea">
          <EntityInfoContainer />
        </div>
      )}
      {isSubpage && !isLoggedOut && (
        <div className="secondaryMainArea">
          <SocialProof socialProof={socialProof} />
        </div>
      )}
      {!hasTertiaryArea && (
        <Styled.HeaderActionsArea>
          <HeaderActions ctaType={ctaType} />
        </Styled.HeaderActionsArea>
      )}
    </Styled.SecondaryArea>
  );
};
