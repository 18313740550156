import type { RouteComponentProps } from '@reach/router';
import { Router as ReachRouter, useParams } from '@reach/router';
import { useMemo, type FC } from 'react';

import { DialogContextProvider } from '@xing-com/crate-entity-pages-common';

import { Layout } from '../components/layout/layout';
import { RedirectToDemo } from '../components/redirect-to-demo/redirect-to-demo';
import { EditContainerValidation } from '../pages/edit/edit-container-validation';
import { MainPageContainer } from '../pages/main-page/main-page';
import { SubpageContainer } from '../pages/subpage/subpage';

type RouteProps = RouteComponentProps & {
  render: (
    props: RouteComponentProps & { params: URLSearchParams }
  ) => JSX.Element;
};
const Route: FC<RouteProps> = ({ render, ...rest }) => {
  const { search } = useParams();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  return render({ ...rest, params });
};

export const Router: FC = () => (
  <ReachRouter>
    <Route
      // This is a workarround because some assets path are conflicting with this route
      path="/:slug"
      render={({ slug }: RouteComponentProps<{ slug: string }>) => (
        <Layout isMainPage>
          <MainPageContainer pageSlug={slug ?? ''} />
        </Layout>
      )}
    />
    <Route
      path="/:slug/:type"
      render={({
        slug,
        type,
      }: RouteComponentProps<{ slug: string; type: string }>) => {
        return (
          <Layout>
            <SubpageContainer pageSlug={slug ?? ''} pageType={type ?? ''} />
          </Layout>
        );
      }}
    />
    <Route
      path="/:slug/:type/:param"
      render={({
        slug,
        type,
        param,
      }: RouteComponentProps<{
        slug: string;
        type: string;
        param: string;
      }>) => (
        <Layout>
          <SubpageContainer
            pageSlug={slug ?? ''}
            pageType={type ?? ''}
            param={param}
          />
        </Layout>
      )}
    />
    <Route
      path="/:slug/demo"
      render={({ slug }: RouteComponentProps<{ slug: string }>) => (
        <RedirectToDemo slug={slug ?? ''} />
      )}
    />
    <Route
      path="/:slug/edit/:item"
      render={({
        slug,
        item,
      }: RouteComponentProps<{ slug: string; item: string }>) => (
        <DialogContextProvider>
          <Layout isZenView={true}>
            <EditContainerValidation pageSlug={slug ?? ''} item={item ?? ''} />
          </Layout>
        </DialogContextProvider>
      )}
    />

    <Route
      path="/webview/:slug/:type"
      render={({
        slug,
        type,
      }: RouteComponentProps<{ slug: string; type: string }>) => (
        <Layout isWebview={true}>
          <SubpageContainer
            pageSlug={slug ?? ''}
            pageType={type ?? ''}
            isWebview={true}
          />
        </Layout>
      )}
    />
    <Route
      path="/webview/:slug/:type/:param"
      render={({
        slug,
        type,
        param,
      }: RouteComponentProps<{
        slug: string;
        type: string;
        param: string;
      }>) => (
        <Layout isWebview={true}>
          <SubpageContainer
            pageSlug={slug ?? ''}
            pageType={type ?? ''}
            param={param}
            isWebview={true}
          />
        </Layout>
      )}
    />
    <Route
      path="/webview/:slug/edit/:item"
      render={({
        slug,
        item,
      }: RouteComponentProps<{ slug: string; item: string }>) => (
        <DialogContextProvider>
          <Layout isWebview={true}>
            <EditContainerValidation
              isWebview={true}
              pageSlug={slug ?? ''}
              item={item ?? ''}
            />
          </Layout>
        </DialogContextProvider>
      )}
    />

    {/* <Miss /> */}
  </ReachRouter>
);
