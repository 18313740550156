import styled from 'styled-components';

import { Button as InternalButton } from '@xing-com/button';
import { spaceXS, spaceM, spaceL } from '@xing-com/tokens';

export const Button = styled(InternalButton)`
  margin: ${spaceM};
`;

export const SearchErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${spaceL};
`;

export const IconWarning = styled.span`
  margin-right: ${spaceXS};
  height: 18px;
`;

export const SearchEmptyState = styled.div`
  display: flex;
  align-items: center;
  padding: ${spaceL};
`;
