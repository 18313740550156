import type { FC } from 'react';

import { SkeletonProfileImage, SkeletonBody } from '@xing-com/skeleton';

import * as Styled from './employees-card-horizontal.styles';

export const EmployeesCardHorizontalSkeleton: FC = () => (
  <Styled.SkeletonWrapper>
    <Styled.SkeletonProfileImage>
      <SkeletonProfileImage />
    </Styled.SkeletonProfileImage>
    <Styled.SkeletonProfileInfo>
      <SkeletonBody amountRows={3} />
    </Styled.SkeletonProfileInfo>
  </Styled.SkeletonWrapper>
);
