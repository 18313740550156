import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { EntityPageFocusType } from '@xing-com/crate-common-graphql-types';
import { usePageContext } from '@xing-com/crate-entity-pages-common';
import { trackIsEditingToggle } from '@xing-com/crate-entity-pages-common/src/tracking';

import * as Styled from './edit-bar.styles';

type ToogleEditModeProps = {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};
export const ToggleEditMode: FC<ToogleEditModeProps> = ({
  isEditing,
  setIsEditing,
}) => {
  const { pageContext } = usePageContext() ?? {};

  const handleToggleEditMode = () => {
    if (pageContext?.focusType === EntityPageFocusType.Company) {
      setIsEditing(!isEditing);
      trackIsEditingToggle(!isEditing);
    } else {
      const cppId = pageContext?.cppId
        ? pageContext?.cppId.split('.')[0]
        : null;

      if (cppId) {
        window.location.href = `/news/pages/${cppId}/edit`;
        // TODO: Is it needed?
        trackIsEditingToggle(true);
      }
    }
  };

  return (
    <Styled.Button
      variant={isEditing ? 'secondary' : 'primary'}
      size={'small'}
      onClick={handleToggleEditMode}
      data-testid={
        isEditing
          ? 'EP_EDIT_ACTION_SET_EDIT_MODE_OFF'
          : 'EP_EDIT_ACTION_SET_EDIT_MODE_ON'
      }
    >
      <FormattedMessage
        id={
          isEditing
            ? 'EP_EDIT_ACTION_SET_EDIT_MODE_OFF'
            : 'EP_EDIT_ACTION_SET_EDIT_MODE_ON'
        }
      />
    </Styled.Button>
  );
};
