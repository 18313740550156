import { type FC } from 'react';

import {
  ModuleHeader,
  useEditContext,
  useMainPageLoadModule,
  useModuleContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';

import { NewsList } from '../components/news-list/news-list';
import { NewsContextProvider } from '../contexts/news-context/news-context';
import { getSubPageKey, newsPath } from '../utils';

export const NewsModule: FC = () => {
  useMainPageLoadModule();
  const { moduleContext } = useModuleContext();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};

  const hasSubpage = moduleContext?.moduleProperties.hasSubpage;

  const subPageKey = getSubPageKey(!!isEditing);

  return (
    <NewsContextProvider hasSubpage={hasSubpage}>
      <ModuleHeader
        headlineCopyKey="EP_NEWS_HEADLINE"
        showMore={
          hasSubpage
            ? {
                to: newsPath(
                  pageContext?.basePath ?? '',
                  pageContext?.pageSlug as string
                ),
                copyKey: `${subPageKey}`,
              }
            : null
        }
      />
      <NewsList />
    </NewsContextProvider>
  );
};
