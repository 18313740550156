import type { FC } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { Descendant } from 'slate';
import { createEditor } from 'slate';
import { Slate, withReact } from 'slate-react';

import {
  getMentionsCount,
  Plugin as MentionPlugin,
  useMention,
  withMentions,
} from '@xing-com/content-editor-plugin-mention';
import { Plugin as ParagraphPlugin } from '@xing-com/content-editor-plugin-paragraph';

import { serializeInput } from '../../helpers/commbox-body';
import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxFormContext } from '../../hooks/use-commbox-form-context';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { useDebouncedLinkShare } from '../../hooks/use-debounce-link-share';
import { trackAction } from '../../tracking';
import { Styled } from './commbox-editor.style';

export const CommboxEditor: FC = () => {
  const {
    setCommboxHasContent,
    setShouldOpenFilePicker,
    shouldOpenFilePicker,
  } = useCommboxContext();
  const { slateMessage, setPlainTextMessage, setSlateMessage } =
    useCommboxFormContext();
  const { enableMentions, isEditingPosting } = useCommboxPermissions();

  const { debouncedLinkShare } = useDebouncedLinkShare();
  const { formatMessage } = useIntl();
  const { handleMentionKeyEvent, handleMentionOnChange, SelectMention } =
    useMention();

  const editor = useMemo(() => withMentions(withReact(createEditor())), []);

  const EDITOR_PLUGINS = [
    MentionPlugin({ handleMentionKeyEvent }),
    ParagraphPlugin(),
  ];

  const handleSlateOnChange = (newMessage: Descendant[]) => {
    const oldMentionsCount = getMentionsCount(slateMessage);
    const newMentionsCount = getMentionsCount(newMessage);

    const mentionWasRemoved =
      oldMentionsCount &&
      (!newMentionsCount || newMentionsCount < oldMentionsCount);

    mentionWasRemoved &&
      trackAction({
        PropTrackAction: 'social_share_commbox_user-mention_remove',
      });

    setSlateMessage(newMessage);
    setPlainTextMessage(serializeInput(newMessage));
    setCommboxHasContent(!!serializeInput(newMessage).trim());
    if (!isEditingPosting) debouncedLinkShare(serializeInput(newMessage));
    if (enableMentions) handleMentionOnChange();
  };
  const handleOnFocus = () => {
    shouldOpenFilePicker && setShouldOpenFilePicker(false);
  };

  return (
    <Slate editor={editor} value={slateMessage} onChange={handleSlateOnChange}>
      <Styled.SlateEditor
        onFocus={handleOnFocus}
        plugins={EDITOR_PLUGINS}
        aria-label="Text Area"
        data-testid="commboxSlate"
        name="commboxInputField"
        placeholder={formatMessage({
          id: enableMentions
            ? 'COMMBOX_EMPTY_TEXT_FIELD_MENTIONS'
            : 'COMMBOX_EMPTY_TEXT_FIELD',
        })}
      />
      {enableMentions && <SelectMention />}
    </Slate>
  );
};
