import { useMutation, useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { StatusBannerAutoHide } from '@xing-com/banner';
import { EntityPageContractType } from '@xing-com/crate-common-graphql-types';
import {
  useDialogContext,
  usePageContext,
  useErrorContext,
  EntityPageUpdateContactUsersCatalogueDocument,
  WarningMessageBox as WarningBox,
  EditActions,
} from '@xing-com/crate-entity-pages-common';
import { BodyCopy } from '@xing-com/typography';

import { ContactsEditListCards } from '../components/contacts-edit-list-cards/contacts-edit-list-cards';
import { SearchInput } from '../components/search-input/search-input';
import { ContactsEditContext } from '../contexts/contacts-edit-context/contacts-edit-context';
import { EntityPagesContactsListDocument } from '../graphql/queries/entity-pages-contacts-list.gql-types';
import type { ContactEdit } from '../types/contacts-edit';
import * as Styled from './contacts-edit.styles';
import Skeleton from './skeleton';

export const ContactsEdit: FC = () => {
  const { $t } = useIntl();
  const { pageContext } = usePageContext() ?? {};
  const ref = useRef(null);
  const { dataChanged, setDataChanged, executeWithDialog } = useDialogContext();
  const { showError } = useErrorContext();

  const isFreeProfile =
    pageContext?.contractType === EntityPageContractType.Free;

  const [contactsTouched, setContactsTouched] = useState<boolean | null>(null);
  const [disablePageActions, setDisablePageActions] = useState(false);
  const [showInfoFeedback, setShowInfoFeedback] = useState<boolean | null>(
    null
  );
  /** ****** CONTACTS ********/
  const [loading, setLoading] = useState(true);
  const [contactUsersList, setContactUsersList] = useState<
    ContactEdit[] | null
  >(null);

  const { error, refetch } = useQuery(EntityPagesContactsListDocument, {
    variables: {
      id: pageContext?.pageSlug as string,
      includeDisabledContact: true,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const contactUsers = data?.entityPageContactUsers?.collection || [];
      const contactUsersList: ContactEdit[] = contactUsers.map((contact) => ({
        contact,
        enabled: !!contact.enabled,
      }));

      setContactUsersList(contactUsersList);
      setLoading(false);
    },
  });
  const [updateContacts, { loading: updateContactsLoading }] = useMutation(
    EntityPageUpdateContactUsersCatalogueDocument
  );

  const handleUpdateContacts = async () => {
    if (!contactsTouched || !contactUsersList) {
      return;
    }

    const { data, errors } = await updateContacts({
      variables: {
        pageId: pageContext?.pageSlug as string,
        ContactUsersCatalogueItemInput: contactUsersList
          .filter(
            (contact) =>
              !(contact.contact.id?.includes('temp') && contact.deleted) // contacts added "temp" and then deleted
          )
          .map((contact, index) => ({
            id: contact.contact.id?.includes('temp')
              ? null
              : contact.contact.id,
            userId: contact.contact.xingId?.id,
            label: contact.contact.label,
            position: index,
            enabled: contact.contact.enabled,
            deleted: contact.deleted,
          })),
      },
    });

    const error =
      errors?.[0] ?? data?.entityPageUpdateContactUsersCatalogue?.error;

    if (error) {
      showError({
        message: 'EP_GENERAL_FEEDBACK_ERROR',
        error: error,
      });
    } else {
      setDataChanged(false);
      // pageContext?.goBackUrl?.();
    }
  };
  const handleOnAddContact = () => {
    if (isFreeProfile && showInfoFeedback === null) {
      setShowInfoFeedback(true);

      setTimeout(() => {
        setShowInfoFeedback(false);
      }, 5000);
    }
  };

  useEffect(() => {
    const nonDeletedUsers = contactUsersList?.filter((x) => !x.deleted);

    if (nonDeletedUsers?.length === 1 && nonDeletedUsers[0].enabled === false) {
      const newList = contactUsersList
        ? contactUsersList.map((contact) => {
            if (contact.default) {
              contact.enabled = true;
            }
            return contact;
          })
        : [];

      setContactUsersList(newList);
    }
  }, [contactUsersList]);

  if (loading) {
    return (
      <>
        <Styled.Headline size="xxlarge">
          {$t({ id: 'EP_CONTACTS_EDIT_TITLE' })}
        </Styled.Headline>
        <Skeleton />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Styled.Headline size="xxlarge">
          {$t({ id: 'EP_CONTACTS_EDIT_TITLE' })}
        </Styled.Headline>
        <WarningBox
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => {
            setLoading(true);
            refetch();
          }}
        />
      </>
    );
  }

  return (
    <ContactsEditContext.Provider
      value={{
        contactDataList: contactUsersList as ContactEdit[],
        disablePageActions,
        contactsTouched,
        contactUsersList,
        setDisablePageActions,
        setContactsTouched,
        setContactUsersList,
      }}
    >
      <Styled.Headline size="xxlarge">
        {$t({ id: 'EP_CONTACTS_EDIT_TITLE' })}
      </Styled.Headline>
      <div ref={ref}>
        <Styled.EditAreaContainer>
          <SearchInput onAddContact={handleOnAddContact} />
          <ContactsEditListCards />
        </Styled.EditAreaContainer>
        <EditActions
          disabled={!dataChanged || disablePageActions}
          discardAction={() =>
            executeWithDialog(() => pageContext?.goBackUrl?.())
          }
          noMarginTop
          saveAction={handleUpdateContacts}
          isSaving={updateContactsLoading}
        />
      </div>
      <StatusBannerAutoHide
        variant="info"
        timeout={7000}
        show={!!showInfoFeedback}
        handleOnClose={() => setShowInfoFeedback(false)}
      >
        <BodyCopy noMargin size="small">
          {$t({ id: 'EP_FREE_ADD_CONTACT_INFO' })}
        </BodyCopy>
      </StatusBannerAutoHide>
    </ContactsEditContext.Provider>
  );
};
