import type { FC } from 'react';

import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
// eslint-disable-next-line monorepo/forbidden-imports
import { TopBar as DefaultTopBar } from '@xing-com/top-bar';

import { CreateButton } from './create-button';

type TopBarProps = {
  isMainPage?: boolean;
};
export const TopBar: FC<TopBarProps> = ({ isMainPage }) => {
  const { pageContext } = usePageContext() ?? {};
  const editContext = useEditContext();

  return (
    <DefaultTopBar
      title={
        (isMainPage ? pageContext?.pageTitle : pageContext?.moduleTitle) ||
        'XING'
      }
      subTitle={(!isMainPage && pageContext?.pageTitle) || undefined}
      type={'subheader'}
      action={
        isMainPage &&
        pageContext?.hasNewsModule &&
        editContext?.isEditor && <CreateButton />
      }
      skipAdditionalButtons
    />
  );
};

export default TopBar;
