import { Redirect } from '@reach/router';
import type { FC } from 'react';

import { usePageContext, PageError } from '@xing-com/crate-entity-pages-common';
import { useFeatureSwitch } from '@xing-com/hub';

import { EditContainer } from './edit-container';
import editItemsMap from './edit-items-map';

interface EditContainerProps {
  pageSlug: string;
  item: string;
  isWebview?: boolean;
}
export const EditContainerValidation: FC<EditContainerProps> = ({
  pageSlug,
  item,
  isWebview,
}) => {
  const canEditMobile = useFeatureSwitch('ep_mobileEdit', false);
  const editItemProps = editItemsMap?.[item] ? editItemsMap[item] : null;
  const EditItem = editItemProps ? editItemProps.edit : null;

  const { pageContext } = usePageContext() ?? {};

  if (!EditItem) {
    return (
      <div>
        <PageError type={404} />
      </div>
    );
  }

  if (
    typeof window !== 'undefined' &&
    window.innerWidth < 1113 &&
    !canEditMobile
  ) {
    return <Redirect to={`${pageContext?.basePath}/${pageSlug}`} />;
  }

  return (
    <EditContainer
      pageSlug={pageSlug}
      editItemProps={editItemsMap[item]}
      isWebview={isWebview}
    />
  );
};
