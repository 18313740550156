import type { FC } from 'react';
import { useIntl } from 'react-intl';

import type { SearchCard } from '../../types/search-card';
import { SearchInputCard } from '../search-input-card/search-input-card';
import { SearchInputListCardsEmpty } from './search-input-list-cards.empty';
import { SearchInputListCardsError } from './search-input-list-cards.error';
import * as Styled from './search-input-list-cards.styles';

type SearchInputListCardsProps = {
  cards: SearchCard[];
  totalCards: number;
  contactIdAdded: string;
  onAddContact?: (cardData: SearchCard) => void;
  onShowMoreClick?: () => void;
};
export const SearchInputListCards: FC<SearchInputListCardsProps> & {
  Error: typeof SearchInputListCardsError;
  Empty: typeof SearchInputListCardsEmpty;
} = ({
  cards,
  totalCards,
  contactIdAdded,
  onAddContact = () => undefined,
  onShowMoreClick = () => undefined,
}) => {
  const { $t } = useIntl();

  return (
    <>
      {cards.map((cardData, index) => {
        return (
          <SearchInputCard
            key={`SEARCH-INPUT-CARD-${cardData.xingId}-${index}`}
            cardData={cardData}
            isLoading={!!contactIdAdded && contactIdAdded === cardData.xingId}
            isDisabled={!!contactIdAdded && contactIdAdded !== cardData.xingId}
            onAddContact={() => onAddContact(cardData)}
          />
        );
      })}
      {totalCards > 5 && (
        <Styled.Button
          variant="secondary"
          size="small"
          onClick={onShowMoreClick}
          data-testid="SHOW_MORE_CONTACTS_BUTTON"
        >
          {$t({ id: 'EP_SHOW_MORE_RESULTS' })}
        </Styled.Button>
      )}
    </>
  );
};

SearchInputListCards.Empty = SearchInputListCardsEmpty;
SearchInputListCards.Error = SearchInputListCardsError;
