import { useMutation } from '@apollo/client';
import type React from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { UploadApplication } from '@xing-com/crate-common-graphql-types';
import type { FileUploaded } from '@xing-com/crate-entity-pages-common';
import {
  FileUploadButton as DefaultFileUploadButton,
  EPuploadRequestDocument,
} from '@xing-com/crate-entity-pages-common';
import { IconUpload } from '@xing-com/icons';

import * as Styled from './documents-editor.styles';

type FileUploadButtonProps = {
  onSuccess: (fileData: FileUploaded) => void;
  onError?: (error: any) => void;
  isUploading?: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  disableUpload?: boolean;
};
export const FileUploadButton: FC<FileUploadButtonProps> = ({
  onSuccess,
  onError = () => undefined,
  setIsUploading,
  isUploading,
  disableUpload,
}) => {
  const [uploadRequest] = useMutation(EPuploadRequestDocument);

  const handleOnPublish = ({ file }: FileUploaded) => {
    uploadRequest({
      variables: {
        application: UploadApplication.EntityPages,
        fileSize: file.size,
        type: file.type,
        fileName: file.name,
      },
      onCompleted: ({ uploadRequest }) => {
        const fileError = uploadRequest?.error ?? undefined;
        const fileSuccess = uploadRequest?.success;

        if (fileError || !fileSuccess) {
          onError(fileError);
        } else {
          onSuccess({ file, id: fileSuccess.id });
        }
      },
    });
  };

  return (
    <Styled.FileUploadContainer>
      <DefaultFileUploadButton
        disableUpload={disableUpload}
        onError={() => setIsUploading(false)}
        onChange={() => setIsUploading(true)}
        onPublish={handleOnPublish}
      >
        <Styled.FileUploadButton
          disabled={disableUpload}
          loading={isUploading}
          variant={'secondary'}
          size={'small'}
          icon={IconUpload}
        >
          <FormattedMessage
            id={'EP_UPLOAD_DOCUMENT'}
            defaultMessage={'EP_UPLOAD_DOCUMENT'}
          />
        </Styled.FileUploadButton>
      </DefaultFileUploadButton>
    </Styled.FileUploadContainer>
  );
};
