import { useQuery } from '@apollo/client';
import { Fragment, type FC } from 'react';

import { articleSerializer } from '@xing-com/content-editor-serializer';
import {
  MetaData,
  useEditContext,
  usePageContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-entity-pages-common';

import { DEFAULT_MEDIA_AMOUNT_FOR_ABOUTUS_SUBPAGE } from '../../config';
import { AboutUsSubpageDocument } from '../../graphql/queries/about-us-subpage-query.gql-types';
import { Affiliates } from '../affiliates-container/affiliates';
import { Awards } from './awards/awards';
import { Description } from './description/description';
import { DocumentsContainer } from './documents/documents-container';
import { GalleryPreviewContainer } from './gallery-preview/gallery-preview-container';
import { AboutUsSkeleton } from './skeleton/skeleton';

type AboutUsErrorPageProps = {
  refetch: () => void;
};
const AboutUsErrorPage: React.FC<AboutUsErrorPageProps> = ({ refetch }) => (
  <div data-testid={'errorContainer'}>
    <Error
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

type AboutUsDetailProps = {
  pageSlug: string;
  companyId: string;
};
export const AboutUsDetail: FC<AboutUsDetailProps> = ({
  pageSlug,
  companyId,
}) => {
  const { isEditor, isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const isFreePage = pageContext?.contractType === 'FREE';
  const upsellActive = pageContext?.upsellActive;

  const { data, loading, refetch } = useQuery(AboutUsSubpageDocument, {
    variables: {
      id: pageSlug,
      first: DEFAULT_MEDIA_AMOUNT_FOR_ABOUTUS_SUBPAGE,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const companyData =
    data?.facts?.__typename === 'CompanyAboutUsFacts'
      ? data.facts.companyData
      : null;

  const badges = companyData?.kununuData?.badges || [];

  const availableBadges = badges
    .filter((badge) => !!badge)
    .filter((badge) => !!badge && badge?.image?.[0]?.url);

  const pagesAboutUs =
    data?.pagesAboutUs?.__typename === 'AboutEntity' ? data.pagesAboutUs : null;

  const descriptionAboutArticle = pagesAboutUs?.aboutArticle;
  const descriptionHeadline = descriptionAboutArticle?.header?.title?.text;
  const descriptionDeserializedArticle = articleSerializer.deserialize(
    descriptionAboutArticle?.blocks
  );

  const aboutUsDocuments =
    pagesAboutUs?.documents?.__typename === 'EntityDocumentConnection'
      ? pagesAboutUs?.documents
      : null;

  const documentsEdges = aboutUsDocuments?.edges ?? [];
  const documents =
    documentsEdges.map((edge) => edge?.node)?.filter((node) => !!node) ?? [];

  const queryHasError =
    !loading &&
    (!(descriptionDeserializedArticle?.length > 0) || !descriptionHeadline);

  if (queryHasError) {
    return <AboutUsErrorPage refetch={refetch} />;
  }

  if (loading && !data) {
    return <AboutUsSkeleton />;
  }

  if (!data) return <Fragment />;

  return (
    <div data-testid={'ABOUT_US_SUBMODULE'}>
      <MetaData description={pagesAboutUs?.summary} />
      <Description
        // @ts-expect-error TODO: fix this type
        descriptionHeadline={descriptionHeadline}
        descriptionArticle={descriptionDeserializedArticle}
        // @ts-expect-error TODO: fix this type
        facts={data?.facts}
      />
      {pagesAboutUs && (
        <GalleryPreviewContainer
          // @ts-expect-error TODO: fix this type
          media={pagesAboutUs?.media}
          pageSlug={pageSlug}
          mediaItemsNumber={DEFAULT_MEDIA_AMOUNT_FOR_ABOUTUS_SUBPAGE}
          upsellActive={upsellActive}
        />
      )}
      {availableBadges.length > 0 && <Awards badges={availableBadges} />}
      {(documents.length > 0 || (isEditing && !isFreePage)) && (
        <DocumentsContainer documents={documents} />
      )}
      {companyId && (!isFreePage || isEditor) ? (
        <Affiliates
          companyId={companyId}
          pageSlug={pageSlug}
          upsellActive={upsellActive}
        />
      ) : null}
    </div>
  );
};
