import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { ContactEdit } from '../../types/contacts-edit';

export type ContactsEditContextValues = {
  contactDataList: ContactEdit[];
  contactUsersList: ContactEdit[] | null;
  disablePageActions: boolean;
  contactsTouched: boolean | null;
  setDisablePageActions: Dispatch<SetStateAction<boolean>>;
  setContactsTouched: Dispatch<SetStateAction<boolean | null>>;
  setContactUsersList: Dispatch<SetStateAction<any>>;
};
export const ContactsEditContext = createContext<
  ContactsEditContextValues | undefined
>(undefined);
