import type { FC } from 'react';
import { useIntl } from 'react-intl';

import type { EntityPageContentFeedCollectionMetadata } from '@xing-com/crate-common-graphql-types';
import { PostingPublicationState } from '@xing-com/crate-common-graphql-types';
import {
  SubpageNavigation,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { trackNewsPublicationFilter } from '@xing-com/crate-entity-pages-common/src/tracking';
import { useFeatureSwitch } from '@xing-com/hub';

import { FEATURE_SWITCH_ENABLE_DRAFT_SCHEDULE } from '../../config/constants';
import { useNewsContext } from '../../hooks/use-news-context/use-news-context';
import { publicationStateIndex } from '../../utils';
import * as Styled from './news-navigation.styles';

type NewsNavigationProps = {
  isLoading: boolean;
  metadata: EntityPageContentFeedCollectionMetadata;
  onClickItem?: (state: PostingPublicationState) => void;
};
export const NewsNavigation: FC<NewsNavigationProps> = ({
  isLoading,
  metadata,
  onClickItem = () => undefined,
}) => {
  const { $t } = useIntl();
  const { pageContext } = usePageContext() ?? {};
  const { currentPublicationState, setCurrentPublicationState } =
    useNewsContext();

  const enableDraftSchedule = useFeatureSwitch(
    FEATURE_SWITCH_ENABLE_DRAFT_SCHEDULE
  );
  const selectPublicationState = (state: PostingPublicationState) => {
    if (state !== currentPublicationState && !isLoading) {
      setCurrentPublicationState(state);
      trackNewsPublicationFilter(state, pageContext?.pageId as string);
    }
  };

  const handleOnClickItem = (state: PostingPublicationState) => {
    onClickItem(state);
    selectPublicationState(state);
  };

  const visibleDefaultTabs = [
    {
      copy: $t({ id: 'NEWS_MODULE_PUBLISHED_TAB' }),
      onClick: () => handleOnClickItem(PostingPublicationState.Published),
      disabled: isLoading,
      'data-testid': 'PUBLISHED_TAB',
    },
    {
      copy: `${$t({ id: 'NEWS_MODULE_HIDDEN_TAB' })} (${metadata.hiddenTotal})`,
      onClick: () => handleOnClickItem(PostingPublicationState.Hidden),
      disabled: isLoading || metadata.hiddenTotal === 0,
      'data-testid': 'HIDDEN_TAB',
    },
  ];

  const featureSwitchTabs = [
    visibleDefaultTabs[0],
    {
      copy: `${$t({ id: 'NEWS_MODULE_SCHEDULED_TAB' })} (${metadata.scheduledTotal})`,
      onClick: () => handleOnClickItem(PostingPublicationState.Scheduled),
      disabled: isLoading || metadata.scheduledTotal === 0,
      'data-testid': 'SCHEDULED_TAB',
    },
    {
      copy: `${$t({ id: 'NEWS_MODULE_DRAFT_TAB' })} (${metadata.draftTotal})`,
      onClick: () => handleOnClickItem(PostingPublicationState.Draft),
      disabled: isLoading || metadata.draftTotal === 0,
      'data-testid': 'DRAFT_TAB',
    },
    visibleDefaultTabs[1],
  ];

  const filterProps = {
    activeItemIndex: publicationStateIndex(currentPublicationState),
    allowDropdown: false,
  };

  return (
    <Styled.NewsNavigation data-testid="NEWS_NAVIGATION">
      <>
        {enableDraftSchedule ? (
          <SubpageNavigation
            {...filterProps}
            variant="filled"
            size="small"
            data={featureSwitchTabs}
            activeItemIndex={publicationStateIndex(
              currentPublicationState,
              true
            )}
            noPadding
          />
        ) : (
          <SubpageNavigation
            {...filterProps}
            variant="filled"
            size="small"
            data={visibleDefaultTabs}
            noPadding
          />
        )}
      </>
    </Styled.NewsNavigation>
  );
};
