export const generateDots = (
  items: Array<any>,
  numberOfItemsPerChunk: number
) => {
  const copyOfEmployees = Array.from({ length: items.length });

  const chunk = (arr: any[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  const dots = chunk(copyOfEmployees, numberOfItemsPerChunk).length;

  return dots;
};
