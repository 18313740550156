import type { FC } from 'react';

import { EntityPageContractType } from '@xing-com/crate-common-graphql-types';
import { usePageContext } from '@xing-com/crate-entity-pages-common';

import { SectionCompanyJobs } from '../components/section-company-jobs/section-company-jobs';
import { SectionSimilarJobs } from '../components/section-similar-jobs/section-similar-jobs';

export const JobsModule: FC = () => {
  const { pageContext } = usePageContext() ?? {};
  const isEBP = pageContext?.contractType === EntityPageContractType.Paid;

  return (
    <>
      <SectionCompanyJobs />
      {!isEBP && <SectionSimilarJobs />}
    </>
  );
};
