import { useContext } from 'react';

import { NewsItemContext } from '../../contexts/news-item-context/news-item-context';

export const useNewsItemContext = () => {
  const context = useContext(NewsItemContext);

  if (!context) {
    throw new Error(
      'useNewsItemContext must be used within a NewsItemContextProvider'
    );
  }

  return context;
};
