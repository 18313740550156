import type { FC } from 'react';

import { NewsItem } from '../news-item/news-item';

type NewsListSkeletonProps = {
  loadingSkeletonCount: number;
  showActors: boolean;
};
export const NewsListSkeleton: FC<NewsListSkeletonProps> = ({
  loadingSkeletonCount,
  showActors,
}) => (
  <div data-testid="NEWS_MODULE_SKELETON">
    {[...Array(loadingSkeletonCount)].map((_, i) => (
      <NewsItem.Skeleton key={i} smallActor={!showActors} />
    ))}
  </div>
);
