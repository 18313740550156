import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePageContext } from '@xing-com/crate-entity-pages-common';

import * as Styled from './edit-page-info.styles';

type EditPageInfoButtonProps = { isLabel?: boolean };
export const EditPageInfoButton: FC<EditPageInfoButtonProps> = ({
  isLabel = false,
}) => {
  const { pageContext } = usePageContext() ?? {};
  const pageSlug = pageContext?.pageSlug;
  return isLabel ? (
    <Styled.EditLabel
      to={`${pageContext?.basePath}/${pageSlug}/edit/pageInfo`}
      size={'small'}
      // @ts-expect-error FIXME: SC6
      noMargin
      data-testid={'EDIT_BUTTON_ON_EMPTY_SLOGAN'}
    >
      <FormattedMessage
        id={'EP_SLOGAN_EDIT_HINT'}
        defaultMessage={'EP_SLOGAN_EDIT_HINT'}
      />
    </Styled.EditLabel>
  ) : (
    <Styled.EditSloganButton
      size={'small'}
      showIcon
      data-cy="EDIT_BUTTON_ON_SLOGAN"
      data-testid="EDIT_BUTTON_ON_SLOGAN"
      to={`${pageContext?.basePath}/${pageSlug}/edit/pageInfo`}
    />
  );
};
