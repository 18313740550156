import type { EntityPageMemberSearchUnfencedFragment } from '../../graphql/fragments/member-search-unfenced-fragment.gql-types';
import type { SearchCard } from '../../types/search-card';

export const createSearchCard = (
  member: EntityPageMemberSearchUnfencedFragment
): SearchCard | null => {
  if (member?.__typename !== 'MembersSearchUnfencedItem' || !member.xingId) {
    return null;
  }

  const flag =
    member.xingId?.userFlags?.displayFlag &&
    member.xingId?.userFlags?.displayFlag !== 'BASIC' &&
    member.xingId?.userFlags?.displayFlag !== 'EXECUTIVE'
      ? member.xingId.userFlags?.displayFlag
      : undefined;

  return {
    flag,
    xingId: member.xingId.id,
    profileUrl: `/profile/${member.xingId?.pageName}`,
    profileImage: {
      src: member.xingId?.profileImage?.[0]?.url ?? undefined,
    },
    displayName: member.xingId.displayName,
    occupation: member.xingId.occupations?.[0]?.headline,
  };
};

export const createSearchCards = (
  members: EntityPageMemberSearchUnfencedFragment[]
) => members.map((member) => createSearchCard(member)).filter((card) => !!card);
