import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type {
  CompanyKununuBadge,
  EntityMedia,
} from '@xing-com/crate-common-graphql-types';
import {
  UpsellBannerVertical,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';

import * as Styled from './about-us.styles';
import { Awards } from './awards/awards';
import { GalleryPreview } from './gallery-preview/gallery-preview';

type AboutUsProps = {
  pageSlug: string;
  headline?: string;
  summary: string;
  hasSubpage: boolean;
  galleryItems: Partial<EntityMedia>[];
  badges: CompanyKununuBadge[];
  upsellActive?: boolean;
  isEditing: boolean;
};
export const AboutUs: FC<AboutUsProps> = ({
  upsellActive,
  headline,
  summary,
  pageSlug,
  hasSubpage,
  galleryItems,
  badges,
  isEditing,
}) => {
  const { pageContext } = usePageContext() ?? {};

  return (
    <Styled.Wrapper data-testid={'ABOUT_US_MODULE'}>
      <Styled.AboutUsWrapper>
        {upsellActive ? (
          <Styled.UpsellLayout>
            <UpsellBannerVertical
              headerCopyKey="EP_UPSELL_PROFILE_MULTIMEDIA_HEADER"
              bodyCopyKey="EP_UPSELL_PROFILE_MULTIMEDIA_BODY"
              moduleName="aboutusmodule"
            />
          </Styled.UpsellLayout>
        ) : (
          <GalleryPreview pageSlug={pageSlug} galleryItems={galleryItems} />
        )}
        <Styled.SummaryAwardsWrapper>
          <div data-cy={'aboutUsText'}>
            {headline && (
              <Styled.Headline
                fontWeight="bold"
                size={'xlarge'}
                data-testid={'ABOUTUS_HEADLINE'}
              >
                {headline}
                {isEditing && pageSlug && (
                  <Styled.EditButton
                    size={'small'}
                    showIcon={true}
                    data-cy="EDIT_BUTTON_ON_ABOUT_US_DESC_TITLE"
                    data-testid="EDIT_BUTTON_ON_ABOUT_US_DESC_TITLE"
                    to={`${pageContext?.basePath}/${pageSlug}/edit/aboutUsDescription?type=short`}
                  />
                )}
              </Styled.Headline>
            )}
            <Styled.DescriptionText
              noMargin
              size={'small'}
              data-testid={'ABOUTUS_DESC'}
            >
              {summary}
              {!headline && isEditing && pageSlug && (
                <Styled.EditButton
                  size={'small'}
                  showIcon={true}
                  data-testid="edit-aboutus-description"
                  data-cy="EDIT_BUTTON_ON_ABOUT_US_DESC_DETAIL"
                  to={`${pageContext?.basePath}/${pageSlug}/edit/aboutUsDescription?type=short`}
                />
              )}
            </Styled.DescriptionText>
            {hasSubpage && (
              <Styled.ButtonWrapper>
                <Styled.Button
                  variant={'tertiary'}
                  size={'small'}
                  to={getTrackingUrl({
                    to: `${pageContext?.basePath}/${pageSlug}/about_us`,
                    trackingKey: 'discoverMore',
                  })}
                >
                  <FormattedMessage
                    id={'EP_ABOUT_US_DISCOVER_CTA'}
                    defaultMessage={'EP_ABOUT_US_DISCOVER_CTA'}
                  />
                </Styled.Button>
              </Styled.ButtonWrapper>
            )}
          </div>
        </Styled.SummaryAwardsWrapper>
      </Styled.AboutUsWrapper>
      {badges.length > 0 && (
        <div>
          <Awards pageSlug={pageSlug} badges={badges} />
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default AboutUs;
