import { useLocation, useNavigate } from '@reach/router';
import { useContext, useEffect, useMemo } from 'react';

import {
  EntityPageFocusType,
  EntityPageParentPage,
} from '@xing-com/crate-common-graphql-types';
import type {
  EntityPageQuery,
  EntitySubpageQuery,
  PageContextType,
} from '@xing-com/crate-entity-pages-common';
import { PageContext, EditContext } from '@xing-com/crate-entity-pages-common';

import { EP_URL } from '../../config/urls';
import type { EditContextQuery } from '../../graphql/queries/editContext.gql-types';
import { getPathByFocusType } from '../../utils/getPathByFocusType';

type EntityPageData =
  | EntityPageQuery['entityPageEX']
  | EditContextQuery['entityPageEX']
  | EntitySubpageQuery['entityPageEX'];

/* this hook needs to work on SSR. this meane it can't depend on useEffect or useContext  */
export const useUpdatePageContext = (
  entityPageEX?: EntityPageData,
  extraPageContext?: Partial<PageContextType>
) => {
  const { origin } = useLocation();
  const navigate = useNavigate();
  const { isEditor } = useContext(EditContext) ?? {};
  const { pageContext, setPageContext } = useContext(PageContext) ?? {};

  const newPageContext = useMemo(() => {
    // TODO Review if we need this
    if (entityPageEX?.__typename !== 'EntityPage') {
      return pageContext;
    }

    const isFollowingPage =
      // @ts-expect-error TODO: fix type, multiple types are possible
      entityPageEX.userPageContext?.userInteractions?.__typename ===
        'EntityPageUserInteractionFollow' &&
      // @ts-expect-error TODO: fix type, multiple types are possible
      entityPageEX.userPageContext.userInteractions.followState?.isFollowing;

    const basePath = getPathByFocusType(extraPageContext?.isWebview);

    // @ts-expect-error TODO: fix type, multiple types are possible
    const headerModule = entityPageEX?.modules?.collection.find(
      // @ts-expect-error TODO: fix type, multiple types are possible
      (module) => module?.type === 'header'
    );

    const headerModuleContent = headerModule?.content;

    const industryId = headerModuleContent?.company?.industry?.id;

    const updatedContext = {
      // @ts-expect-error TODO: fix type, multiple types are possible
      socialProof: entityPageEX.userPageContext?.socialProof,
      basePath,
      contractType: entityPageEX.contract?.type,
      // @ts-expect-error TODO: fix type, multiple types are possible
      publicationStatus: entityPageEX.publicationStatus,
      // @ts-expect-error TODO: fix type, multiple types are possible
      visibility: entityPageEX.visibility,
      pageId: entityPageEX.id,
      // @ts-expect-error TODO: fix type, multiple types are possible
      url: entityPageEX.links?.self,
      pageSlug: entityPageEX.slug,
      // @ts-expect-error TODO: fix type, multiple types are possible
      pageSlogan: entityPageEX.slogan || '',
      isFollowingPage,
      focusType: entityPageEX.focusType,
      companyId: entityPageEX.context?.companyId,
      industryId,
      cppId: entityPageEX.context?.cppId,
      pageTitle: entityPageEX.title,
      globalId: entityPageEX.globalId,
      upsellActive:
        entityPageEX.contract?.type === 'FREE' &&
        isEditor &&
        entityPageEX.focusType === EntityPageFocusType.Company,
      // @ts-expect-error TODO: fix type, multiple types are possible
      logo: entityPageEX.logoImage?.[0].url,
      parentPageUrl:
        // @ts-expect-error TODO: fix type, multiple types are possible
        entityPageEX.properties?.parentPage ===
        EntityPageParentPage.CompaniesLandingPage
          ? `${origin}companies`
          : `${origin}news`,
      isShowingCompanyBanner: false,
      goBackUrl: pageContext?.globalId
        ? () => navigate(-1)
        : () => entityPageEX.slug && navigate(EP_URL(entityPageEX.slug)),
      ...extraPageContext,
    };

    return updatedContext;
  }, [entityPageEX, isEditor]);

  useEffect(() => {
    // @ts-expect-error TODO: fix set Page Context
    setPageContext((prevState) => ({
      ...prevState,
      ...newPageContext,
    }));
  }, [newPageContext]);

  return { ...pageContext, ...newPageContext } as PageContextType;
};
