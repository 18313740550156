import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useEffect } from 'react';

import {
  usePageContext,
  useMainPageLoadModule,
} from '@xing-com/crate-entity-pages-common';
import { Column, Row } from '@xing-com/xing-grid';

import { ModuleError } from '../components/module-error/module-error';
import { NewsItem } from '../components/news-item/news-item';
import { NewsList } from '../components/news-list/news-list';
import { NewsContextProvider } from '../contexts/news-context/news-context';
import { EntityPageModuleDocument } from '../graphql/queries/news-sub-module-query.gql-types';
import * as Styled from './news-detail.style';

export const NewsDetail: FC = () => {
  useMainPageLoadModule();
  const { pageContext } = usePageContext() ?? {};
  const { pageId } = pageContext ?? {};
  const { data, loading, error, refetch } = useQuery(EntityPageModuleDocument, {
    variables: { id: pageId ?? '', moduleType: 'news' },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
  }, []);

  if (error) {
    return <ModuleError refetch={refetch} />;
  }

  return (
    <NewsContextProvider
      isDetailPage={true}
      hasSubpage={data?.entityPageModule?.properties?.hasSubpage}
    >
      <Styled.Wrapper>
        {loading ? (
          <Row>
            <Column sizeDesktop={10} offsetDesktop={1} size={12}>
              <NewsItem.Skeleton smallActor={true} />
            </Column>
          </Row>
        ) : (
          <NewsList loadingSkeletonCount={3} />
        )}
      </Styled.Wrapper>
    </NewsContextProvider>
  );
};
