import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useEffect, useState } from 'react';

import { PostingPublicationState } from '@xing-com/crate-common-graphql-types';
import { useEditContext } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import type { EntityPageFeedCollectionFragment } from '../../graphql/fragments/entity-page-feed-collection-fragment.gql-types';

export type NewsContextType = {
  size: number;
  hasSubpage?: boolean;
  isDetailPage?: boolean;
  selectedPosting?: EntityPageFeedCollectionFragment['object'] | null;
  currentPublicationState: PostingPublicationState;
  setSelectedPosting: Dispatch<
    SetStateAction<EntityPageFeedCollectionFragment['object'] | null>
  >;
  setCurrentPublicationState: Dispatch<SetStateAction<PostingPublicationState>>;
};
export const NewsContext = createContext<NewsContextType | undefined>(
  undefined
);

type NewsContextProviderProps = {
  isDetailPage?: boolean;
  hasSubpage?: boolean;
};
export const NewsContextProvider: FC<
  PropsWithChildren<NewsContextProviderProps>
> = ({ children, isDetailPage = false, hasSubpage = true }) => {
  const { isEditing } = useEditContext();
  const { isLoggedOut } = useLoginState();

  const [selectedPosting, setSelectedPosting] = useState<
    EntityPageFeedCollectionFragment['object'] | undefined
  >();
  const [currentPublicationState, setCurrentPublicationState] =
    useState<PostingPublicationState>(PostingPublicationState.Published);

  let size = 10;

  if (hasSubpage) size = 3;
  // IMPORTANT: This has been reduced to 10 due to performance issues
  // if (isLoggedOut) size = 30;
  if (isLoggedOut) size = 10;

  useEffect(() => {
    if (!isEditing) setSelectedPosting(null);
  }, [isEditing]);

  return (
    <NewsContext.Provider
      value={{
        size,
        hasSubpage,
        isDetailPage,
        selectedPosting,
        currentPublicationState,
        setSelectedPosting,
        setCurrentPublicationState,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};
