import styled from 'styled-components';

import { spaceL, spaceS, spaceXS } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const EmployeeData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${spaceS};
`;

export const TotalEmployees = styled(BodyCopy)`
  font-weight: bold;
  text-align: right;
  margin-bottom: 20px;
`;

export const ProfileImage = styled.img`
  width: 142px;
  height: 142px;
`;

export const Name = styled.div`
  margin-top: 0;
  margin-bottom: ${spaceXS};
`;

export const CTA = styled.div`
  font-weight: 700;
  font-size: var(--fontSize400);
`;

export const BlurBackground = styled.div`
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  border: none;
  padding: ${spaceL};
`;

export const SkeletonText = styled.div`
  margin-bottom: var(--space10);
`;

export const HeadlineClamped = styled.div`
  display: none;
`;
