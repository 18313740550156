import Autolinker from 'autolinker';
import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';

import { useCommboxContext } from './use-commbox';
import { useCommboxFormContext } from './use-commbox-form-context';

const Linker = new Autolinker({
  email: false,
  phone: false,
});

export const useDebouncedLinkShare = () => {
  const { attachmentType, setAttachmentType } = useCommboxContext();
  const { setLinkShareUrl } = useCommboxFormContext();

  const debouncedLinkShare = useMemo(
    () =>
      debounce((plainTextMessage) => {
        if (attachmentType !== 'EMPTY' || !plainTextMessage) return;

        const matches: any = Linker.parse(plainTextMessage);

        if (matches?.length) {
          setLinkShareUrl(matches[0].getUrl());
          setAttachmentType('LINK_PREVIEW');
        }
      }, 1000),
    [attachmentType]
  );

  useEffect(() => {
    return () => debouncedLinkShare.cancel();
  }, [debouncedLinkShare]);

  return { debouncedLinkShare };
};
