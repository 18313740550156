import throttle from 'lodash/throttle';
import type { ReactElement, SyntheticEvent } from 'react';
import { useCallback } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';

import type { ButtonProps } from '@xing-com/button';
import {
  useFeatureSwitch,
  useNotifications,
  useViewerData,
} from '@xing-com/hub';
import { IconSearch, IconArrowLeft, IconMessages } from '@xing-com/icons';
import { Link } from '@xing-com/link';
import { IconWithBadge } from '@xing-com/platform-layout-icon-with-badge';
import { MeMenuDropdownList } from '@xing-com/platform-layout-me-menu';
import { ProductsMenuWithItemsLoggedIn } from '@xing-com/platform-layout-products-menu';
import { ProfileImage } from '@xing-com/profile-image';
import { ProgressBadge } from '@xing-com/progress-badge';
import { SideSheet } from '@xing-com/side-sheet';
import ttt from '@xing-com/ticktricktrack';
import { trackingURL, eitherOrWithCount } from '@xing-com/xing-tracking';

import { SearchDropdown } from './search-dropdown';
import {
  Container,
  Actions,
  Placebo,
  H1,
  H2,
  H3,
  ProfileImageWrapper,
  BackButton,
  SubHeaderContainer,
  SubHeaderButton,
  SubHeaderLink,
  MessengerLink,
  StyledTitleSection,
} from './top-bar.styles';

type TopBarNavItemProps = {
  icon: React.FunctionComponent;
  notificationKey: string;
  href: string;
  title: string;
  hasMessengerAction: boolean;
  tracking: Record<string, string | [string, string]>;
};

export const TopBarNavItem: React.FC<TopBarNavItemProps> = ({
  icon,
  notificationKey,
  href,
  hasMessengerAction,
  tracking,
  ...props
}) => {
  const notifications = useNotifications();

  const notificationCount =
    notifications && notifications[notificationKey || ''];

  const trackingConditions = {
    sc_o_PropActionOrigin: eitherOrWithCount(notificationCount),
  };

  const completeTrackingURL = trackingURL(
    href,
    'tracking',
    trackingConditions
  )({ tracking });

  if (hasMessengerAction) {
    return (
      <MessengerLink {...props} to={completeTrackingURL}>
        <IconWithBadge icon={icon} count={notificationCount} />
      </MessengerLink>
    );
  } else {
    return <React.Fragment />;
  }
};

// public API props
export type TopBarProps = {
  action?: React.ReactNode;
  /** @callback */
  backButtonOnClick?: (e: React.MouseEvent) => void;
  backButtonUrl?: string;
  /** section name of custom link (e.g.: you) */
  backButtonUrlAriaLabelSection?: string;
  /** @callback */
  titleSectionOnClick?: (e: React.MouseEvent) => void;
  titleSectionUrl?: string;
  /** @deprecated use skipSearchAndMessenger instead */
  skipSearch?: boolean;
  /** @deprecated use skipAdditionalButtons instead */
  skipSearchAndMessenger?: boolean;
  skipAdditionalButtons?: boolean;
  subTitle?: string;
  title?: string;
  type?: 'logo' | 'header' | 'subheader';
  showOnLargerScreens?: boolean;
  subHeaderContent?: React.ReactNode;
};

type BackButtonType = Pick<
  ButtonProps,
  'aria-label' | 'to' | 'onClick' | 'size' | 'variant' | 'icon'
> & {
  size: 'medium';
  icon: typeof IconArrowLeft;
};

export const TopBar: React.FC<TopBarProps> = ({
  action,
  backButtonOnClick,
  backButtonUrl,
  backButtonUrlAriaLabelSection,
  titleSectionOnClick,
  titleSectionUrl,
  skipSearch = false,
  skipSearchAndMessenger = false,
  skipAdditionalButtons = false,
  showOnLargerScreens = false,
  subTitle,
  title,
  type,
  ...props
}) => {
  let titleSection: React.ReactNode;
  let subheader: React.ReactNode;
  let hasMessengerAction = false;

  const viewerData = useViewerData();

  const profileImageUrl = (viewerData?.data?.user?.profileImage ?? [])[0]?.url;

  const intl = useIntl();

  const networkEfficiencyIndicator =
    viewerData?.data?.networkEfficiencyIndicator || {};

  const [isSideSheetOpen, setIsSideSheetOpen] = React.useState(false);

  const { score, userTypeSupported } = networkEfficiencyIndicator;
  const neffiValue = score
    ? Math.ceil(Math.round(score * 100) / 5) * 5
    : 'not_available';

  const openSideSheet = useCallback(
    (event: SyntheticEvent) => {
      setIsSideSheetOpen(true);

      ttt.event('PropTrackAction', {
        PropTrackAction: 'navigation_me_menu',
        PropActionOrigin: `navigation_neffi_${neffiValue}`,
      });

      event.preventDefault();
    },
    [neffiValue]
  );

  const closeSideSheet = useCallback(() => {
    setIsSideSheetOpen(false);
  }, []);

  const showProfileFillingIndicator = userTypeSupported && score && score !== 1;

  // considering the 2 deprecated props
  skipAdditionalButtons =
    skipAdditionalButtons || skipSearchAndMessenger || skipSearch;

  // Helper method to avoid switch clause fall-through errors on TS
  const _getDefaultTitleSection = (): ReactElement => {
    const subHeaderContent = (
      <React.Fragment>
        <H2 data-testid="top-bar-title-h2" size="large">
          {title}
        </H2>
        {subTitle && (
          <H3 data-testid="top-bar-title-h3" size="small">
            {subTitle}
          </H3>
        )}
      </React.Fragment>
    );
    if (titleSectionUrl) {
      return (
        <SubHeaderLink to={titleSectionUrl}>{subHeaderContent}</SubHeaderLink>
      );
    } else if (titleSectionOnClick) {
      return (
        <SubHeaderButton
          data-testid="top-bar-subheader-button"
          onClick={titleSectionOnClick}
        >
          {subHeaderContent}
        </SubHeaderButton>
      );
    }
    return <SubHeaderContainer>{subHeaderContent}</SubHeaderContainer>;
  };

  const tracking = {
    sc_o: 'navigation_header',
    sc_o_PropActionOrigin: `navigation_neffi_${neffiValue}`,
    expandNeffi: true,
  };

  const completeTrackingUrl = trackingURL('/profile', 'tracking')({ tracking });

  const backButtonProps: BackButtonType = {
    'aria-label': intl.formatMessage({
      id: 'TOP_BAR_BACK_BUTTON_ARIA_LABEL',
    }),
    icon: IconArrowLeft,
    size: 'medium',
    onClick: backButtonOnClick,
  };

  switch (type) {
    case 'logo':
      titleSection = (
        <StyledTitleSection>
          <Link to={completeTrackingUrl} onClick={openSideSheet}>
            <ProfileImageWrapper>
              <ProfileImage
                src={profileImageUrl}
                size="xsmall"
                data-testid="header-profile-logo"
                progressBadge={
                  showProfileFillingIndicator ? (
                    <ProgressBadge
                      progress={Math.round(score * 100)}
                      data-testid="top-bar-profile-progress-badge"
                    />
                  ) : null
                }
              />
            </ProfileImageWrapper>
          </Link>
          <SideSheet
            dimmerTitle={intl.formatMessage({
              id: 'TOP_BAR_ME_MENU_DIMMER_ARIA_LABEL',
            })}
            onOutsideClick={closeSideSheet}
            onSwipeLeft={closeSideSheet}
            show={isSideSheetOpen}
          >
            <MeMenuDropdownList />
          </SideSheet>
        </StyledTitleSection>
      );
      hasMessengerAction = true;
      break;
    case 'header':
      titleSection = (
        <H1
          size="xlarge"
          data-qa="top-bar-header"
          data-testid="top-bar-profile-h1"
        >
          {title}
        </H1>
      );
      hasMessengerAction = true;

      break;
    case 'subheader':
      if (backButtonUrl) {
        if (backButtonUrlAriaLabelSection) {
          backButtonProps['aria-label'] = intl.formatMessage(
            {
              id: 'TOP_BAR_BACK_BUTTON_ARIA_LABEL_CUSTOM',
            },
            { section: backButtonUrlAriaLabelSection }
          );
        }
        backButtonProps.to = backButtonUrl;
      } else {
        backButtonProps.onClick = (e) => {
          backButtonOnClick && backButtonOnClick(e);
          if (global?.window?.history?.length > 1) {
            global.window.history.back();
            e.preventDefault();
          }
        };
        backButtonProps.to = '/';
      }
      subheader = (
        <BackButton
          data-testid="top-bar-back-button"
          data-qa="top-bar-back"
          {...backButtonProps}
        />
      );
      titleSection = _getDefaultTitleSection();
      hasMessengerAction = true;
      break;
    default:
      titleSection = _getDefaultTitleSection();
  }

  const [scrollTopPosition, setScrollTopPosition] = React.useState(true);
  const { formatMessage } = useIntl();
  React.useEffect(() => {
    const setLineOnTop = throttle(
      () => setScrollTopPosition(window.pageYOffset === 0),
      250
    ); // 250ms

    window.addEventListener('scroll', setLineOnTop);
    return () => {
      window.removeEventListener('scroll', setLineOnTop);
    };
  }, []);

  const isNewNavigation = useFeatureSwitch('nav_update_mid_2024');

  return (
    <React.Fragment>
      <Container
        data-qa="top-bar-container"
        $showOnLargerScreens={showOnLargerScreens}
        $type={type}
        $isScrolling={scrollTopPosition}
        {...props}
      >
        {subheader}
        {titleSection}
        <Actions>
          {action}
          {!skipAdditionalButtons ? (
            <>
              <TopBarNavItem
                data-testid="top-bar-icon-messenger"
                icon={IconMessages}
                title={formatMessage({ id: 'VERTICAL_NAVIGATION_MESSAGES' })}
                href="/chats"
                notificationKey="unreadChats"
                tracking={{
                  sc_o: 'navigation_messages',
                  sc_o_PropActionOrigin: [
                    'navigation_badge_messages',
                    'navigation_badge_no_badge',
                  ],
                }}
                hasMessengerAction={hasMessengerAction}
              />
              <SearchDropdown
                icon={IconSearch}
                title={formatMessage({ id: 'FRAME_HEADER_SEARCH' })}
              />
              {isNewNavigation ? (
                <ProductsMenuWithItemsLoggedIn
                  hideTitleOnSmallerScreens={showOnLargerScreens}
                />
              ) : null}
            </>
          ) : null}
        </Actions>
      </Container>
      <Placebo />
    </React.Fragment>
  );
};
