import styled from 'styled-components';

import { ProfileInfoAdapter as InnerProfileInfo } from '@xing-com/crate-entity-pages-common';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { scale100, scale110, scale130, spaceS } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';
import { Column } from '@xing-com/xing-grid';

export const Title = styled(BodyCopy)`
  margin-bottom: ${scale130};
`;

export const Container = styled(Column)`
  margin: 0 auto;

  @media ${mediaSmallOrTiny} {
    padding: 0 ${scale100};
  }
`;

export const List = styled.ul`
  margin: ${scale110} 0;
  padding-left: 0;
`;

export const ListItem = styled.li`
  display: block;
  width: 100%;
  margin-bottom: ${scale130};
`;

export const ProfileInfo = styled(InnerProfileInfo)`
  p {
    margin-bottom: 0;
  }

  & h2 {
    margin-bottom: 0;
  }

  & strong {
    margin-right: ${spaceS};
  }
`;
