import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import {
  redirectToLogin,
  redirectToRegister,
} from '@xing-com/crate-entity-pages-common';

import Icon from '../../assets/icon-fencing.png';
import { LIMIT_EMPLOYEES_LIST_LOGGED_OUT } from '../../helpers';
import type { EmployeeCard } from '../../types/employee-card';
import { EmployeesCardHorizontal } from '../employees-card-horizontal/employees-card-horizontal';
import * as Styled from './employees-fencing-view.styles';

type FencingPopupProps = {
  total?: number;
};
const FencingPopup: FC<FencingPopupProps> = ({ total }) => {
  const { $t } = useIntl();

  return (
    <Styled.FencingPopupWrapper>
      <Styled.FencingPlaceholderOverlay />
      <Styled.FencingPlaceholder>
        <Styled.FencingPlaceholderPopup>
          <Styled.FencingImage src={Icon} alt="Fencing" />
          <Styled.FencingPopupPanel>
            <Styled.FencingText noMargin size={'xlarge'}>
              <FormattedMessage id="EP_EMPLOYEE_FENCED" values={{ total }} />
            </Styled.FencingText>

            <Styled.FencingButtonPanel>
              <Button
                variant={'primary'}
                size={'large'}
                onClick={() => redirectToRegister()}
              >
                <FormattedMessage id="EP_EMPLOYEES_BUTTON_REGISTER_NOW" />
              </Button>
              <Styled.FencingSignInButton
                onClick={() => redirectToLogin('employeesEntityPage')}
              >
                {`${$t({ id: 'EP_EMPLOYEES_OR' })} ${$t({ id: 'EP_EMPLOYEES_SIGNIN' })}`}
              </Styled.FencingSignInButton>
            </Styled.FencingButtonPanel>
          </Styled.FencingPopupPanel>
        </Styled.FencingPlaceholderPopup>
      </Styled.FencingPlaceholder>
    </Styled.FencingPopupWrapper>
  );
};

type EmployeesFencingViewProps = {
  employeesCards: EmployeeCard[];
  total?: number;
};
export const EmployeesFencingView: FC<EmployeesFencingViewProps> = ({
  employeesCards,
  total = 0,
}) => (
  <Styled.FencingContainer>
    {employeesCards.map((employeeCard) => (
      <EmployeesCardHorizontal
        key={employeeCard.id}
        card={{
          ...employeeCard,
          flag: undefined,
        }}
      />
    ))}

    {total >= LIMIT_EMPLOYEES_LIST_LOGGED_OUT && <FencingPopup total={total} />}
  </Styled.FencingContainer>
);
