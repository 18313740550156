import type { FC } from 'react';

import { Carousel } from '@xing-com/carousel';

import { EmployeesCardVertical } from '../employees-card-vertical/employees-card-vertical';
import * as Styled from './employees-carousel.styles';

export const EmployeesCarouselSkeleton: FC = () => {
  return (
    <Styled.CarouselWrapper>
      <Carousel>
        <EmployeesCardVertical.Skeleton />
        <EmployeesCardVertical.Skeleton />
        <EmployeesCardVertical.Skeleton />
        <EmployeesCardVertical.Skeleton />
        <EmployeesCardVertical.Skeleton />
      </Carousel>
    </Styled.CarouselWrapper>
  );
};
