import { useQuery } from '@apollo/client';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';

import {
  LazyLoading,
  LAZY_LOADING_TYPE_BUTTON,
  EmptyState,
  WarningMessageBox as Error,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { EmployeesFencingView } from '../components/employees-fencing-view/employees-fencing-view';
import { EmployeesGrid } from '../components/employees-grid/employees-grid';
import { EmployeesDocument } from '../graphql/queries/employees-query.gql-types';
import {
  LIMIT_EMPLOYEES_LIST,
  LIMIT_EMPLOYEES_LIST_LOGGED_OUT,
  QUERY_CONSUMER_DETAIL,
  EMPLOYEES_SORTING,
} from '../helpers';
import { useNetworkRequestLoggedOutAction } from '../hooks/use-network-request-logged-out-action';
import { createEmployeeCards } from '../model/employee-card';
import * as Styled from './employees-detail.styles';

const i18nKeys = {
  single: 'EP_EMPLOYEE',
  multiple: 'EP_EMPLOYEES',
};

type RenderErrorProps = {
  refetch: () => void;
};
const RenderError: FC<RenderErrorProps> = ({ refetch }) => (
  <div data-testid={'errorContainer'}>
    <Error
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

export const EmployeesDetail: FC = () => {
  const { $t } = useIntl();
  const { isLoggedIn } = useLoginState();
  const { pageContext } = usePageContext() ?? {};

  const [page, setPage] = useState(0);

  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(
    EmployeesDocument,
    {
      variables: {
        id: pageContext?.companyId as string,
        first: isLoggedIn
          ? LIMIT_EMPLOYEES_LIST
          : LIMIT_EMPLOYEES_LIST_LOGGED_OUT,
        query: { consumer: QUERY_CONSUMER_DETAIL, sort: EMPLOYEES_SORTING },
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    }
  );

  const total = data?.company?.employees?.total;
  const employeesEdged = data?.company?.employees?.edges ?? [];
  const employeesNodes = employeesEdged
    .map((edge) => edge?.node)
    .filter((node) => !!node);

  useNetworkRequestLoggedOutAction({
    employees: employeesNodes,
    loading,
  });

  if (error) return <RenderError refetch={refetch} />;

  const employeesCards = createEmployeeCards(employeesNodes);

  const totalEmployeeKey = total === 1 ? i18nKeys.single : i18nKeys.multiple;

  const handleOnLazyLoad = async () => {
    const endCursor = data?.company?.employees?.pageInfo.endCursor;

    await fetchMore({
      variables: {
        id: pageContext?.companyId as string,
        first: LIMIT_EMPLOYEES_LIST,
        after: endCursor,
        query: {
          consumer: QUERY_CONSUMER_DETAIL,
          sort: EMPLOYEES_SORTING,
        },
      },
      // @ts-expect-error TODO: fix this
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          company: {
            ...prev.company,
            employees: {
              ...prev.company?.employees,
              edges: [
                ...(prev?.company?.employees?.edges || []),
                ...(fetchMoreResult?.company?.employees?.edges || []),
              ],
              pageInfo: fetchMoreResult?.company?.employees?.pageInfo,
            },
          },
        };
      },
    });

    setPage(page + 1);
  };

  const handleOnAddContact = () => {
    refetch({
      id: pageContext?.companyId as string,
      first: LIMIT_EMPLOYEES_LIST * (page + 1),
      query: {
        consumer: QUERY_CONSUMER_DETAIL,
        sort: EMPLOYEES_SORTING,
      },
    });
  };

  const hasNextPage = data?.company?.employees?.pageInfo.hasNextPage;
  const loadingFirstRender = loading && networkStatus !== 3;
  const isPaginationEnabled = loadingFirstRender
    ? false
    : !hasNextPage
      ? false
      : true;

  if (total === 0) {
    return (
      <EmptyState
        headlineCopyKey="EP_EMPLOYEES_NUMBER_EMPTY_STATE"
        textCopyKey="EP_EMPLOYEES_EMPTY_STATE"
        statusCode={isLoggedIn ? undefined : 404}
      />
    );
  }

  const isFenced =
    !isLoggedIn &&
    total &&
    (total > LIMIT_EMPLOYEES_LIST_LOGGED_OUT ||
      (employeesCards?.length ?? 0) > LIMIT_EMPLOYEES_LIST_LOGGED_OUT);

  return (
    <>
      {total && (
        <Styled.TotalEmployees>{`${total} ${$t({ id: totalEmployeeKey })}`}</Styled.TotalEmployees>
      )}
      {loadingFirstRender ? (
        <EmployeesGrid.Skeleton />
      ) : (
        <>
          {isFenced ? (
            <EmployeesFencingView
              employeesCards={employeesCards}
              total={total}
            />
          ) : (
            <EmployeesGrid
              employeesCards={employeesCards}
              trackingModuleType="Subpage"
              onAddContact={handleOnAddContact}
            />
          )}
        </>
      )}
      {isLoggedIn && (
        <LazyLoading
          type={LAZY_LOADING_TYPE_BUTTON}
          isLoading={networkStatus === 3}
          enabled={isPaginationEnabled}
          onLazyLoad={handleOnLazyLoad}
          ctaText="EP_EMPLOYEES_LOAD_MORE_CTA"
        />
      )}
    </>
  );
};
