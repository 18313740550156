import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { PostingPublicationState } from '@xing-com/crate-common-graphql-types';
import {
  FormattedTimestamp,
  usePageContext,
  useEditContext,
} from '@xing-com/crate-entity-pages-common';

import { PAGES_URL_PREFIX, PINNED } from '../../../config/constants';
import { useNewsContext } from '../../../hooks/use-news-context/use-news-context';
import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';
import { getActionTextIdByType } from '../../../utils';
import { NewsStatusFlag } from '../news-status-flag/news-status-flag';
import * as Styled from './news-item-actor.styles';

export const NewsItemActor: FC = () => {
  const { hasSubpage } = useNewsContext();
  const { post, publicationState } = useNewsItemContext();
  const { pageContext } = usePageContext() ?? {};
  const { isEditing } = useEditContext();
  const { focusType } = pageContext ?? {};

  const createdAt =
    post.object?.__typename === 'EntityPageContentFeedPostingPost'
      ? (post.object.createdAt ?? '')
      : undefined;

  const date =
    publicationState === PostingPublicationState.Published ||
    publicationState === undefined
      ? post.publishedAt
      : createdAt;

  let urlPath = PAGES_URL_PREFIX;

  switch (focusType) {
    // case EntityPageFocusType.<SOMETHING ELSE>:
    //   urlPath = ANOTHER_PREFIX;
    //   break;
    default:
      urlPath = PAGES_URL_PREFIX;
      break;
  }

  // URLs here are assumed to start with a slash '/'
  const permalink = `${urlPath}/${pageContext?.pageSlug as string}`;
  const url = `${permalink}${
    permalink.includes('?') ? '&' : '?'
  }sc_o=entity_page_news_module_to_publisher`;

  const actionTextId = getActionTextIdByType(post.object?.__typename);
  const itemStatusState = post.isPinned ? PINNED : publicationState;

  // whether to show `X posted this article` legend or not
  let displayAction = false;

  if (isEditing) {
    if (!publicationState && !post.isPinned) {
      displayAction = true;
    }
  } else {
    if (!post.isPinned) {
      displayAction = true;
    }
  }

  const showFollowButton = false; // following is currently disabled here
  return (
    <>
      {hasSubpage ? (
        <Styled.Actor>
          <Styled.ActorAnchor href={url}>
            <Styled.ProfileImage
              size="small"
              type="company"
              profileName={pageContext?.pageTitle}
              // @ts-expect-error FIXME: SC6
              alt={pageContext?.pageTitle ?? ''}
              src={pageContext?.logo}
            />
          </Styled.ActorAnchor>
          <Styled.ActorContent>
            <Styled.ActorNameWrap>
              <Styled.ActorName href={url}>
                {pageContext?.pageTitle}
              </Styled.ActorName>
            </Styled.ActorNameWrap>
            <Styled.ActorSubtitle>
              {((isEditing && publicationState) || post.isPinned) &&
                itemStatusState && <NewsStatusFlag state={itemStatusState} />}
              {displayAction && (
                <span>
                  {actionTextId && <FormattedMessage id={actionTextId} />}
                </span>
              )}
              <Styled.DotSeparator>.</Styled.DotSeparator>
              <span>
                {date && <FormattedTimestamp date={new Date(date)} />}
              </span>
            </Styled.ActorSubtitle>
          </Styled.ActorContent>
          {showFollowButton && <Styled.FollowButton />}
        </Styled.Actor>
      ) : (
        <Styled.NoActor>
          {post.isPinned ? (
            <NewsStatusFlag state="PINNED" />
          ) : (
            date && <FormattedTimestamp date={new Date(date)} />
          )}
        </Styled.NoActor>
      )}
    </>
  );
};
