import type { FC } from 'react';
import { useState } from 'react';

import { BottomSheet } from '@xing-com/bottom-sheet';
import { PostingPublicationState } from '@xing-com/crate-common-graphql-types';
import { useDialogContext } from '@xing-com/crate-entity-pages-common';
import { IconEdit } from '@xing-com/icons';
import { List, ListItem } from '@xing-com/list';

import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';
import { ActionDelete } from './action-delete';
import { ActionEdit } from './action-edit';
import { ActionHide } from './action-hide';
import { ActionPin } from './action-pin';
import { ActionPromote } from './action-promote';
import * as Styled from './news-item-actions.styles';

type NewsItemActionsProps = {
  onItemActionCompleted?: (action: 'edit' | 'pin' | 'hide' | 'delete') => void;
  onItemActionStarted?: (action: 'edit' | 'pin' | 'hide' | 'delete') => void;
};
export const NewsItemActions: FC<NewsItemActionsProps> = ({
  onItemActionCompleted = () => undefined,
  onItemActionStarted = () => undefined,
}) => {
  const { post, publicationState } = useNewsItemContext();
  const { dialogConfirmation, setDialogConfirmation } = useDialogContext();

  const itemId =
    post.object?.__typename === 'EntityPageContentFeedPostingPost' ||
    post.object?.__typename === 'EntityPageContentFeedCompanyArticlePost'
      ? post.object?.id
      : undefined;

  const [show, setShow] = useState<boolean>();

  return (
    <>
      <Styled.ActorActions
        isDesktop={true}
        data-testid="POSTING_ACTIONS"
        id={`POSTING_${itemId}`}
      >
        <ActionEdit
          onItemActionCompleted={() => onItemActionCompleted('edit')}
          onItemActionStarted={() => onItemActionStarted('edit')}
        />
        {publicationState !== PostingPublicationState.Scheduled && (
          <ActionPin
            itemId={itemId}
            onItemActionCompleted={() => onItemActionCompleted('pin')}
            onItemActionStarted={() => onItemActionStarted('pin')}
          />
        )}
        <ActionHide
          itemId={itemId}
          onItemActionCompleted={() => onItemActionCompleted('hide')}
          onItemActionStarted={() => onItemActionStarted('hide')}
        />
        <ActionDelete
          itemId={itemId}
          onItemActionCompleted={() => onItemActionCompleted('delete')}
          onItemActionStarted={() => onItemActionStarted('delete')}
        />
        <ActionPromote />
      </Styled.ActorActions>
      <Styled.ActorActions
        isDesktop={false}
        id={`POSTING_${itemId}`}
        data-testid="POSTING_ACTIONS"
      >
        <Styled.EditButton
          data-testid="NEWS_ITEM_ACTION_BUTTON"
          size="small"
          icon={IconEdit}
          onClick={() => setShow(true)}
        />
        <ActionPromote />
      </Styled.ActorActions>
      {show && (
        <BottomSheet
          dimmerTitle="close"
          show={show}
          onOutsideClick={() => setShow(false)}
          data-testid="NEWS_ITEM_ACTION_SHEET"
        >
          <List>
            <ListItem>
              <ActionEdit
                desktop={false}
                onItemActionCompleted={() => onItemActionCompleted('edit')}
                onItemActionStarted={() => onItemActionStarted('edit')}
              />
            </ListItem>
            <ListItem>
              <ActionPin
                desktop={false}
                itemId={itemId}
                onItemActionCompleted={() => {
                  setShow(false);
                  onItemActionCompleted('pin');
                }}
              />
            </ListItem>

            <ListItem>
              <ActionHide
                desktop={false}
                itemId={itemId}
                onItemActionCompleted={() => {
                  setShow(false);
                  onItemActionCompleted('hide');
                }}
              />
            </ListItem>

            <ListItem>
              <ActionDelete
                desktop={false}
                itemId={itemId}
                onItemActionCompleted={() => {
                  setShow(false);
                  onItemActionCompleted('delete');
                }}
              />
            </ListItem>
          </List>
        </BottomSheet>
      )}
      <Styled.Dialog
        show={Boolean(dialogConfirmation)}
        data-testid="POSTING_DIALOG"
        isDestructive
        // @ts-expect-error FIXME: SC6
        flavour="xdl"
        headline={dialogConfirmation?.headline as string}
        text={dialogConfirmation?.text as string}
        cancelLabel={dialogConfirmation?.cancel as string}
        confirmLabel={dialogConfirmation?.confirm as string}
        onCancel={() => {
          setDialogConfirmation(null);
        }}
        onConfirm={() => {
          if (typeof dialogConfirmation?.dialogAction === 'function') {
            dialogConfirmation?.dialogAction?.();
          }
          setDialogConfirmation(null);
        }}
      />
    </>
  );
};
