import type { FC } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { FormattedMessage } from 'react-intl';

import { FormattedTimestamp } from '@xing-com/crate-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';
import { ProfileImage } from '@xing-com/profile-image';

import matchIcon from '../../assets/match.svg';
import type { JobCardValues } from '../../types/job-card';
import { JobsBookmarkContainer } from '../jobs-bookmark/jobs-bookmark-container';
import * as Styled from './job-card.styles';

type JobCardProps = JobCardValues & {
  onBookmarkUpdate?: () => void;
};
export const JobCard: FC<JobCardProps> = ({
  id,
  link,
  companyLogo,
  companyName,
  bookmarked,
  title,
  location,
  refreshedAt,
  match,
  onBookmarkUpdate = () => undefined,
}) => {
  const handleOnCardClick = () => {
    const trackingLink = getTrackingUrl({
      to: link,
      trackingKey: 'viewJobProfile',
    });
    window.open(trackingLink, '_blank');
  };

  return (
    <Styled.JobCard
      data-testid={`JOB-CARD-${id}`}
      key={id}
      href={link}
      onClick={(ev) => {
        ev.preventDefault();
        handleOnCardClick();
      }}
      linkProps={{
        'aria-label': 'job details link',
      }}
    >
      <Styled.CardHeader>
        {companyLogo && (
          <ProfileImage
            size="xxsmall"
            profileName={companyName}
            src={companyLogo}
            type="company"
          />
        )}
        <Styled.CompanyName size={'xsmall'}>{companyName}</Styled.CompanyName>
        <JobsBookmarkContainer
          jobId={id}
          bookmarked={bookmarked}
          onCreateBookmarkCompleted={onBookmarkUpdate}
          onDeleteBookmarkCompleted={onBookmarkUpdate}
        />
      </Styled.CardHeader>
      <Styled.Body>
        <Styled.CardTitle size="medium">
          <Dotdotdot clamp={2}>{title}</Dotdotdot>
        </Styled.CardTitle>
        <Styled.Location size="xsmall">{location}</Styled.Location>
      </Styled.Body>
      <Styled.Footer>
        <Styled.MatchContainer>
          {!!match && (
            <>
              <Styled.MatchIcon src={matchIcon} alt="Match" loading="lazy" />
              <Styled.FooterText noMargin>
                <FormattedMessage
                  id="EP_JOBS_MATCH"
                  defaultMessage="EP_JOBS_MATCH"
                  values={{ number: match }}
                />
              </Styled.FooterText>
            </>
          )}
        </Styled.MatchContainer>
        {refreshedAt && (
          <Styled.FooterText noMargin>
            <FormattedTimestamp date={refreshedAt} />
          </Styled.FooterText>
        )}
      </Styled.Footer>
    </Styled.JobCard>
  );
};
