import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import {
  ModuleHeader,
  usePageContext,
  UpsellBanner,
  WarningMessageBox as Error,
  useModuleContext,
} from '@xing-com/crate-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';

import { ContactsGrid } from '../components/contacts-grid/contacts-grid';
import { EntityPagesContactsListDocument } from '../graphql/queries/entity-pages-contacts-list.gql-types';
import { createContactsCards } from '../models/contact-card/contact-card';
import * as Styled from './contacts-module.styles';

export const ContactsModule: FC = () => {
  const { moduleContext } = useModuleContext();
  const { pageContext } = usePageContext() ?? {};
  const upsellActive = pageContext?.upsellActive;

  const { data, loading, refetch } = useQuery(EntityPagesContactsListDocument, {
    variables: {
      id: pageContext?.pageSlug as string,
      limit: upsellActive ? 1 : 2,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const total = data?.entityPageContactUsers?.total ?? 0;
  const contactsNodes = data?.entityPageContactUsers?.collection ?? [];
  const contactsCards = createContactsCards(contactsNodes);

  const showMore =
    total > 2
      ? {
          to: getTrackingUrl({
            to: `${pageContext?.basePath}/${pageContext?.pageSlug}/contacts`,
            trackingKey: 'viewAllContacts',
          }),
          copyKey: 'EP_ALL_CONTACTS',
          testId: `${moduleContext?.moduleType}_subpage_link`,
        }
      : null;

  return (
    <div data-testid="CONTACTS_MODULE">
      <ModuleHeader
        editTestId="EDIT_BUTTON_ON_CONTACTS"
        headlineCopyKey="EP_CONTACTS_MODULE_TITLE"
        editTo={`${pageContext?.basePath}/${pageContext?.pageSlug}/edit/contacts`}
        showMore={showMore}
      />

      <Styled.Layout $upsellActive={upsellActive}>
        {loading ? (
          <ContactsGrid.Skeleton />
        ) : contactsCards.length === 0 ? (
          <div data-testid={'errorContainer'}>
            <Error
              headerText="EP_ERROR_HEADER"
              bodyText="EP_ERROR_BODY"
              buttonText="EP_ERROR_RELOAD_CTA"
              onClick={() => refetch()}
            />
          </div>
        ) : (
          <>
            <Styled.ContactsGrid
              $upsellActive={upsellActive}
              contactCards={contactsCards}
              messageEntryPoint="entity_pages_main_contact_message"
              trackingModuleType="Main"
            />
            {upsellActive && (
              <UpsellBanner
                headerCopyKey="EP_UPSELL_CONTACT_HEADER"
                bodyCopyKey="EP_UPSELL_CONTACT_BODY"
                moduleName="contactsmodule"
                column
              />
            )}
          </>
        )}
      </Styled.Layout>
    </div>
  );
};
