import styled from 'styled-components';

import { VideoUpload } from '@xing-com/crate-entity-pages-common';
import { spaceM } from '@xing-com/tokens';

export const VideoUploadWrapper = styled.div`
  & h2 {
    margin-bottom: 0 !important;
  }
`;

export const VideUpload = styled(VideoUpload)`
  & div[class*='ctaContainer'] {
    & div > button:last-of-type {
      margin-left: ${spaceM};
    }
  }

  & div[class*='cropCtaContainer'] {
    & button:last-of-type {
      margin-left: ${spaceM};
    }
  }

  & div[class*='fileAddedContainer'] {
    & p {
      margin: 0 ${spaceM};
    }
  }
`;
