import { useState, useRef } from 'react';
import type { FC } from 'react';

import * as Types from '@xing-com/crate-common-graphql-types';
import type { EntityPageQuery } from '@xing-com/crate-entity-pages-common';
import {
  ModuleContextProvider,
  useEditContext,
  usePageContext,
  PageContext,
  ErrorBoundary,
} from '@xing-com/crate-entity-pages-common';
import {
  trackPageviewModules,
  trackModuleVisibleAction,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { Header } from '@xing-com/crate-entity-pages-header';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useFeatureSwitch } from '@xing-com/hub';
import Observer from '@xing-com/intersection-observer';
import { GridContainer } from '@xing-com/xing-grid';

import { CompanyBannersContainer } from '../../components/banners/company-banners-container/company-banners-container';
import { CustomerFeedBackButton } from '../../components/customer-feedback-button/customer-feedback-button';
import { EditBar } from '../../components/edit-bar/edit-bar';
import { Navigation } from '../../components/navigation/navigation';
import { modulesMap as MODULES } from '../../config/modules-map';
import { useAppcues } from '../../hooks/useAppcues/useAppcues';
import { useNavigationActiveItem } from '../../hooks/useNavigationActiveItem/useNavigationActiveItem';
import { useNavigationOffset } from '../../hooks/useNavigationOffset/useNavigationOffset';
import { useUpdateEditContext } from '../../hooks/useUpdateEditContext/useUpdateEditContext';
import { useUpdatePageContext } from '../../hooks/useUpdatePageContext/useUpdatePageContext';
import { useModulesLoaded } from './helpers';
import type { MainPageContainerProps } from './main-page';
import * as Styled from './main-page.styles';
import MetaDataContainer from './meta-data-container';

export type IntersectUseState = {
  [key: string]: boolean;
};

export type TrackedRef = {
  [key: string]: any;
  pageview?: boolean;
};

export type RealMainPageContainerProps = Pick<
  MainPageContainerProps,
  'pageSlug'
> & {
  pageModules: any;
  data?: EntityPageQuery;
};
export const RealMainPageContainer: FC<RealMainPageContainerProps> = ({
  pageSlug,
  pageModules,
  data,
}) => {
  const tracked = useRef<TrackedRef>({});
  const [intersected, setIntersected] = useState<IntersectUseState>({});

  const { isLoggedIn } = useLoginState();

  const canEditMobile = useFeatureSwitch('ep_mobileEdit', false);

  const { isEditing } = useEditContext();
  const { setPageContext } = usePageContext() ?? {};

  const entityPage =
    data?.entityPageEX?.__typename === 'EntityPage'
      ? (data.entityPageEX ?? undefined)
      : undefined;

  const isCompanyDraft =
    entityPage?.publicationStatus === 'DRAFT' &&
    entityPage?.focusType === Types.EntityPageFocusType.Company;

  const isEditor = !!entityPage?.userPageContext?.permissions?.canEdit;

  const pageModulesByType = pageModules
    ? pageModules
        // @ts-expect-error TODO: fix this type
        .map((pageModule) => pageModule && pageModule.type)
        // @ts-expect-error TODO: fix this type
        .filter((type) => type)
    : null;

  const { setModuleLoaded } = useModulesLoaded(pageModulesByType);

  const hasNewsModule = !!entityPage?.modules?.collection.find(
    (page) => page?.type === 'news'
  );
  const pageContext = useUpdatePageContext(entityPage, {
    modules: entityPage?.modules ?? undefined,
    hasNewsModule,
    setModuleLoaded,
  });
  const isPageContextSet = !!pageContext;

  const isEditContextSet = useUpdateEditContext(entityPage);

  const [activeIndex, forceActiveIndex, setIntersectionRatio] =
    useNavigationActiveItem(pageModulesByType);
  const [
    mainPageRef,
    navigationRef,
    navigationOffset,
    frameSize,
    navigationBarHeight,
  ] = useNavigationOffset();

  useAppcues();

  if (!isPageContextSet || !isEditContextSet) {
    return (
      <Styled.MainPageWrapper>
        <MetaDataContainer setDefault={true} />
        <Header.Skeleton />
      </Styled.MainPageWrapper>
    );
  }

  const handleModuleIntersect = (
    {
      isIntersecting,
      intersectionRatio,
    }: { isIntersecting: boolean; intersectionRatio: number },
    type: string,
    index: number
  ) => {
    setIntersectionRatio(type, intersectionRatio);

    if (!tracked.current[type] && isIntersecting) {
      tracked.current = { ...tracked.current, [type]: true };
      setIntersected({ ...intersected, [type]: true });
      trackModuleVisibleAction(type, index + 1);
    }
  };

  if (!tracked.current['pageview'] && isEditing !== null) {
    tracked.current = { ...tracked.current, pageview: true };

    const trackIsFollower: boolean =
      entityPage?.userPageContext?.userInteractions?.__typename ===
      'EntityPageUserInteractionFollow'
        ? Boolean(
            entityPage?.userPageContext?.userInteractions.followState
              ?.isFollowing
          )
        : false;

    if (entityPage?.id) {
      trackPageviewModules({
        isLoggedIn,
        isEditor,
        isEditing,
        modulesByType: pageModulesByType,
        id: entityPage?.id.split('.')[0],
        focusType: entityPage?.focusType as string,
        contractType: entityPage?.contract?.type,
        isFollowing: trackIsFollower,
      });
    }
  }

  // PageContext.Provider is important here for SSR. It avoids to wait for the useEffect that executes on client side only
  return (
    <PageContext.Provider
      value={{
        pageContext,
        // @ts-expect-error TODO: fix this type, I don't even know how this app even works
        setPageContext,
      }}
    >
      <Styled.MainPageWrapper ref={mainPageRef}>
        <MetaDataContainer
          title={entityPage?.title}
          url={entityPage?.links.self}
          image={entityPage?.logoImage?.[0].url ?? undefined}
          focusType={entityPage?.focusType}
        />
        {isEditor &&
          entityPage?.focusType === Types.EntityPageFocusType.Company && (
            <CompanyBannersContainer
              companyId={pageContext?.companyId}
              isDraft={isCompanyDraft}
            />
          )}

        {isEditor && <CustomerFeedBackButton module="main" />}

        {isEditor && (
          <EditBar
            pageSlug={pageSlug}
            displayNewsButtons={
              pageContext?.focusType === Types.EntityPageFocusType.Company
            }
            displayBackButton={false}
            contractType={entityPage?.contract?.type}
            globalId={entityPage?.globalId}
            logo={entityPage?.logoImage?.[0].url ?? undefined}
            pageTitle={entityPage?.title}
            pageId={entityPage?.id}
            displayManageUpdates={Boolean(
              pageModules.find(
                (pageModule: Types.EntityPageGenericModule) =>
                  pageModule?.type === 'news'
              )
            )}
            canEditOnMobile={canEditMobile}
          />
        )}

        <Styled.HeaderWrapper
          isShowingCompanyBanner={pageContext?.isShowingCompanyBanner ?? false}
        >
          <Header />
        </Styled.HeaderWrapper>
        <Navigation
          pageModulesByType={pageModulesByType!}
          navigationOffset={navigationOffset}
          frameSize={frameSize}
          activeIndex={activeIndex}
          forceActiveIndex={forceActiveIndex}
          navigationRef={navigationRef}
          navigationBarHeight={navigationBarHeight}
        />
        <GridContainer>
          <Styled.Row>
            <Styled.Column size={12}>
              {pageModules?.map(
                (pageModulesItem: Types.EntityPageGenericModule, i: number) => {
                  const { type, globalId, properties } = pageModulesItem;
                  const Module = MODULES[type] ? MODULES[type].module : null;

                  if (!Module) return null;

                  const moduleContext = {
                    moduleProperties: properties,
                    intersected: type ? intersected[type] : null,
                    moduleType: type,
                    moduleGlobalId: globalId,
                  };

                  return (
                    <ModuleContextProvider value={moduleContext} key={i}>
                      <Observer
                        onChange={(event: any) =>
                          type && handleModuleIntersect(event, type, i)
                        }
                        rootMargin={`-${navigationOffset}px 0px 0px 0px`}
                        threshold={[0, 0.2, 0.4, 0.6, 0.7, 1]}
                      >
                        <Styled.ModuleWrapper
                          id={`module-${type}`}
                          data-cy={`${type}Module`}
                          // TODO: remove this once the modules are ready
                          $canDisplayModules={true}
                          // $canDisplayModules={canDisplayModule(type)}
                        >
                          <ErrorBoundary onError={() => setModuleLoaded(type)}>
                            <Module
                              key={i}
                              isEditor={isEditor}
                              {...moduleContext}
                              {...pageContext}
                            />
                          </ErrorBoundary>
                        </Styled.ModuleWrapper>
                      </Observer>
                    </ModuleContextProvider>
                  );
                }
              )}
            </Styled.Column>
          </Styled.Row>
        </GridContainer>
      </Styled.MainPageWrapper>
    </PageContext.Provider>
  );
};
