import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  countryCode,
  PagesFormField,
  SmallGrid,
} from '@xing-com/crate-entity-pages-common';

import type { LocationStateType } from '../../types';
import {
  addParenthisisOnNumbers,
  removeParenthisisOnNumbers,
  removeWhiteSpaces,
  addHttpToWebsiteUrl,
} from './helpers';
import * as Styled from './location-form.styles';
import { locationValidation } from './validation';

type ListType = {
  value: string;
  text: string;
}[];

type LocationProps = {
  locationState: LocationStateType;
  setLocationState?: (locationState: any) => void;
  setLocationsTouched?: (locationTouched: any) => void;
};
export const LocationForm: FC<LocationProps> = ({
  locationState,
  setLocationState = () => undefined,
  setLocationsTouched = () => undefined,
}) => {
  const intl = useIntl();
  const [orderedByLanguageCountryList, setOrderedByLanguageCountryList] =
    useState<ListType>([{ value: '', text: '' }]);
  const [countryCodeObj, setCountryCodeObj] = useState<ListType>([
    { value: '', text: '' },
  ]);
  const commonFields = {
    validate: locationValidation,
    formState: locationState,
    setFormState: setLocationState,
    setFormTouched: setLocationsTouched,
    copyPrefix: 'EP_EDIT_LOCATION_',
  };

  useEffect(() => {
    setOrderedByLanguageCountryList(
      Object.keys(countryCode)
        .map((code) => ({
          value: code,
          text: intl.formatMessage({
            id: `COUNTRY_${code}`,
            defaultMessage: `COUNTRY_${code}`,
          }),
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    );

    const countryCodeObj = new Array(...Object.values(countryCode))
      .sort((a, b) => a - b)
      .map((countryCode) => ({
        value: countryCode,
        text: `+${countryCode}`,
      }));
    setCountryCodeObj(countryCodeObj);
  }, []);

  return (
    <SmallGrid inner>
      <PagesFormField
        type="label"
        {...commonFields}
        required
        data-testid="CONTACT_FIELD_LABEL"
      />
      <PagesFormField
        type="address"
        {...commonFields}
        required
        data-testid="CONTACT_FIELD_ADDRESS"
      />
      <PagesFormField
        type="addressSuffix"
        {...commonFields}
        data-testid="CONTACT_FIELD_SUFFIX"
      />
      <Styled.FieldGroup>
        <Styled.PagesFormFieldZipcode
          type="postcode"
          applyToValue={(value: string) =>
            removeWhiteSpaces(value).toUpperCase()
          }
          required
          {...commonFields}
          data-testid="CONTACT_FIELD_POSTCODE"
        />
        <Styled.PagesFormFieldCity
          type="city"
          required
          {...commonFields}
          data-testid="CONTACT_FIELD_CITY"
        />
      </Styled.FieldGroup>
      <PagesFormField
        type="country"
        {...commonFields}
        optionList={orderedByLanguageCountryList}
        defaultValue={'DE'}
        data-testid="CONTACT_FIELD_COUNTRY"
      />
      <Styled.FieldGroup>
        <Styled.PagesFormFieldPhonePrefix
          type="phoneNumberCountryCode"
          optionList={countryCodeObj}
          defaultValue={49}
          {...commonFields}
          data-testid="CONTACT_FIELD_PHONECODE"
        />
        <Styled.PagesFormFieldPhone
          type="phoneNumber"
          applyToValue={(value: string) => removeWhiteSpaces(value)}
          applyToValueOnBlur={(value: string) => addParenthisisOnNumbers(value)}
          applyToValueOnFocus={(value: string) =>
            removeParenthisisOnNumbers(value)
          }
          {...commonFields}
          data-testid="CONTACT_FIELD_PHONENUMBER"
        />
        <Styled.PagesFormFieldFaxPrefix
          type="faxNumberCountryCode"
          optionList={countryCodeObj}
          defaultValue={49}
          {...commonFields}
          data-testid="CONTACT_FIELD_FAXCODE"
        />
        <Styled.PagesFormFieldFax
          type="faxNumber"
          applyToValue={(value: string) => removeWhiteSpaces(value)}
          applyToValueOnBlur={(value: string) => addParenthisisOnNumbers(value)}
          applyToValueOnFocus={(value: string) =>
            removeParenthisisOnNumbers(value)
          }
          {...commonFields}
          data-testid="CONTACT_FIELD_FAX"
        />
      </Styled.FieldGroup>
      <PagesFormField
        type="email"
        {...commonFields}
        applyToValue={(value: string) => removeWhiteSpaces(value)}
        data-testid="CONTACT_FIELD_EMAIL"
      />
      <PagesFormField
        type="websiteURL"
        {...commonFields}
        applyToValue={(value: string) => removeWhiteSpaces(value)}
        applyToValueOnBlur={(value: string) => addHttpToWebsiteUrl(value)}
        data-testid="CONTACT_FIELD_WEBSITEURL"
      />
    </SmallGrid>
  );
};
