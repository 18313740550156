import { useEffect } from 'react';

import type {
  EntityPageQuery,
  EntitySubpageQuery,
} from '@xing-com/crate-entity-pages-common';
import { useEditContext } from '@xing-com/crate-entity-pages-common';

import type { EditContextQuery } from '../../graphql/queries/editContext.gql-types';

export const useUpdateEditContext = (
  entityPageEX?:
    | Partial<EntityPageQuery['entityPageEX']>
    | Partial<EditContextQuery['entityPageEX']>
    | Partial<EntitySubpageQuery['entityPageEX']>
): boolean => {
  const { setIsEditor, setIsAdmin } = useEditContext();

  useEffect(() => {
    if (entityPageEX?.__typename !== 'EntityPage') {
      return;
    }

    const userPageContext = entityPageEX.userPageContext;
    const canEdit =
      (userPageContext && userPageContext.permissions?.canEdit) || false;
    const canAdmin =
      (userPageContext && userPageContext.permissions?.canAdmin) || false;

    setIsEditor(canEdit);
    setIsAdmin(canAdmin);
  }, [entityPageEX]);

  return !!entityPageEX;
};
