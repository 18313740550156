/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NewsListItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  until?: Types.InputMaybe<Types.Scalars['String']['input']>;
  since?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PostingPublicationState>;
}>;

export type NewsListItemsQuery = {
  __typename?: 'Query';
  entityPageContentFeeds?: {
    __typename?: 'EntityPageContentFeedCollection';
    metadata?: {
      __typename?: 'EntityPageContentFeedCollectionMetadata';
      draftTotal: number;
      scheduledTotal: number;
      hiddenTotal: number;
    } | null;
    collection?: Array<{
      __typename?: 'EntityPageContentFeedUpdate';
      globalId?: string | null;
      publishedAt?: string | null;
      isPinned: boolean;
      metadata?: {
        __typename?: 'EntityPageContentFeedMetadata';
        likesCount: number;
        commentsCount: number;
        isCommentable: boolean;
        isLikeable: boolean;
        isRecommendable: boolean;
      } | null;
      socialProof?: {
        __typename?: 'XingId';
        id: string;
        displayName: string;
        gender?: Types.Gender | null;
        pageName: string;
        profileImage?: Array<{
          __typename?: 'ProfileImage';
          url: string;
        } | null> | null;
      } | null;
      object?:
        | {
            __typename: 'EntityPageContentFeedArticlePost';
            globalId: string;
            title?: string | null;
            description?: string | null;
            comment?: string | null;
            permalink?: string | null;
            provider?: string | null;
            image?: {
              __typename?: 'EntityPageContentFeedUpdateImage';
              srcWidth347?: string | null;
              srcWidth516?: string | null;
              srcWidth1232?: string | null;
            } | null;
            video?: {
              __typename?: 'EntityPageContentVideo';
              id: string;
              urn: string;
              links?: {
                __typename?: 'EntityPageContentVideoLinks';
                iframeUrl?: string | null;
                thumbnailsUrls?: Array<string> | null;
              } | null;
            } | null;
          }
        | {
            __typename: 'EntityPageContentFeedArticlesArticlePost';
            globalId: string;
            title?: string | null;
            summary?: string | null;
            coverImageUrl?: string | null;
            visitUrl?: string | null;
            images?: Array<{
              __typename?: 'ScaledImage';
              reference?: string | null;
              url?: string | null;
            }> | null;
          }
        | {
            __typename: 'EntityPageContentFeedCompanyArticlePost';
            id: string;
            globalId: string;
            comment?: string | null;
            permalink?: string | null;
            title?: string | null;
            description?: string | null;
            provider?: string | null;
            imageUrl?: string | null;
            images?: Array<{
              __typename?: 'ScaledImage';
              reference?: string | null;
              url?: string | null;
            }> | null;
            video?: {
              __typename?: 'EntityPageContentVideo';
              id: string;
              urn: string;
              links?: {
                __typename?: 'EntityPageContentVideoLinks';
                iframeUrl?: string | null;
                thumbnailsUrls?: Array<string> | null;
              } | null;
            } | null;
          }
        | { __typename: 'EntityPageContentFeedPlainTextPostingPost' }
        | {
            __typename: 'EntityPageContentFeedPostingPost';
            id: string;
            globalId: string;
            comment?: string | null;
            publicationState: Types.PostingPublicationState;
            createdAt: string;
            publishAt?: string | null;
            attachment?:
              | { __typename?: 'PostingsArticleAttachment' }
              | {
                  __typename?: 'PostingsImageAttachment';
                  uuid: string;
                  images?: Array<{
                    __typename?: 'ScaledImage';
                    reference?: string | null;
                    url?: string | null;
                  }> | null;
                }
              | {
                  __typename?: 'PostingsLinkAttachment';
                  globalId: string;
                  link?: {
                    __typename?: 'SocialExternalLinkResult';
                    url: string;
                    title?: string | null;
                    description?: string | null;
                    cachedImageUrl?: string | null;
                    sourceDomain?: string | null;
                  } | null;
                }
              | { __typename?: 'PostingsPollAttachment' }
              | {
                  __typename?: 'PostingsVideoAttachment';
                  id: string;
                  videoV2?: {
                    __typename?: 'VideoV2';
                    ref?: string | null;
                  } | null;
                }
              | null;
          }
        | null;
    }> | null;
  } | null;
};

export const NewsListItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewsListItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'until' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'since' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostingPublicationState' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityPageContentFeeds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'until' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'until' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'since' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'since' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'supportedObjectTypes' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'PLAIN_TEXT_POSTING' },
                    { kind: 'EnumValue', value: 'LINK_SHARE_POSTING' },
                    { kind: 'EnumValue', value: 'IMAGE_POSTING' },
                    { kind: 'EnumValue', value: 'CONTENT_PAGE_ARTICLE' },
                    { kind: 'EnumValue', value: 'COMPANY_PAGE_ARTICLE' },
                    { kind: 'EnumValue', value: 'VIDEO_POSTING' },
                    { kind: 'EnumValue', value: 'ARTICLES_ARTICLE' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'draftTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scheduledTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiddenTotal' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'entityPageFeedCollection',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'entityPageFeedCollection' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityPageContentFeedUpdate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPinned' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentsCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCommentable' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isLikeable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isRecommendable' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialProof' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'size' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'SQUARE_32' }],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'object' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EntityPageContentFeedPostingPost',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicationState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publishAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'PostingsImageAttachment',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uuid' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'images' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'dimensions',
                                        },
                                        value: {
                                          kind: 'ListValue',
                                          values: [
                                            {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'height',
                                                  },
                                                  value: {
                                                    kind: 'IntValue',
                                                    value: '600',
                                                  },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'width',
                                                  },
                                                  value: {
                                                    kind: 'IntValue',
                                                    value: '800',
                                                  },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'reference',
                                                  },
                                                  value: {
                                                    kind: 'StringValue',
                                                    value: 'large',
                                                    block: false,
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'reference',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'PostingsLinkAttachment',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'globalId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cachedImageUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'sourceDomain',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'PostingsVideoAttachment',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videoV2' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'ref' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EntityPageContentFeedCompanyArticlePost',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'permalink' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'dimensions' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '600' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '800' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'reference',
                                      },
                                      value: {
                                        kind: 'StringValue',
                                        value: 'large',
                                        block: false,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reference' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'video' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'links' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'iframeUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'thumbnailsUrls',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EntityPageContentFeedArticlePost',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'permalink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'srcWidth347' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'srcWidth516' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'srcWidth1232' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'video' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'links' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'iframeUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'thumbnailsUrls',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EntityPageContentFeedArticlesArticlePost',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'summary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'dimensions' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '600' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '800' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'reference',
                                      },
                                      value: {
                                        kind: 'StringValue',
                                        value: 'large',
                                        block: false,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reference' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coverImageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'visitUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewsListItemsQuery, NewsListItemsQueryVariables>;
