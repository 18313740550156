import styled from 'styled-components';

import {
  spaceL,
  spaceXS,
  spaceXXL,
  xdlColorBackgroundSecondary,
} from '@xing-com/tokens';

export const Wrapper = styled.div`
  > div {
    margin-right: ${spaceL};
  }
`;

export const Card = styled.div`
  width: 100%;
  padding: ${spaceXS};
`;

export const EmptyState = styled.div`
  background-color: ${xdlColorBackgroundSecondary};
  padding: 56px ${spaceXXL};
  font-weight: bold;
`;
