import * as React from 'react';
import type { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import type { ButtonProps } from '@xing-com/button';
import { IconArrowLeft, IconSearch } from '@xing-com/icons';
import { Link } from '@xing-com/link';
import { LightMode } from '@xing-com/theme-mode-switcher';

import { SearchDropdown } from './old/search-dropdown';
import {
  H2,
  H3,
  Placebo,
  StyledPrimaryActions,
  StyledSecondaryActions,
  SubHeaderButton,
  SubHeaderContainer,
  SubHeaderLink,
  TopBarContainer,
  StyledLogo,
  SignInButton,
  StyledButterfly,
  NavItemContainer,
  SubHeaderContentWrapper,
} from './top-bar-search-result.styles';
import type { TopBarType } from './top-bar-types';
import { getFullLoginLink } from './utils/get-full-login-link';

export type TopBarSearchResultsProps = {
  primaryActions?: React.ReactNode[];
  secondaryActions?: React.ReactNode[];
  skipAdditionalButtons?: boolean;
  skipBackButton?: boolean;
  showOnLargerScreens?: boolean;
  titleSectionOnClick?: (e: React.MouseEvent) => void;
  titleSectionUrl?: string;
  backButtonOnClick?: (e: React.MouseEvent) => void;
  backButtonUrl?: string;
  /** section name of custom link (e.g.: you) */
  backButtonUrlAriaLabelSection?: string;
  subTitle?: string;
  title?: React.ReactNode;
  type?: TopBarType;
  subHeaderContent?: React.ReactNode;
  isSticky?: boolean;
  isLoggedOut?: boolean;
  loginLink?: string;
  signupLink?: string;
};

type BackButtonType = Pick<
  ButtonProps,
  'aria-label' | 'to' | 'onClick' | 'size' | 'variant' | 'icon'
> & {
  size: 'medium';
  icon: typeof IconArrowLeft;
};

export const TopBarSearchResults: React.FC<TopBarSearchResultsProps> = ({
  primaryActions = [],
  secondaryActions = [],
  skipAdditionalButtons,
  skipBackButton,
  showOnLargerScreens = false,
  titleSectionOnClick,
  backButtonOnClick,
  titleSectionUrl,
  backButtonUrl,
  backButtonUrlAriaLabelSection,
  subTitle,
  title,
  type,
  subHeaderContent,
  isSticky = true,
  isLoggedOut,
  loginLink = getFullLoginLink(),
  signupLink = '/start/signup',
  ...props
}) => {
  const { formatMessage } = useIntl();

  // Those 2 Copy variables needed to be created to prevent re-rendering of elements in props on every scroll. Checkout UseEffect.
  const primaryActionsCopy = [...primaryActions];
  const secondaryActionsCopy = [...secondaryActions];

  const [loginButtonTo, setLoginButtonTo] = React.useState<string | undefined>(
    undefined
  );
  const [signupButtonTo, setSignupButtonTo] = React.useState<
    string | undefined
  >(undefined);

  React.useEffect(() => {
    setSignupButtonTo(signupLink);
    setLoginButtonTo(loginLink);
  }, [loginLink, signupLink]);

  // Search Button Section
  const Search = (
    <SearchDropdown
      showLabel
      key="top-bar-search"
      icon={IconSearch}
      size="medium"
      type={type}
    />
  );

  const SecondaryActions: React.FC<PropsWithChildren<object>> = ({
    children,
  }) => {
    return (
      <StyledSecondaryActions $type={type}>
        <NavItemContainer>{children}</NavItemContainer>
      </StyledSecondaryActions>
    );
  };

  const PrimaryActions: React.FC<PropsWithChildren<object>> = ({
    children,
  }) => {
    return isLoggedOut && !skipAdditionalButtons ? (
      <>
        <Button
          data-testid="login-button-top-bar"
          to={loginButtonTo}
          size="medium"
          href={loginLink}
        >
          {formatMessage({
            id: 'X_UI_NAVIGATION_LOGIN_FRAME_FALLBACK',
          })}
        </Button>
        <SignInButton
          data-testid="signin-button-top-bar"
          size="medium"
          variant="primary"
          to={signupButtonTo}
        >
          {formatMessage({ id: 'X_UI_HEADER_REGISTER' })}
        </SignInButton>
      </>
    ) : (
      <StyledPrimaryActions data-testid="top-bar-primary-actions" $type={type}>
        {children}
      </StyledPrimaryActions>
    );
  };

  if (!skipAdditionalButtons) {
    primaryActionsCopy.push([Search]);
  }

  // Title Section
  let titleSection: React.ReactNode;
  const _getDefaultTitleSection = (): React.ReactElement => {
    const subHeaderContent = (
      <React.Fragment>
        <H2 size="large">{title}</H2>
        {subTitle && <H3 size="small">{subTitle}</H3>}
      </React.Fragment>
    );
    if (titleSectionUrl) {
      return (
        <SubHeaderLink to={titleSectionUrl}>{subHeaderContent}</SubHeaderLink>
      );
    } else if (titleSectionOnClick) {
      return (
        <SubHeaderButton onClick={titleSectionOnClick}>
          {subHeaderContent}
        </SubHeaderButton>
      );
    }
    return <SubHeaderContainer>{subHeaderContent}</SubHeaderContainer>;
  };

  // Back Button Section
  let BackButton: React.ReactNode;

  if (!skipBackButton) {
    const backButtonProps: BackButtonType = {
      'aria-label': formatMessage({
        id: 'TOP_BAR_BACK_BUTTON_ARIA_LABEL',
      }),
      icon: IconArrowLeft,
      size: 'medium',
      onClick: backButtonOnClick,
    };

    if (backButtonUrl) {
      if (backButtonUrlAriaLabelSection) {
        backButtonProps['aria-label'] = formatMessage(
          {
            id: 'TOP_BAR_BACK_BUTTON_ARIA_LABEL_CUSTOM',
          },
          { section: backButtonUrlAriaLabelSection }
        );
      }
      backButtonProps.to = backButtonUrl;
    } else {
      backButtonProps.onClick = (e) => {
        backButtonOnClick && backButtonOnClick(e);
        if (global?.window?.history?.length > 1) {
          global.window.history.back();
          e.preventDefault();
        }
      };
      backButtonProps.to = '/';
    }

    BackButton = (
      <Button {...backButtonProps} key="top-bar-back" data-qa="top-bar-back" />
    );
    secondaryActionsCopy.push(BackButton);
    titleSection = _getDefaultTitleSection();
  } else {
    secondaryActionsCopy.push([
      <Link key="xing-butterfly-link" to="/">
        <StyledButterfly key="xing-butterfly" variant="mono" />
      </Link>,
      <Link key="xing-logo-link" to="/">
        <StyledLogo key="xing-logo" variant="mono" />
      </Link>,
    ]);
  }
  titleSection = _getDefaultTitleSection();

  return (
    <React.Fragment>
      <LightMode>
        <TopBarContainer
          data-qa="top-bar-container"
          $showOnLargerScreens={showOnLargerScreens}
          $isScrolling
          $isSticky={isSticky}
          {...props}
        >
          <SecondaryActions>
            {secondaryActionsCopy.map((action, index) => {
              return <NavItemContainer key={index}>{action}</NavItemContainer>;
            })}
          </SecondaryActions>
          {subHeaderContent ? (
            <SubHeaderContentWrapper>
              {subHeaderContent}
            </SubHeaderContentWrapper>
          ) : (
            titleSection
          )}

          <PrimaryActions>
            {primaryActionsCopy.map((action, index) => {
              return <NavItemContainer key={index}>{action}</NavItemContainer>;
            })}
          </PrimaryActions>
        </TopBarContainer>
      </LightMode>

      <Placebo $showOnLargerScreens={showOnLargerScreens} />
    </React.Fragment>
  );
};
