import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaSmallOnly } from '@xing-com/layout-tokens';
import { FormField as InternalFormField } from '@xing-com/text-field';
import { spaceS, spaceM, spaceL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const EditWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const EditText = styled(BodyCopy)`
  margin-left: ${spaceS};
`;

export const InputGuidance = styled.div`
  margin-bottom: ${spaceM};
`;

export const FormAndActionsWrapper = styled.div`
  display: flex;

  @media ${mediaSmallOnly} {
    flex-direction: column;
  }
`;

export const FormField = styled(InternalFormField)`
  width: 100%;
`;

export const EditActionsWrapper = styled.div`
  margin-left: ${spaceL};
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-top: ${spaceS};

  @media ${mediaSmallOnly} {
    justify-content: flex-start;
    margin-left: 0;
  }
`;

export const DefaultItemActionButton = styled(Button)`
  margin-right: ${spaceL};

  &:last-child {
    margin-right: 0;
  }

  @media ${mediaSmallOnly} {
    margin-right: ${spaceM};

    &:last-child {
      margin-right: 0;
    }
  }
`;
