import { useMutation, useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import { Transforms } from 'slate';

import {
  articleSerializer,
  articleHeaderSerializer,
} from '@xing-com/content-editor-serializer';
import {
  useDialogContext,
  usePageContext,
  useEditContext,
  EditHeader,
  useErrorContext,
  WarningMessageBox as WarningBox,
} from '@xing-com/crate-entity-pages-common';
import { trackEditSaving } from '@xing-com/crate-entity-pages-common/src/tracking';

import { DEFAULT_MEDIA_AMOUNT_FOR_ABOUTUS } from '../../../config';
import { UpdateAboutUsDescriptionDetailedDocument } from '../../../graphql/mutation/update-aboutus-description-detailed.gql-types';
import { UpdateAboutusDescriptionIntroDocument } from '../../../graphql/mutation/update-aboutus-description-intro.gql-types';
import { AboutUsDocument } from '../../../graphql/queries/about-us-query.gql-types';
import { AboutUsDescriptionDocument } from '../../../graphql/queries/aboutus-description.gql-types';
import { useEditor } from '../article-editor';
import { MAX_INTRO_DESCRIPTION_CHARS, MAX_INTRO_TITLE_CHARS } from './config';
import DescriptionEdit from './description-edit';
import { detailedInitialValue } from './detailed-initial-value';
import {
  getEditorTitle,
  getEditorBody,
  isEditorBodyEmpty,
} from './editor-helpers';
import { EditDescriptionSkeleton } from './skeleton/skeleton';
import type { FormDataType } from './types';

type AboutUsEditDescriptionProps = {
  pageSlug: string;
};

type UpdateCompleteStatusProps = {
  intro?: boolean;
  detailed?: boolean;
};

export const AboutUsEditDescription: FC<AboutUsEditDescriptionProps> = ({
  pageSlug,
}) => {
  const type = new URLSearchParams(useLocation().search).get('type');
  const [activeIndex, setActiveIndex] = useState(type === 'detailed' ? 1 : 0);
  const updateCompleteStatus = useRef<UpdateCompleteStatusProps>({});
  const [isSaving, setIsSaving] = useState(false);
  const { dataChanged, setDataChanged } = useDialogContext();
  const { pageContext } = usePageContext() ?? {};
  const { showError } = useErrorContext();
  const { trackingData } = useEditContext();

  const companyId = pageContext?.companyId;
  const [formData, setFormData] = useState<FormDataType>({
    detailedValue: { value: detailedInitialValue },
    defaultState: true,
  });
  const goBackUrl = pageContext?.goBackUrl;
  const editor = useEditor();

  const { data, loading, error, refetch } = useQuery(
    AboutUsDescriptionDocument,
    {
      variables: { id: pageSlug },
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    data?.pagesAboutUs?.__typename === 'AboutEntity' &&
      setFormData({
        title: { value: data?.pagesAboutUs?.headline || '' },
        description: { value: data?.pagesAboutUs?.summary || '' },
        detailedValue: {
          value: data?.pagesAboutUs?.aboutArticle?.header?.title && [
            articleHeaderSerializer.deserialize([
              data?.pagesAboutUs?.aboutArticle?.header?.title,
            ]).title,
            ...articleSerializer.deserialize(
              data?.pagesAboutUs?.aboutArticle?.blocks
            ),
          ],
        },
        defaultState: false,
      });
  }, [data]);

  const handleUpdateComplete = () => {
    if (
      updateCompleteStatus?.current?.intro &&
      updateCompleteStatus?.current?.detailed
    ) {
      setDataChanged(false);

      goBackUrl && goBackUrl();
      setIsSaving(false);
    }
  };

  const prepareDataToSave = () => {
    /* if short description is empty, copy from detailed */
    const copyFromDetailed = !formData?.description?.value;

    return {
      introTitle:
        copyFromDetailed && !formData?.title?.value
          ? getEditorTitle(formData?.detailedValue?.value)
              .join('')
              .substring(0, MAX_INTRO_TITLE_CHARS)
          : formData?.title?.value.replace(/^\s+/g, ''),
      introDescription: copyFromDetailed
        ? getEditorBody(formData?.detailedValue?.value)
            .join(' ')
            .substring(0, MAX_INTRO_DESCRIPTION_CHARS)
        : formData?.description?.value &&
          formData?.description?.value
            .replace(/[\r\n]\s*[\r\n]/g, '\n\n')
            .replace(/^\s+/g, ''),
      header: articleHeaderSerializer.serialize(formData?.detailedValue?.value),
      blocks: articleSerializer.serialize(formData?.detailedValue?.value),
    };
  };

  const [updateIntro] = useMutation(UpdateAboutusDescriptionIntroDocument, {
    onCompleted: (data) => {
      if (data?.entityPageUpdateAboutContent?.error) {
        showError({
          message: 'EP_GENERIC_FEEDBACK_ERROR',
          error: data.entityPageUpdateAboutContent.error,
        });
        setIsSaving(false);
      } else {
        updateCompleteStatus.current.intro = true;
        handleUpdateComplete();
      }
    },
    onError: (error) => {
      setIsSaving(false);
      showError({
        message: 'EP_GENERIC_FEEDBACK_ERROR',
        error,
      });
    },
    update: (cache) => {
      const data: any = cache.readQuery({
        query: AboutUsDocument,
        variables: {
          id: pageSlug,
          companyId,
          first: DEFAULT_MEDIA_AMOUNT_FOR_ABOUTUS,
        },
      });

      if (!data) {
        return;
      }

      const processedData = prepareDataToSave();

      cache.writeQuery({
        query: AboutUsDocument,
        variables: {
          id: pageSlug,
          companyId,
          first: DEFAULT_MEDIA_AMOUNT_FOR_ABOUTUS,
        },
        data: {
          ...data,
          pagesAboutUs: {
            ...data?.pagesAboutUs,
            headline: processedData.introTitle,
            summary: processedData.introDescription,
          },
        },
      });
    },
  });

  const [updateDetailed] = useMutation(
    UpdateAboutUsDescriptionDetailedDocument,
    {
      onCompleted: (data) => {
        if (data?.articlesUpdateArticle?.error) {
          showError({
            message: 'EP_GENERIC_FEEDBACK_ERROR',
            error: data.articlesUpdateArticle.error,
          });
          setIsSaving(false);
        } else {
          updateCompleteStatus.current.detailed = true;
          handleUpdateComplete();
        }
      },
      onError: (error) => {
        setIsSaving(false);
        showError({
          message: 'EP_GENERIC_FEEDBACK_ERROR',
          error,
        });
      },
    }
  );

  const handlePublish = () => {
    if (!dataChanged) {
      goBackUrl && goBackUrl();
      return;
    }

    if (
      formData.title?.error ||
      formData.description?.error ||
      data?.pagesAboutUs?.__typename !== 'AboutEntity'
    ) {
      setActiveIndex(0);
      return;
    }

    if (
      pageContext?.focusType &&
      pageContext?.pageId &&
      trackingData?.module &&
      trackingData?.part
    ) {
      trackEditSaving({
        focusType: pageContext?.focusType,
        itemId: pageContext?.pageId,
        isCreate: true,
        module: trackingData.module,
        part: trackingData.part,
      });
    }

    if (
      getEditorTitle(formData?.detailedValue?.value)[0]?.trim().length < 4 ||
      isEditorBodyEmpty(formData?.detailedValue?.value)
    ) {
      showError({
        message: 'EP_EDIT_ABOUTUS_DETAILED_REQUIRED',
      });
      setFormData({
        ...formData,
        detailedValue: { ...formData.detailedValue, error: true },
      });
      setActiveIndex(1);
      return;
    }

    const processedData = prepareDataToSave();
    setIsSaving(true);
    Transforms.deselect(editor);
    updateIntro({
      variables: {
        pageId: pageSlug,
        aboutHeadline: processedData.introTitle,
        aboutSummary: processedData.introDescription,
      },
    });
    updateDetailed({
      variables: {
        articleGlobalId: data?.pagesAboutUs?.aboutArticle?.globalId ?? '',
        header: processedData.header,
        blocks: processedData.blocks,
      },
    });
    return;
  };

  if (loading) {
    return (
      <>
        <EditHeader
          titleKey="EP_EDIT_ABOUTUS_DESCRIPTION"
          contentSwitcher={{
            activeIndex,
            setActiveIndex,
            contentKeyList: [
              'EP_EDIT_ABOUTUS_NAV_INTRO',
              'EP_EDIT_ABOUTUS_NAV_DESCRIPTION',
            ],
          }}
        />
        <EditDescriptionSkeleton />
      </>
    );
  }

  if (error) {
    return (
      <>
        <EditHeader
          titleKey="EP_EDIT_ABOUTUS_DESCRIPTION"
          contentSwitcher={{
            activeIndex,
            setActiveIndex,
            contentKeyList: [
              'EP_EDIT_ABOUTUS_NAV_INTRO',
              'EP_EDIT_ABOUTUS_NAV_DESCRIPTION',
            ],
          }}
        />
        <WarningBox
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetch()}
        />
      </>
    );
  }

  return (
    <>
      <EditHeader
        titleKey="EP_EDIT_ABOUTUS_DESCRIPTION"
        infoMessage={{
          key: 'EP_EDIT_ABOUTUS_HINT',
          values: {
            a: (b: any) => (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={'https://faq.xing.com/de/node/68251'} target="_blank">
                {b}
              </a>
            ),
            b: (t: any) => <b>{t}</b>,
          },
        }}
        noMarginBottom={true}
        contentSwitcher={{
          activeIndex,
          setActiveIndex,
          contentKeyList: [
            'EP_EDIT_ABOUTUS_NAV_INTRO',
            'EP_EDIT_ABOUTUS_NAV_DESCRIPTION',
          ],
        }}
      />
      {!formData.defaultState && (
        <DescriptionEdit
          activeIndex={activeIndex}
          handlePublish={handlePublish}
          isSaving={isSaving}
          formData={formData}
          setFormData={setFormData}
          setDetailedValue={(newValue) => {
            setDataChanged(true);
            setFormData({
              ...formData,
              detailedValue: { value: newValue, error: null },
            });
          }}
          goBackUrl={goBackUrl}
        />
      )}
    </>
  );
};
