import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import {
  scale030,
  scale080,
  scale090,
  scale130,
  scale160,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXS,
} from '@xing-com/tokens';

import type { FacepileSize, FacepileVariant } from './';

type FacepileTransientProps = {
  $size: FacepileSize;
  $variant: FacepileVariant;
};

type CounterButtonTransientProps = {
  $size: FacepileSize;
};

// *** Sizes ***
export const sizes = {
  small: {
    default: css`
      margin-right: ${spaceXS};
    `,
    condensed: css`
      &:nth-child(n + 2) {
        margin-left: calc(calc(${spaceM} * -1) + calc(${spaceXS} * -1));
      }
    `,
    counterButton: css`
      && {
        height: ${scale130};
        margin-left: ${spaceM};
      }
    `,
  },
  medium: {
    default: css`
      margin-right: ${scale030};
    `,
    condensed: css`
      &:nth-child(n + 2) {
        margin-left: calc(calc(${spaceL} * -1) + calc(${scale030}) * -1);
      }
    `,
    counterButton: css`
      && {
        font-size: ${scale080};
        height: ${scale160};
        margin-left: ${spaceL};
      }
    `,
  },
  large: {
    default: css`
      margin-right: ${scale030};
    `,
    condensed: css`
      &:nth-child(n + 2) {
        margin-left: calc(calc(${spaceXL} * -1) + calc(${spaceS}) * -1);
      }
    `,
    counterButton: css`
      && {
        font-size: ${scale090};
        height: ${scale160};
        margin-left: ${spaceXL};
      }
    `,
  },
};

// *** Components ***
export const FacepileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const Facepile = styled.div<FacepileTransientProps>`
  display: flex;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }

  ${(props) => css`
    ${props.$size && sizes[props.$size].default};
    ${props.$variant === 'condensed' &&
    css`
      ${sizes[props.$size].condensed};
    `};
  `};
`;

export const CounterButton = styled(Button)<CounterButtonTransientProps>`
  min-width: 0;
  padding: 0;

  ${(props) => css`
    ${props.$size && sizes[props.$size].counterButton};
  `};
`;

export const FacepileButton = styled(Button)`
  min-width: auto;
  overflow: visible;
  padding: 0;
`;
