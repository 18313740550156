import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaSmallOnly } from '@xing-com/layout-tokens';
import {
  spaceM,
  spaceL,
  xdlColorTextInvert,
  xdlColorSelected,
} from '@xing-com/tokens';

export const DefaultItemActionButton = styled(Button)`
  margin-right: ${spaceL};

  &:last-child {
    margin-right: 0;
  }

  @media ${mediaSmallOnly} {
    margin-right: ${spaceM};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DefaultUserButton = styled(DefaultItemActionButton)<{
  $activeEnableButton?: boolean;
}>`
  ${({ $activeEnableButton }) =>
    !$activeEnableButton &&
    css`
      background-color: ${xdlColorSelected};
      border: none;
      color: ${xdlColorTextInvert};

      &:hover {
        border: none;
      }

      &:disabled4 {
        background-color: ${xdlColorSelected};
        border: none;
        color: ${xdlColorTextInvert};
      }
    `}
`;

export const DeletedButton = styled(DefaultItemActionButton)`
  margin-right: 0;
  /* alignment with the other item buttons */
  padding-right: 0;
`;

export const DragButton = styled(DefaultItemActionButton)`
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  user-select: none;

  &:hover {
    background-color: inherit;
  }

  &:active {
    cursor: grabbing;
    background-color: inherit;
    outline: none;
    color: inherit;
  }
`;
