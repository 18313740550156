import { getImageGender } from '@xing-com/crate-entity-pages-common';

import type { EntityPageEmployeeCardFragment } from '../graphql/fragments/employee-card.gql-types';
import type { EmployeeCard } from '../types/employee-card';

export const createEmployeeCard = (
  employee: EntityPageEmployeeCardFragment
): EmployeeCard | null => {
  const profileDetails = employee?.profileDetails;
  const networkRelationship = employee?.networkRelationship?.relationship;
  const contactDistance = employee?.contactDistance;
  const sharedContacts = employee?.sharedContacts;

  if (!profileDetails) {
    return null;
  }

  return {
    id: profileDetails.id,
    displayName: profileDetails.displayName,
    profileUrl:
      profileDetails?.clickReasonProfileUrl?.profileUrl ??
      `/profile/${profileDetails.pageName}`,
    pageName: profileDetails.pageName,
    occupationTitle: profileDetails?.occupations?.[0]?.subline ?? '',
    sharedContacts: sharedContacts?.total ?? 0,
    relationship: networkRelationship ?? undefined,
    profileImage: {
      src: profileDetails.profileImage?.[0]?.url ?? '',
      type: getImageGender(profileDetails.gender),
      profileName: profileDetails.displayName,
      alt: `${profileDetails.displayName}'s profile picture`,
      connectionDegree: contactDistance?.distance,
    },
    position: profileDetails.occupations?.[0]?.subline,
    flag: profileDetails?.userFlags?.displayFlag ?? undefined,
  };
};

export const createEmployeeCards = (
  employees: EntityPageEmployeeCardFragment[]
) =>
  employees
    .map((employee) => createEmployeeCard(employee))
    .filter((card) => !!card);
