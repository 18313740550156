import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import { WarningMessageBox as Error } from '@xing-com/crate-entity-pages-common';

import { FactsQueryDocument } from '../../../graphql/queries/facts-query.gql-types';
import FactsEditCompany from './facts-edit-company';
import FactsEditPublisher from './facts-edit-publisher';

type FactsEditContainerProps = {
  pageSlug: string;
};
export const FactsEditContainer: FC<FactsEditContainerProps> = ({
  pageSlug,
}) => {
  const { data, error, refetch } = useQuery(FactsQueryDocument, {
    variables: {
      id: pageSlug,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const facts = data?.pagesAboutUsFacts;

  if (error) {
    return (
      <Error
        headerText="EP_ERROR_HEADER"
        bodyText="EP_ERROR_BODY"
        buttonText="EP_ERROR_RELOAD_CTA"
        onClick={() => refetch()}
      />
    );
  }

  if (!facts) {
    return null;
  }

  if (facts.__typename === 'CompanyAboutUsFacts') {
    return (
      <FactsEditCompany
        // @ts-expect-error TODO: fix this type
        facts={facts}
      />
    );
  }
  if (facts.__typename === 'PublisherAboutUsFacts') {
    return <FactsEditPublisher />;
  }

  return null;
};
