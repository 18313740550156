import styled from 'styled-components';

import { Button as InternalButton } from '@xing-com/button';
import { mediaSmallOnly } from '@xing-com/layout-tokens';
import { PopOver as InternalPopOver } from '@xing-com/pop-over';
import { InputBar as InternalInputBar } from '@xing-com/text-field';
import { spaceM, spaceL, spaceXL } from '@xing-com/tokens';

export const InputSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  margin: ${spaceXL} 0;

  @media ${mediaSmallOnly} {
    flex-direction: column;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: ${spaceL};
  align-items: center;

  @media ${mediaSmallOnly} {
    margin-bottom: ${spaceM};
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const InputBar = styled(InternalInputBar)`
  & input {
    &:focus {
      outline: 0;
    }
  }
`;

export const NoticeWrapper = styled.div`
  width: 100%;
`;

export const PopOver = styled(InternalPopOver)`
  padding: 0;
`;

export const Button = styled(InternalButton)`
  margin: ${spaceM};
`;
