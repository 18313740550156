import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  usePageContext,
  ReportButton,
  useEditContext,
  useErrorContext,
} from '@xing-com/crate-entity-pages-common';

import * as Styled from './list.styles';

type RenderEditorOptionsProps = {
  globalId?: string;
  ctaType?: string;
};
const RenderEditorOptions: FC<RenderEditorOptionsProps> = ({
  globalId,
  ctaType,
}) => {
  return (
    <>
      <Styled.ListItem
        onClick={() =>
          window.open(
            `/xam/aac/?entity_urn=${globalId}&asset_urn=${globalId}`,
            '_blank'
          )
        }
      >
        <span data-testid="EP_PROMOTE_PAGE">
          <Styled.Link noMargin fontWeight="bold" size="small">
            <FormattedMessage
              id="EP_PROMOTE_PAGE"
              defaultMessage="EP_PROMOTE_PAGE"
            />
          </Styled.Link>
        </span>
      </Styled.ListItem>
      {ctaType === 'follow' && (
        <Styled.ListItem
          onClick={() => window.open(`/brandmanager/`, '_blank')}
        >
          <span data-testid="ADMINISTRATE_PAGE">
            <Styled.Link noMargin fontWeight="bold" size="small">
              <FormattedMessage
                id="EP_ADMINISTRATE_PAGE"
                defaultMessage="EP_ADMINISTRATE_PAGE"
              />
            </Styled.Link>
          </span>
        </Styled.ListItem>
      )}
      {ctaType === 'follow' && (
        <Styled.ListItem
          onClick={() =>
            window.open('https://community.xing.com/de/s/', '_blank')
          }
        >
          <span data-testid="HELP_CENTER">
            <Styled.Link noMargin fontWeight="bold" size="small">
              <FormattedMessage
                id="EP_HELP_CENTER"
                defaultMessage="EP_HELP_CENTER"
              />
            </Styled.Link>
          </span>
        </Styled.ListItem>
      )}
    </>
  );
};

type ListProps = {
  closePopUp: () => void;
  sharePage: () => void;
  ctaType: string;
};
const List: FC<ListProps> = ({ sharePage, closePopUp, ctaType }) => {
  const { isEditor, isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const { showError } = useErrorContext();

  return (
    <>
      <Styled.MoreMenuTitle noMargin size="small">
        <FormattedMessage
          id="EP_CHOOSE_ACTION"
          defaultMessage="EP_CHOOSE_ACTION"
        />
      </Styled.MoreMenuTitle>
      <span data-testid="HEADER_MORE_ACTIONS_LIST">
        <Styled.ListWrapper>
          <>
            <Styled.ListItem onClick={() => sharePage()}>
              <span data-testid="SHARE_PAGE">
                <Styled.Link noMargin fontWeight="bold" size="small">
                  <FormattedMessage
                    id="EP_SHARE_PAGE"
                    defaultMessage="EP_SHARE_PAGE"
                  />
                </Styled.Link>
              </span>
            </Styled.ListItem>
            {!isEditor && (
              <Styled.ListItem>
                <span data-testid="REPORT_PAGE">
                  <ReportButton
                    authorUrn={pageContext?.globalId}
                    targetUrn={pageContext?.globalId}
                    onSuccess={closePopUp}
                    onError={() =>
                      showError({
                        message: 'EP_GENERIC_FEEDBACK_ERROR',
                      })
                    }
                  >
                    <Styled.Link noMargin size="small">
                      <FormattedMessage
                        id="EP_REPORT_PAGE"
                        defaultMessage="EP_REPORT_PAGE"
                      />
                    </Styled.Link>
                  </ReportButton>
                </span>
              </Styled.ListItem>
            )}
            {isEditor && isEditing && (
              <RenderEditorOptions
                globalId={pageContext?.globalId}
                ctaType={ctaType}
              />
            )}
          </>
        </Styled.ListWrapper>
      </span>
    </>
  );
};

export default List;
