import type { FC } from 'react';

import type { EmployeeCard } from '../../types/employee-card';
import { EmployeesCardHorizontal } from '../employees-card-horizontal/employees-card-horizontal';
import { EmployeesGridSkeleton } from './employees-grid.skeleton';
import * as Styled from './employees-grid.styles';

type EmployeesGridProps = {
  employeesCards: EmployeeCard[];
  trackingModuleType?: string;
  onAddContact?: () => void;
};
export const EmployeesGrid: FC<EmployeesGridProps> & {
  Skeleton: typeof EmployeesGridSkeleton;
} = ({
  employeesCards,
  trackingModuleType,
  onAddContact = () => undefined,
}) => {
  return (
    <Styled.EmployeesGridContainer>
      {employeesCards.map((employeeCard) => {
        return (
          <EmployeesCardHorizontal
            key={employeeCard.id}
            card={employeeCard}
            trackingModuleType={trackingModuleType}
            onAddContact={onAddContact}
          />
        );
      })}
    </Styled.EmployeesGridContainer>
  );
};

EmployeesGrid.Skeleton = EmployeesGridSkeleton;
