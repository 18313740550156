import styled from 'styled-components';

export const ListContainer = styled.div`
  width: 100%;
`;

export const LoadableSVGs = styled.div`
  position: absolute;
  z-index: -100;
`;

export const SortableWrapper = styled.div`
  .ghost {
    visibility: hidden;
  }

  .sortableFallback {
    background-color: white;
    box-shadow: 0 4px 16px 3px rgba(33, 42, 54, 0.3);
  }
`;
