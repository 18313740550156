import { getImageGender } from '@xing-com/crate-entity-pages-common';

import type { EntityPagesContactCardFragment } from '../../graphql/fragments/contact-card-fragment.gql-types';
import type { ContactCard } from '../../types/contact-card';

export const createContactsCard = (
  contact: EntityPagesContactCardFragment
): ContactCard => {
  const flag = contact.xingId?.userFlags?.displayFlag ?? undefined;

  const hasFlag = Boolean(flag && flag !== 'BASIC' && flag !== 'EXECUTIVE');

  return {
    id: contact.id,
    profileImage: {
      src: contact.xingId?.profileImage?.[0]?.url ?? undefined,
      type: getImageGender(contact.xingId?.gender),
    },
    pageName: contact.xingId?.pageName ?? '',
    occupation: contact.xingId?.occupations?.[0]?.subline,
    displayName: contact.xingId?.displayName,
    label: contact.label ?? undefined,
    profileUrl:
      contact.xingId?.clickReasonProfileUrl?.profileUrl ??
      `/profile/${contact.xingId?.pageName}`,
    hasFlag: hasFlag,
    flag: flag,
  };
};

export const createContactsCards = (
  contacts: EntityPagesContactCardFragment[]
) =>
  contacts
    .map((contact) => createContactsCard(contact))
    .filter((card) => !!card);
