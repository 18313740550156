import type { FC } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';

import { useHeaderContext } from '../../hooks/use-header-context/use-header-context';
import HeaderActions from '../header-actions';
import { SocialProof } from '../social-proof/social-proof';
import * as Styled from './tertiary-area.styles';

export const TertiaryArea: FC = () => {
  const { ctaType } = useHeaderContext();
  const { pageContext } = usePageContext() ?? {};

  const socialProof = pageContext?.socialProof;

  return (
    <Styled.TertiaryArea className="tertiaryArea">
      {socialProof && socialProof.total > 0 && (
        <Styled.TertiaryMainArea>
          <SocialProof socialProof={socialProof} />
        </Styled.TertiaryMainArea>
      )}
      <Styled.HeaderActionsArea>
        <HeaderActions ctaType={ctaType} />
      </Styled.HeaderActionsArea>
    </Styled.TertiaryArea>
  );
};
