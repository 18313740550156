import * as React from 'react';

import { ProfileImage } from '@xing-com/profile-image';
import type { ProfileImageSize } from '@xing-com/profile-image';

import * as Styled from './facepile.styles';
import type { FacepileProps, FacepileSize } from './facepile.types';

export const Facepile = React.forwardRef<HTMLDivElement, FacepileProps>(
  (
    {
      actors = [],
      className,
      counter,
      fenced = false,
      maxActors = 10,
      moreHref,
      moreTo,
      size,
      sizeConfined,
      sizeWide,
      upsellHref,
      upsellTo,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const threshold = fenced ? 5 : maxActors;

    const getProfileImageSize = (
      value?: FacepileSize
    ): ProfileImageSize | undefined => {
      if (value === 'small') return 'xsmall';
      if (value === 'medium') return 'small';
      if (value === 'large') return 'medium';

      return undefined;
    };

    return (
      <Styled.FacepileContainer className={className} {...props}>
        {actors.slice(0, threshold).map((actor) => (
          <Styled.Facepile
            $size={size}
            $variant={variant}
            data-xds="Facepile"
            key={actor.id}
            ref={forwardedRef}
          >
            <Styled.FacepileButton
              href={fenced ? upsellHref : actor.href}
              to={fenced ? upsellTo : actor.to}
            >
              <ProfileImage
                blur={!!actor.blur}
                connectionDegree={
                  variant === 'condensed' ? undefined : actor.connectionDegree
                }
                profileName={actor.profileName}
                size={getProfileImageSize(size)}
                sizeConfined={getProfileImageSize(sizeConfined)}
                sizeWide={getProfileImageSize(sizeWide)}
                src={actor.src}
                type={actor.type}
              />
            </Styled.FacepileButton>
          </Styled.Facepile>
        ))}
        {counter && (
          <span className="facepile-button">
            <Styled.CounterButton
              $size={size}
              href={moreHref}
              to={moreTo}
            >{`+${counter}`}</Styled.CounterButton>
          </span>
        )}
      </Styled.FacepileContainer>
    );
  }
);

Facepile.displayName = 'Facepile';
