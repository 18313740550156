/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MembersSearchQueryVariables = Types.Exact<{
  query: Types.MembersSearchQueryInput;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type MembersSearchQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    features?: { __typename?: 'Feature'; isPremium?: boolean | null } | null;
    membersSearch?: {
      __typename?: 'MembersSearchConnection';
      edges: Array<{
        __typename?: 'MembersSearchEdge';
        cursor: string;
        node?:
          | { __typename?: 'MembersSearchFencedItem' }
          | {
              __typename?: 'MembersSearchUnfencedItem';
              xingId?: {
                __typename?: 'XingId';
                globalId: string;
                id: string;
                displayName: string;
                gender?: Types.Gender | null;
                pageName: string;
                userFlags?: {
                  __typename?: 'UserFlags';
                  displayFlag?: Types.UserFlagsDisplayFlag | null;
                } | null;
                profileImage?: Array<{
                  __typename?: 'ProfileImage';
                  url: string;
                } | null> | null;
                clickReasonProfileUrl?: {
                  __typename?: 'ClickReason';
                  profileUrl: string;
                } | null;
                occupations?: Array<{
                  __typename?: 'XingIdOccupation';
                  headline: string;
                  subline: string;
                } | null> | null;
              } | null;
            }
          | null;
      }>;
    } | null;
  } | null;
};

export const MembersSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MembersSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MembersSearchQueryInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPremium' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membersSearch' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'query' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'query' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'after' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'EntityPageMemberSearchUnfenced',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityPagesContactsXingId' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'XingId' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayFlag' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'EnumValue', value: 'SQUARE_64' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickReasonProfileUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clickReasonId' },
                value: {
                  kind: 'EnumValue',
                  value: 'CR_WEB_PUBLISHER_CONTACTS_MODULE',
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'occupations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subline' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityPageMemberSearchUnfenced' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MembersSearchResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MembersSearchUnfencedItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'xingId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'EntityPagesContactsXingId',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MembersSearchQuery, MembersSearchQueryVariables>;
