import type { FC } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';

import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';
import { IconPlus, IconMinus } from '@xing-com/icons';

import * as Styled from './affiliates.styles';

type AffiliatesItemProps = {
  displayName: string;
  category: string;
  followers: number;
  url: string;
  logo: string;
  isFollowing?: boolean;
  toggleFollowCompany: () => void;
  authenticated?: boolean;
};
const AffiliatesItem: FC<AffiliatesItemProps> = ({
  displayName,
  category,
  followers,
  url,
  logo,
  isFollowing = false,
  toggleFollowCompany,
  authenticated,
}) => {
  return (
    <Styled.Card data-testid="affiliates-item">
      <Styled.AffiliatesProfileInfo
        profileUrl={
          url &&
          getTrackingUrl({
            to: url,
            trackingKey: 'visitAffiliate',
          })
        }
        profileImage={{
          size: 'small',
          sizeWide: 'medium',
          profileName: displayName,
          src: logo,
        }}
        headline={{
          size: 'medium',
          children: (
            <Styled.HeadlineWrapper>
              <Styled.HeadlineClamp clamp={2}>
                {displayName}
              </Styled.HeadlineClamp>
              <Styled.Headline>{displayName}</Styled.Headline>
            </Styled.HeadlineWrapper>
          ),
        }}
        textBody={[{ size: 'medium', sizeWide: 'medium', children: category }]}
        textMeta={[
          {
            // @ts-expect-error FIXME: SC6
            variant: 'small',
            sizeWide: 'medium',
            children: (
              <FormattedPlural
                value={followers}
                one={
                  <FormattedMessage
                    id="EP_FOLLOWERS_COUNT_WEB.one"
                    values={{ value: followers }}
                  />
                }
                other={
                  <FormattedMessage
                    id="EP_FOLLOWERS_COUNT_WEB.other"
                    values={{ value: followers }}
                  />
                }
              />
            ),
          },
        ]}
        buttons={[
          {
            variant: 'tertiary',
            size: 'small',
            children: (
              <>
                <Styled.FollowButton>
                  <FormattedMessage
                    id={
                      authenticated && isFollowing
                        ? 'EP_FOLLOWING'
                        : 'EP_FOLLOW'
                    }
                  />
                </Styled.FollowButton>
                <Styled.FollowIconButton>
                  {authenticated && isFollowing ? (
                    <IconMinus width={16} height={16} />
                  ) : (
                    <IconPlus width={16} height={16} />
                  )}
                </Styled.FollowIconButton>
              </>
            ),
            onClick: toggleFollowCompany,
          },
        ]}
      />
    </Styled.Card>
  );
};

export default AffiliatesItem;
