import { useIntl, FormattedMessage } from 'react-intl';

import {
  getTrackingUrl,
  trackClickKununuProfile,
  trackClickKununuCulture,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { BodyCopy } from '@xing-com/typography';

import * as Styled from './rating.styles';

type BoxProps = {
  value: string | number;
  label?: string;
  valueDetail?: string;
};

type RatingProps = {
  profileUrl?: string;
  score?: number;
  recommendation?: number;
  isEditor: boolean;
};

const Box = ({ value, valueDetail, label }: BoxProps) => (
  <Styled.Box>
    <Styled.InnerBox>
      <Styled.BoxBackground />
      <Styled.BoxValue>
        {String(value)}
        {valueDetail && <span>{valueDetail}</span>}
      </Styled.BoxValue>
      <BodyCopy size={'small'} fontWeight="bold" lineHeight="dense">
        <FormattedMessage id={label} defaultMessage={label} />
      </BodyCopy>
    </Styled.InnerBox>
  </Styled.Box>
);

const Rating = ({
  score,
  recommendation,
  profileUrl,
  isEditor = false,
}: RatingProps) => {
  const intl = useIntl();
  const roundedScore = score ? (Math.round(score * 10) / 10).toFixed(1) : '-';

  const roundedScoreLocale =
    intl.locale === 'de' ? roundedScore.replace('.', ',') : roundedScore;

  const recommendationRate = recommendation
    ? `${Math.round(recommendation * 100)}%`
    : '-';

  return (
    <Styled.RatingWrapper>
      <Box
        value={roundedScoreLocale}
        valueDetail={'/5'}
        label="EP_KUNUNU_RATING"
      />
      <Box value={recommendationRate} label="EP_KUNUNU_RECOMMENDATION" />
      <Styled.WhatSayContainer>
        <Styled.WhatSayLabelContainer>
          <BodyCopy fontWeight="bold" lineHeight="dense" size={'medium'}>
            <FormattedMessage
              id="EP_KUNUNU_DISCOVER_REVIEWS_CULTURE"
              defaultMessage="EP_KUNUNU_DISCOVER_REVIEWS_CULTURE"
            />
          </BodyCopy>
        </Styled.WhatSayLabelContainer>
        <Styled.WhatSayLink data-cy="read_reviewsBtn">
          <Styled.SecondaryCta
            onClick={() => trackClickKununuProfile({ isEditor })}
            variant={'secondary'}
            size={'medium'}
            href={getTrackingUrl({
              to: profileUrl,
              trackingKey: 'viewKununuProfile',
            })}
            // @ts-expect-error FIXME: SC6
            target="_blank"
          >
            <FormattedMessage
              id="EP_KUNUNU_WHAT_SAY_BUTTON"
              defaultMessage="EP_KUNUNU_WHAT_SAY_BUTTON"
            />
          </Styled.SecondaryCta>
          <Styled.SecondaryCta
            onClick={() => trackClickKununuCulture({ isEditor })}
            variant={'tertiary'}
            size={'medium'}
            href={getTrackingUrl({
              to: `${profileUrl}/kultur`,
              trackingKey: 'viewKununuCulture',
            })}
            // @ts-expect-error FIXME: SC6
            target="_blank"
          >
            <FormattedMessage
              id="EP_KUNUNU_CULTURE_BUTTON"
              defaultMessage="EP_KUNUNU_CULTURE_BUTTON"
            />
          </Styled.SecondaryCta>
        </Styled.WhatSayLink>
      </Styled.WhatSayContainer>
    </Styled.RatingWrapper>
  );
};

export default Rating;
