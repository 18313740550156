import styled from 'styled-components';

import { PaginationDots as DefaultPaginationDots } from '@xing-com/pagination-dots';
import { spaceL } from '@xing-com/tokens';

export const CarouselWrapper = styled.div`
  & > div > button {
    visibility: visible !important;
    z-index: 2;
  }
  & > div > button {
    visibility: visible !important;
    z-index: 2;
  }
`;
export const PaginationDots = styled(DefaultPaginationDots)`
  margin: ${spaceL} auto 0 auto;
`;
