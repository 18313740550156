import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { BodyCopy } from '@xing-com/typography';

import * as Styled from './search-input-list-cards.styles';

export const SearchInputListCardsEmpty: FC = () => {
  const { $t } = useIntl();

  return (
    <Styled.SearchEmptyState>
      <BodyCopy noMargin size="small">
        {/* <IconWarning width={16} height={16} />  */}
        {$t({ id: 'EP_CONTACTS_SEARCH_NO_RESULTS' })}
      </BodyCopy>
    </Styled.SearchEmptyState>
  );
};
