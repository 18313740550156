import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextButton } from '@xing-com/button';
import type { EntityMedia } from '@xing-com/crate-common-graphql-types';
import {
  useDialogContext,
  useErrorContext,
  usePageContext,
  EmptyState,
} from '@xing-com/crate-entity-pages-common';

import { EntityPageUpdateMediaGalleryDocument } from '../../../../graphql/mutation/gallery-update-mutation.gql-types';
import * as Styled from './gallery-manager.styles';
import { MediaItem } from './media-item/media-item';
import VideoFailureNotifications from './video-failure-notifications';

type GalleryManagerProps = {
  mediaItemsList: EntityMedia[];
  onEditItem?: (id: string) => void;
  onVideoItemSuccess?: (id: string) => void;
  onDeleteItem?: (id: string) => void;
  setList?: () => void;
  onSave?: (newList: EntityMedia[]) => void;
  onSaveError?: () => void;
};
export const GalleryManager: FC<GalleryManagerProps> = ({
  mediaItemsList,
  onEditItem = () => undefined,
  onDeleteItem = () => undefined,
  onVideoItemSuccess = () => undefined,
  onSave = () => undefined,
  onSaveError = () => undefined,
}) => {
  const { setDialogConfirmation } = useDialogContext();
  const { showError } = useErrorContext();

  const { pageContext } = usePageContext() ?? {};
  const { pageSlug } = pageContext ?? {};

  const [sorted, setSorted] = useState<boolean>(false);
  const [sortedList, setSortedList] = useState<EntityMedia[]>([]);
  const [deletedItems, setDeletedItems] = useState<string[]>([]);
  const [failures, setFailures] = useState<any[]>([]);

  const hasExternalLinks =
    sortedList.filter(
      (item) =>
        item?.media?.__typename === 'EntityExternalVideo' &&
        item.media.externalLink
    ).length > 0;

  const dataChanged = !sorted || deletedItems.length > 0;

  const [updateMediaGallery, { loading }] = useMutation(
    EntityPageUpdateMediaGalleryDocument
  );

  const deleteNotification = (reference: string) => {
    const newFailures = [...failures];
    const foundIndex = failures.findIndex((x) => x.reference === reference);
    if (foundIndex > -1) {
      newFailures.splice(foundIndex, 1);
    }
    setFailures(newFailures);
  };

  const handleVideoFailure = (videoId: string) => {
    const foundIndex = sortedList.findIndex(
      (x) =>
        x.media?.__typename === 'EntityVideo' &&
        x.media?.videoReferenceV2 === videoId
    );

    const newMedia = [...sortedList];

    if (foundIndex > -1) {
      newMedia.splice(foundIndex, 1);
    }

    setFailures([
      {
        ...sortedList[foundIndex]?.media,
        title: sortedList[foundIndex]?.description,
      },
      ...failures,
    ]);
  };

  const handleDeleteItem = (id: string) => {
    const alreadyIn = deletedItems.includes(id);
    if (alreadyIn) {
      setDeletedItems(deletedItems.filter((item) => item !== id));
    } else {
      setDeletedItems([...deletedItems, id]);
    }
  };

  const handleOnSave = () => {
    const mutationData = sortedList.map((item, index) => ({
      position: index,
      deleted: deletedItems.includes(item.id),
      id: item.id,
    }));

    updateMediaGallery({
      variables: {
        // @ts-expect-error TODO: fix pageContext
        pageId: pageSlug,
        mediaItems: mutationData,
      },
      onCompleted: (data) => {
        const error = data?.entityPageUpdateMediaGallery?.error;
        if (error) {
          onSaveError();
          showError({ message: 'EP_JOBS_FEEDBACK_ERROR', error });
        } else {
          const newDefaultList = sortedList.filter(
            (item) => !deletedItems.includes(item.id)
          );

          onSave(newDefaultList);
        }
      },
      onError: (error) => {
        onSaveError();
        showError({
          message: 'EP_JOBS_FEEDBACK_ERROR',
          error,
        });
      },
    });
  };

  const handleDiscard = () => {
    if (!dataChanged) {
      // @ts-expect-error TODO: fix pageContext
      pageContext?.goBackUrl();
    } else {
      setDialogConfirmation({
        dialogAction: () => {
          setDialogConfirmation(null);
          // @ts-expect-error TODO: fix pageContext
          pageContext?.goBackUrl();
        },
      });
    }
  };

  const handleOnUpdateSorter = (
    original: EntityMedia[],
    sorted: EntityMedia[]
  ) => {
    const originalOrder = original.map((item) => item.id).join(' ');
    const newOrder = sorted.map((item) => item.id).join(' ');

    setSorted(originalOrder === newOrder);
  };

  useEffect(() => {
    setSortedList([...mediaItemsList]);
  }, [mediaItemsList]);

  useEffect(() => {
    handleOnUpdateSorter(mediaItemsList, sortedList);
  }, [sortedList]);

  if (mediaItemsList.length === 0) {
    return (
      <EmptyState
        headlineCopyKey="EP_GM_NO_MEDIA_HEADLINE"
        textCopyKey="EP_GM_NO_MEDIA"
      />
    );
  }

  return (
    <div>
      <div>
        {failures?.length > 0 && (
          <VideoFailureNotifications
            failures={failures}
            deleteNotification={deleteNotification}
          />
        )}
        {hasExternalLinks && (
          <Styled.VideoInfoStatusBanner variant="info" display="inline">
            <FormattedMessage
              id="EP_EDIT_ABOUTUS_EXTERNALVIDEO_INFO"
              defaultMessage="EP_EDIT_ABOUTUS_EXTERNALVIDEO_INFO"
            />
          </Styled.VideoInfoStatusBanner>
        )}
        <Styled.GalleryManagerInfo size="small">
          <FormattedMessage
            id="EP_GALLERY_MANAGER_INFO"
            defaultMessage="EP_GALLERY_MANAGER_INFO"
          />
        </Styled.GalleryManagerInfo>
        <Styled.SortableContainer
          list={sortedList.map((item) => ({ ...item, chosen: true }))}
          setList={(newState) => {
            setSortedList(newState as EntityMedia[]);
          }}
          handle="[name*='dragButton']"
          animation={200}
          ghostClass="ghost"
          forceFallback={true}
          fallbackClass="sortableFallback"
          onChoose={() => (document.body.style.cursor = 'grabbing')}
          onUnchoose={() => (document.body.style.cursor = 'default')}
        >
          {sortedList.map((item) => (
            <MediaItem
              key={item.id}
              item={item}
              showDragAndDrop={sortedList.length > 1}
              onEdit={() => {
                onEditItem(item.id);
              }}
              onDelete={() => {
                handleDeleteItem(item.id);
                onDeleteItem(item.id);
              }}
              onVideoEncodingFailure={() => handleVideoFailure(item.id)}
              onVideoEncodingSuccess={() => {
                onVideoItemSuccess(item.id);
              }}
            />
          ))}
        </Styled.SortableContainer>
      </div>
      <Styled.GalleryActions>
        <TextButton size="medium" disabled={loading} onClick={handleDiscard}>
          <FormattedMessage
            id="EP_DISCARD_CTA"
            defaultMessage="EP_DISCARD_CTA"
          />
        </TextButton>
        <Styled.PublishButton
          loading={loading}
          disabled={!dataChanged}
          size="medium"
          variant="primary"
          onClick={handleOnSave}
        >
          <FormattedMessage
            id="EP_PUBLISH_CTA"
            defaultMessage="EP_PUBLISH_CTA"
          />
        </Styled.PublishButton>
      </Styled.GalleryActions>
    </div>
  );
};
