import {
  kununuLogo,
  ModuleHeader,
  useEditContext,
} from '@xing-com/crate-entity-pages-common';
import {
  getTrackingUrl,
  trackClickEditorProfile,
} from '@xing-com/crate-entity-pages-common/src/tracking';

import * as Styled from './header.styles';

type Props = {
  loading: boolean;
  profileUrl?: string;
};

const Header = ({ loading, profileUrl = '' }: Props) => {
  const { isEditing } = useEditContext();

  return (
    <ModuleHeader
      headlineCopyKey="EP_KUNUNU_HEADLINE"
      externalEditInfo={
        !loading && isEditing
          ? {
              copyKey: 'EP_KUNUNU_EDIT_HINT',
              ctaCopyKey: 'EP_KUNUNU_EDIT_HINT_CTA',
              ctaUrl: getTrackingUrl({
                to: profileUrl,
                trackingKey: 'viewProfileAsEditor',
              }),
              cyTestId: 'EDIT_HINT_KUNUNU',
              buttonProps: {
                onClick: () => trackClickEditorProfile(),
              },
            }
          : null
      }
    >
      <Styled.HeaderLogo src={kununuLogo} alt="Kununu logo" />
    </ModuleHeader>
  );
};

export default Header;
