import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { IconEdit } from '@xing-com/icons';

import { CONTENT_FEED_TYPE_GENERIC } from '../../../config/constants';
import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';
import { UpdatePostButton } from '../../update-post-button/update-post-button';
import * as Styled from './news-item-actions.styles';

type ActionEditProps = {
  desktop?: boolean;
  onItemActionCompleted?: () => void;
  onItemActionStarted?: () => void;
};
export const ActionEdit: FC<ActionEditProps> = ({
  desktop = true,
  onItemActionCompleted = () => undefined,
  onItemActionStarted = () => undefined,
}) => {
  const { $t } = useIntl();
  const { post } = useNewsItemContext();

  const disabled = post.object?.__typename !== CONTENT_FEED_TYPE_GENERIC;

  return (
    <UpdatePostButton
      post={post}
      onUpdate={onItemActionCompleted}
      onUpdateStart={onItemActionStarted}
    >
      {(onClick) => (
        <Styled.EditButton
          size="small"
          icon={IconEdit}
          disabled={disabled}
          data-testid="EDIT_POSTING"
          onClick={onClick}
        >
          {desktop === false && $t({ id: 'NEWS_MODULE_EDIT_LABEL' })}
        </Styled.EditButton>
      )}
    </UpdatePostButton>
  );
};
