import type { FC } from 'react';

import type { ButtonProps } from '@xing-com/button';
import { redirectToLogin } from '@xing-com/crate-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import type { FlagProps, FlagVariant } from '@xing-com/flag';
import { useViewerData } from '@xing-com/hub';
import { IconMessages } from '@xing-com/icons';
import type { FallbackImageType } from '@xing-com/super-ellipse';

import type { ContactCard as ContactCardValues } from '../../types/contact-card';
import * as Styled from './contact-card.styles';

type ContactCardProps = {
  card: ContactCardValues;
  trackingModuleType?: string;
  messageEntryPoint?: string;
};
export const ContactCard: FC<ContactCardProps> = ({
  card,
  trackingModuleType,
  messageEntryPoint,
}) => {
  const { isLoggedOut } = useLoginState();
  const { data: viewerData } = useViewerData();
  const currentUser = viewerData?.user;

  const handleOnClickSendMessage = () => {
    if (isLoggedOut) {
      redirectToLogin('sendMessage', { recipientName: card.pageName });
    } else {
      const link = `/chats/new/${card.pageName}?contextId=entity_pages&entryPoint=${messageEntryPoint}`;
      window.location.assign(link);
    }
  };

  const isTheSameUser = currentUser?.id !== card.id;

  const flag: FlagProps | undefined = card.hasFlag
    ? {
        size: 'small',
        variant: card.flag?.toLowerCase() as FlagVariant,
      }
    : undefined;

  const buttons: ButtonProps[] | undefined = isTheSameUser
    ? [
        {
          variant: 'tertiary',
          icon: IconMessages,
          // @ts-expect-error test case
          'data-cy': 'message-btn',
          size: 'medium',
          'aria-label': 'message',
          onClick: () => handleOnClickSendMessage(),
          as: Styled.MessageButton,
        },
      ]
    : undefined;

  return (
    <Styled.ContactCardWrapper
      data-cy="contact"
      data-testid={`CONTACT-CARD-${card.pageName}`}
    >
      <Styled.ProfileInfo
        profileImage={{
          size: 'medium',
          src: card.profileImage.src,
          type: card.profileImage.type as FallbackImageType,
          profileName: 'user profile image',
        }}
        profileUrl={getTrackingUrl({
          to: card.profileUrl,
          trackingKey: 'viewContactProfile' + trackingModuleType,
        })}
        headline={{
          size: 'small',
          sizeWide: 'medium',
          ellipsis: true,
          noMargin: true,
          children: card.displayName,
          as: 'strong',
        }}
        textBody={[
          {
            size: 'small',
            sizeWide: 'medium',
            children: card.occupation,
          },
        ]}
        flag={flag}
        textMeta={[
          {
            size: 'small',
            sizeWide: 'medium',
            children: card.label,
          },
        ]}
        buttons={buttons}
      />
    </Styled.ContactCardWrapper>
  );
};
