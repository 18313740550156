import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { TextButton } from '@xing-com/button';
import type { EntityMedia } from '@xing-com/crate-common-graphql-types';
import {
  useDialogContext,
  useErrorContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { IconTrash } from '@xing-com/icons';
import { FormField } from '@xing-com/text-field';

import DeleteDialog from './delete-dialog';
import { EditMediaDocument } from '../../../../graphql/queries/edit-media-query.gql-types'; // eslint-disable-line
import { EntityPageUpdateMediaDocument } from '../../../../graphql/mutation/entity-page-update-media.gql-types'; // eslint-disable-line
import { EntityPageDeleteMediaDocument } from '../../../../graphql/mutation/entity-page-delete-media.gql-types'; // eslint-disable-line

import * as Styled from './image-upload.styles';

type GalleryImageEditProps = {
  item: EntityMedia;
  onSave?: () => void;
  onDelete?: () => void;
  onDiscard: () => void;
};

export const GalleryImageEdit: FC<GalleryImageEditProps> = ({
  item,
  onSave = () => undefined,
  onDelete = () => undefined,
  onDiscard = () => undefined,
}) => {
  const { pageContext } = usePageContext() ?? {};
  const { pageId } = pageContext ?? {};

  const [showDialog, setShowDialog] = useState(false);
  const [description, setDescription] = useState(item.description || '');
  const keyPressed = useRef(null);
  const { showError } = useErrorContext();
  const intl = useIntl();

  const { setDialogConfirmation } = useDialogContext();

  const handleDescriptionChange = (newDescription: string, _: any) => {
    if (newDescription.length <= 200 && keyPressed.current !== 13) {
      setDescription(newDescription);
    }
  };

  const discardHandler = () => {
    if (dataChanged) {
      setDialogConfirmation({
        dialogAction: () => {
          setDialogConfirmation(null);
          onDiscard();
        },
      });
    } else {
      onDiscard();
    }
  };

  const [updateMedia, { loading: updateLoading }] = useMutation(
    EntityPageUpdateMediaDocument
  );

  const [deleteMedia, { loading: deleteLoading }] = useMutation(
    EntityPageDeleteMediaDocument
  );

  const handleUpdate = () => {
    updateMedia({
      variables: {
        // @ts-expect-error TODO: fix pageContext
        pageId,
        mediaId: item.id,
        description,
      },
      onCompleted: (data) => {
        const error = data?.entityPageUpdateMedia?.error;

        if (error) {
          showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
        } else {
          onSave();
        }
      },
      onError: (error) => {
        showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
      },
    });
  };

  const handleDelete = () => {
    deleteMedia({
      variables: {
        // @ts-expect-error TODO: fix pageContext
        pageId,
        mediaId: item.id,
      },
      onCompleted: (data) => {
        const error = data?.entityPageDeleteMedia?.error;
        if (error) {
          showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
        } else {
          onDelete();
        }
      },
      onError: (error) => {
        setShowDialog(false);
        showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
      },
    });
  };

  // @ts-expect-error TODO: fix type
  const imageUrl = item.media?.url ?? '';

  const dataChanged = description !== item.description;

  return (
    <>
      <DeleteDialog
        showDialog={showDialog}
        loading={updateLoading || deleteLoading}
        onCancel={() => setShowDialog(false)}
        onConfirm={() => {
          handleDelete();
        }}
      />
      <Styled.ImageWrapper>
        <Styled.ImageWrapperInner>
          <Styled.Image src={imageUrl ?? ''} alt={'Image'} />
          <Styled.DeleteButton
            loading={false}
            icon={IconTrash}
            size={'small'}
            variant={'tertiary'}
            onClick={() => setShowDialog(true)}
            aria-label="Trash"
          />
        </Styled.ImageWrapperInner>
      </Styled.ImageWrapper>
      <Styled.DescriptionWrapper>
        <FormField
          onKeyDown={(e: any) => (keyPressed.current = e.keyCode)}
          // @ts-expect-error FIXME: SC6
          medium
          multiline
          value={description}
          onChange={(e: any) => handleDescriptionChange(e.target.value, e)}
          label={intl.formatMessage({
            id: 'EP_GM_IMAGE_CAPTION_LABEL',
            defaultMessage: 'EP_GM_IMAGE_CAPTION_LABEL',
          })}
          helperText={
            description && description.length > 0
              ? intl.formatMessage(
                  {
                    id: 'EP_INPUT_CHARS_LEFT',
                    defaultMessage: 'EP_INPUT_CHARS_LEFT',
                  },
                  { charactersNumber: 200 - description.length }
                )
              : intl.formatMessage({
                  id: 'EP_GM_IMAGE_CAPTION_HINT',
                  defaultMessage: 'EP_GM_IMAGE_CAPTION_HINT',
                })
          }
        />
      </Styled.DescriptionWrapper>
      <Styled.GalleryActions>
        <TextButton
          size={'medium'}
          disabled={updateLoading || deleteLoading}
          onClick={() => discardHandler()}
        >
          <FormattedMessage
            id="EP_DISCARD_CTA"
            defaultMessage="EP_DISCARD_CTA"
          />
        </TextButton>
        <Styled.PublishButton
          loading={updateLoading || deleteLoading}
          disabled={!dataChanged}
          size={'medium'}
          variant={'primary'}
          onClick={() => handleUpdate()}
        >
          <FormattedMessage
            id="EP_PUBLISH_CTA"
            defaultMessage="EP_PUBLISH_CTA"
          />
        </Styled.PublishButton>
      </Styled.GalleryActions>
    </>
  );
};
