import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { mediaSmallOnly, mediaConfinedOnly } from '@xing-com/layout-tokens';
import {
  space3XL,
  spaceL,
  spaceXL,
  spaceXXL,
  xdlColorBackground,
  xdlColorBorderSoft,
  xdlColorText,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const imageWidth = '160px';
const imageHeight = '190px';

export const FencingContainer = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FencingPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 482px;
  background-size: cover;
  background-repeat-x: no-repeat;
  background-image: url('../assets/rectangle.png');
  max-height: 100%;

  @media ${mediaSmallOnly} {
    background-repeat: no-repeat;
    background-image: url('../assets/background-fencing-mobile.png');
  }

  @media ${mediaConfinedOnly} {
    background-image: url('../assets/background-fencing-tablet-portrait.png');
  }
`;

export const FencingPlaceholderPopup = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${xdlColorBackground};
  border: solid 2px ${xdlColorBorderSoft};
  padding: ${space3XL};
  width: 724px;
  height: 286px;
  font-weight: 700;
  font-size: var(--fontSize400);

  @media ${mediaSmallOnly} {
    border: none;
    align-items: center;
    background: none;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    width: 100%;
  }
`;

export const FencingPopupPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${space3XL};

  @media ${mediaSmallOnly} {
    margin-left: 0;
    width: 100%;
  }
`;

export const FencingButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spaceXXL};

  @media ${mediaSmallOnly} {
    justify-content: center;
  }
`;

export const FencingText = styled(BodyCopy)`
  margin-top: ${spaceXL};
  font-weight: 700;
`;

export const FencingSignInButton = styled(TextButton)`
  margin-left: ${spaceL};
  font-weight: 700;
  color: ${xdlColorText};
`;

export const FencingPlaceholderOverlay = styled.div`
  display: none;
`;

export const FencingPopupWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const FencingImage = styled.img`
  @media ${mediaSmallOnly} {
    width: ${imageWidth};
    height: ${imageHeight};
    margin-bottom: ${spaceL};
  }
`;
