import styled, { css } from 'styled-components';

import { TextButton as OriginalTextButton } from '@xing-com/button';
import { ReportButton as OriginalReportButton } from '@xing-com/crate-entity-pages-common';
import { ListItem as OriginalListItem } from '@xing-com/list';
import { xdlColorHover } from '@xing-com/tokens';

const colorCopy = 'rgb(51, 51, 51)';

const sharedFlyOutStyle = css`
  background-color: transparent;
  color: ${colorCopy};
  &:hover {
    background-color: transparent;
    color: ${colorCopy};
    text-decoration: none;
  }
  & svg {
    width: 16px;
  }
`;

export const ListItem = styled(OriginalListItem)`
  > div > div {
    &:hover {
      background-color: ${xdlColorHover};
    }
  }
`;

export const ReportButton = styled(OriginalReportButton)`
  ${sharedFlyOutStyle}
  & span {
    @media (prefers-color-scheme: dark) {
      filter: invert(1);
    }
  }
`;

export const TextButton = styled(OriginalTextButton)`
  ${sharedFlyOutStyle}
`;
