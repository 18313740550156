import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SmallGrid, LinkParser } from '@xing-com/crate-entity-pages-common';
import { Headline } from '@xing-com/typography';

type ImprintProps = {
  imprint: string;
};

const Imprint: FC<ImprintProps> = ({ imprint }) => (
  <SmallGrid>
    <Headline size={'xxlarge'}>
      <FormattedMessage id="EP_LEGAL_NOTICE" defaultMessage="EP_LEGAL_NOTICE" />
    </Headline>
    <span>
      <LinkParser>{imprint}</LinkParser>
    </span>
  </SmallGrid>
);

export default Imprint;
