import type { ApolloError } from '@apollo/client';
import type React from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type { FileUploaded } from '@xing-com/crate-entity-pages-common';
import { Headline } from '@xing-com/typography';

import * as Styled from './documents-editor.styles';
import { FileUploadButton } from './file-upload-button';

type DocumentsEditorHeaderProps = {
  hideButton?: boolean | ApolloError;
  onSuccess: (fileData: FileUploaded) => void;
  isUploading?: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  disableUpload: boolean;
};
export const DocumentsEditorHeader: FC<DocumentsEditorHeaderProps> = ({
  hideButton,
  isUploading,
  setIsUploading,
  onSuccess,
  disableUpload,
}) => (
  <Styled.Header>
    <Headline size={'xxlarge'} noMargin>
      <FormattedMessage
        id="EP_EDIT_DOCUMENTS"
        defaultMessage="EP_EDIT_DOCUMENTS"
      />
    </Headline>
    {!hideButton && (
      <FileUploadButton
        disableUpload={disableUpload}
        onSuccess={onSuccess}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
      />
    )}
  </Styled.Header>
);
