import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { StatusBanner } from '@xing-com/banner';
import { WarningMessageBox as Error } from '@xing-com/crate-entity-pages-common';
import { BodyCopy } from '@xing-com/typography';
import { Row } from '@xing-com/xing-grid';

import { MembersSearchDocument } from '../../graphql/queries/members-search-query.gql-types';
import { createSearchCards } from '../../models/search-card/search-card';
import type { SearchCard } from '../../types/search-card';
import { SearchInputCard } from '../search-input-card/search-input-card';
import * as Styled from './search-input-more-results.styles';

type SearchInputMoreResultsProps = {
  queryText?: string;
  contactIdAdded?: string;
  onAddContact: (cardData: SearchCard) => void;
};
export const SearchInputMoreResults: FC<SearchInputMoreResultsProps> = ({
  queryText,
  contactIdAdded,
  onAddContact,
}) => {
  const { $t } = useIntl();

  const { data, loading, refetch, error } = useQuery(MembersSearchDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 50,
      query: {
        consumer: 'loggedin.web.entitypages.search.react.search-results',
        queries: {
          name: queryText,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) {
    return (
      <Row>
        <Styled.Column size={12} sizeDesktop={5} sizeTablet={5}>
          <>
            <Styled.Headline size={'large'}>
              {$t({ id: 'EP_RESULT_LIST_HEADLINE' })}
            </Styled.Headline>
            <div data-testid={'errorContainer'}>
              <Error
                headerText="EP_ERROR_HEADER"
                bodyText="EP_ERROR_BODY"
                buttonText="EP_ERROR_RELOAD_CTA"
                onClick={() => refetch()}
              />
            </div>
          </>
        </Styled.Column>
      </Row>
    );
  }

  if (loading) {
    return (
      <Row>
        <Styled.Column size={12} sizeDesktop={5} sizeTablet={5}>
          <>
            <Styled.Headline size={'large'}>
              {$t({ id: 'EP_RESULT_LIST_HEADLINE' })}
            </Styled.Headline>
            {Array.from({ length: 2 }).map((_, index) => (
              <SearchInputCard.Skeleton
                key={`SEARCH-INPUT-CARD-SKELETON-${index}`}
              />
            ))}
          </>
        </Styled.Column>
      </Row>
    );
  }

  const membersSearchEdges = data?.viewer?.membersSearch?.edges ?? [];
  const membersSearchNodes = membersSearchEdges
    .map(({ node }) => node)
    .filter((node) => !!node);
  const searchCards = createSearchCards(membersSearchNodes);

  const isUserPremium = data?.viewer?.features?.isPremium;

  return (
    <Row data-testid="SEARCH-INPUT-SHOW-MORE-RESULT">
      <Styled.Column size={12} sizeDesktop={5} sizeTablet={5}>
        <Styled.Headline size={'large'}>
          {$t({ id: 'EP_RESULT_LIST_HEADLINE' })}
        </Styled.Headline>
        <Styled.List>
          {searchCards.map((cardData, index) => {
            return (
              <SearchInputCard
                key={`SEARCH-INPUT-CARD-${cardData.xingId}-${index}`}
                variant="B"
                cardData={cardData}
                isLoading={
                  !!contactIdAdded && contactIdAdded === cardData.xingId
                }
                isDisabled={
                  !!contactIdAdded && contactIdAdded !== cardData.xingId
                }
                onAddContact={() => cardData && onAddContact(cardData)}
              />
            );
          })}
        </Styled.List>
        {!isUserPremium && (
          <StatusBanner variant="info" display="inline">
            <BodyCopy size={'small'}>
              <span>
                <FormattedMessage
                  id="EP_RESULT_LIST_CONTACT_V2"
                  values={{
                    a: (b) => <a href={'mailto:' + b}>{b}</a>,
                  }}
                />
              </span>
            </BodyCopy>
          </StatusBanner>
        )}
      </Styled.Column>
    </Row>
  );
};
