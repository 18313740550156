import { useLocation } from '@reach/router';
import { useEffect, useRef, type FC } from 'react';
import { useIntl } from 'react-intl';

import { useNewsContext } from '../../hooks/use-news-context/use-news-context';
import { CreatePostButton } from '../create-post-button/create-post-button';
import * as Styled from './create-post-trigger.styles';

type CreatePostTriggerProps = {
  param?: string;
  onCompleted?: () => void;
};
export const CreatePostTrigger: FC<CreatePostTriggerProps> = ({
  param = 'create',
  onCompleted = () => undefined,
}) => {
  const { isDetailPage } = useNewsContext();
  const { $t } = useIntl();
  const urlSearchParams = new URLSearchParams(useLocation().search);

  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const create = urlSearchParams.get(param);

    if (create === 'post' && !isDetailPage) {
      buttonRef.current?.click();
    }
  }, []);

  return (
    <Styled.CommboxInput data-testid="commboxTrigger">
      <CreatePostButton onCompleted={onCompleted}>
        {(onClick) => (
          <Styled.TextInput ref={buttonRef} onClick={onClick}>
            {$t({ id: 'COMMBOX_EMPTY_TEXT_FIELD' })}
          </Styled.TextInput>
        )}
      </CreatePostButton>
    </Styled.CommboxInput>
  );
};
