import type { FC } from 'react';
import { useIntl } from 'react-intl';

import type { CompanyAboutUsFacts } from '@xing-com/crate-common-graphql-types';
import {
  formatEmployeesNumber,
  useEditContext,
} from '@xing-com/crate-entity-pages-common';

import Facts from './facts';
import getCommonFactsItem from './get-common-facts-item';
import employeesIllustration from './assets/people.svg'; // eslint-disable-line

type FactsCompanyProps = {
  facts: CompanyAboutUsFacts;
};
const FactsCompany: FC<FactsCompanyProps> = ({ facts }) => {
  const companySizeRange = facts?.companyData?.companySizeRange;
  const { isEditing } = useEditContext();
  const intl = useIntl();
  const items: any = [];

  if (facts.foundingYear || isEditing) {
    items.push(
      getCommonFactsItem(
        'foundingYear',
        facts.foundingYear,
        !facts.foundingYear
      )
    );
  }

  if (companySizeRange && companySizeRange.min !== companySizeRange?.max) {
    items.push({
      type: 'employeesNumber',
      values: {
        employeesNumber: formatEmployeesNumber(companySizeRange, intl),
      },
      link: { href: 'employees' },
      illustration: employeesIllustration,
      translationKey: 'EP_EMPLOYEES_COUNT',
    });
  } else if (isEditing) {
    items.push({
      type: 'employeesNumber',
      illustration: employeesIllustration,
      translationKey: 'EP_EMPLOYEES_NUMBER_EMPTY_STATE',
      showEmptyState: true,
    });
  }

  if (facts.websiteUrl || isEditing) {
    items.push(
      getCommonFactsItem('websiteUrl', facts.websiteUrl, !facts.websiteUrl)
    );
  }

  if (facts.imprint || isEditing) {
    items.push(getCommonFactsItem('imprint', facts.imprint, !facts.imprint));
  }

  const localizationKey = facts.companyData?.industry?.localizationKey;
  const categories: string[] = localizationKey
    ? [
        intl.formatMessage({
          id: localizationKey,
          defaultMessage: localizationKey,
        }),
      ]
    : [];

  return (
    <Facts
      headlineKey="EP_COMPANY_SUMMARY"
      items={items}
      categoriesHeadlineKey="EP_COMPANY_INDUSTRY"
      categories={categories}
      facts={facts}
    />
  );
};
export default FactsCompany;
