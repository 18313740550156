import styled from 'styled-components';

import { PagesField } from '@xing-com/crate-entity-pages-common';

export const ContentWrapper = styled.div<{ hidden: boolean }>`
  ${({ hidden }) => hidden && 'display: none;'}
`;

export const IndustryField = styled(PagesField)`
  width: 100%;

  @media (min-width: 1113px) {
    display: inline-flex;

    & > div {
      height: 42px;
      width: 100%;
    }
  }
`;
