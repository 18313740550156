import { useContext } from 'react';

import { NewsContext } from '../../contexts/news-context/news-context';

export const useNewsContext = () => {
  const context = useContext(NewsContext);

  if (!context) {
    throw new Error('useNewsContext must be used within a NewsContextProvider');
  }

  return context;
};
