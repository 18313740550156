import type { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  formatEmployeesNumber,
  formatAmount,
  kununuLogo,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';

import { useHeaderContext } from '../../hooks/use-header-context/use-header-context';
import { EntityInfo } from './entity-info';
import * as Styled from './entity-info.styles';

export const EntityInfoCompany: FC = () => {
  const { pageContext } = usePageContext() ?? {};
  const { headerContent } = useHeaderContext();
  const intl = useIntl();

  const showKununu = pageContext?.modulesLoaded?.['kununu'];

  if (headerContent?.__typename !== 'EntityPageCompanyHeaderContent') {
    return null;
  }

  const entityInfoData = [];
  const companySizeRange = headerContent.company?.companySizeRange;
  const employeesCount = headerContent.company?.employeesCount;
  const kununuData = headerContent.company?.kununuData;
  const followersTotal = headerContent.followers?.total || 0;
  const roundedKununuRating =
    showKununu && kununuData && kununuData.ratingAverage
      ? (Math.round(kununuData.ratingAverage * 10) / 10).toFixed(1)
      : null;

  if (followersTotal > 0) {
    entityInfoData.push({
      value: formatAmount({ value: followersTotal, intl }),
      label: ` ${intl.formatMessage({
        id: followersTotal > 1 ? 'EP_FOLLOWERS.other' : 'EP_FOLLOWERS.one',
      })}`,
      testId: 'HEADER_FOLLOWERS_TOTAL',
    });
  }

  if (companySizeRange && companySizeRange.min !== companySizeRange.max) {
    entityInfoData.push({
      value: formatEmployeesNumber(companySizeRange, intl),
      label: ` ${intl.formatMessage({ id: 'EP_EMPLOYEES' })}`,
      className: !roundedKununuRating ? 'hideBorderOnLowRes' : undefined,
    });
  }

  if (
    companySizeRange &&
    companySizeRange.min !== companySizeRange.max &&
    employeesCount
  ) {
    entityInfoData.push({
      value: formatAmount({ value: employeesCount, intl }),
      label: ` ${intl.formatMessage({ id: 'EP_ON_XING' })}`,
      className: 'hideOnLowRes',
    });
  }

  if (roundedKununuRating && parseFloat(roundedKununuRating) > 0) {
    entityInfoData.push({
      value: (
        <>
          {intl.locale === 'de'
            ? roundedKununuRating.replace('.', ',')
            : roundedKununuRating}
          <Styled.KununuRatingLabel>/5</Styled.KununuRatingLabel>
        </>
      ),
      label: <Styled.KununuLogo src={kununuLogo} alt="Kununu logo" />,
    });
  }

  return <EntityInfo data={entityInfoData} />;
};
