import type { FC } from 'react';

import { Button } from '@xing-com/button';
import {
  PAGES_NEWS_PATH,
  PAGES_PATH,
  URL_SOCIAL_BAR,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { IconMore } from '@xing-com/icons';
import { usePopOver } from '@xing-com/pop-over';
import { SocialBar } from '@xing-com/social-bar';

import { useNewsContext } from '../../../hooks/use-news-context/use-news-context';
import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';
import { NewsItemMenu } from '../news-item-menu/news-item-menu';
import * as Styled from './news-item-social.style';

export const NewsItemSocial: FC = () => {
  const { post } = useNewsItemContext();
  const { isDetailPage } = useNewsContext();
  const { pageContext } = usePageContext() ?? {};
  const activityId = post?.globalId?.split(':').pop();

  // include back_to parameter to allow startpage to link back to current page
  const detailUrl = `/discover/detail-activities/${activityId}?back_to=${encodeURIComponent(
    isDetailPage
      ? PAGES_NEWS_PATH(pageContext?.pageSlug as string)
      : PAGES_PATH(pageContext?.pageSlug as string)
  )}`;

  const popOver = usePopOver();
  const flyOut = (
    <Styled.Actions>
      <Button
        data-testid="flyout-anchor"
        size={'medium'}
        icon={IconMore}
        onClick={popOver.handleShow}
        innerRef={popOver.triggerRef}
        aria-label="More"
      />
    </Styled.Actions>
  );

  const handleCommentClick = () => window.location.assign(detailUrl);
  return (
    <Styled.Wrapper>
      <SocialBar
        urn={post?.globalId as string}
        onCommentClick={handleCommentClick}
        hasShare={post.metadata?.isRecommendable}
        hasReaction={post.metadata?.isLikeable}
        hasComment={post.metadata?.isCommentable}
        shareZenViewTo={URL_SOCIAL_BAR}
        propChannel="wbm/EntityPages"
        entryPoint="entity_page_social_share"
        rightBar={flyOut}
      />

      {post.globalId && (
        <NewsItemMenu
          activityGlobalId={pageContext?.globalId as string}
          authorUrn={post.globalId}
          popOver={popOver}
        />
      )}
    </Styled.Wrapper>
  );
};
