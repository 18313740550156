import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { Carousel as InnerCarousel } from '@xing-com/crate-entity-pages-common';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { scale080 } from '@xing-com/tokens';

export const AwardCard = styled(Card)`
  display: inline-block;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    margin-left: 16px;
  }

  width: calc(50% - 8px);
  padding-top: 30%;
  position: relative;
`;

export const Image = styled.img`
  max-width: calc(100% - 16px);
  max-height: calc(100% - 16px);
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Carousel = styled(InnerCarousel)`
  > div > div {
    @media ${mediaWideNavless} {
      & > *:nth-child(n) {
        width: calc((100% - 1 * ${scale080}) / 2);
      }
    }

    @media (min-width: 1113px) {
      & > *:nth-child(n) {
        width: calc((100% - 2 * ${scale080}) / 3);
      }
    }
  }
`;
