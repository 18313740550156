import { parsePhoneNumberFromString } from 'libphonenumber-js';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import type {
  Country,
  GpsCoordinates,
} from '@xing-com/crate-common-graphql-types';
import {
  trackContactOpenMaps,
  trackContactClickPhoneNumber,
  trackContactClickEmail,
  trackContactClickWebpage,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { useHost } from '@xing-com/crate-xinglet';
import { BodyCopy } from '@xing-com/typography';

import { Map } from '../map/map';
import * as Styled from './location.styles';

type PhoneNumber = {
  countryCode: number;
  number: string;
};
type LocationProps = {
  trackingModuleType: string;
  label: string;
  city: string;
  address: string;
  postcode: string;
  country?: Nullable<Omit<Country, 'provinces'>>;
  coordinates?: Nullable<GpsCoordinates>;
  email?: string;
  phoneNumber?: Nullable<PhoneNumber>;
  faxNumber?: Nullable<PhoneNumber>;
  websiteURL?: Nullable<string>;
  intersected?: boolean;
};

const buildContactNumber = (countryCode: number, number: string) =>
  parsePhoneNumberFromString(
    `+${countryCode}${number}`
  )?.formatInternational() || '';

export const Location: FC<LocationProps> = ({
  trackingModuleType,
  label,
  city,
  address,
  postcode,
  country,
  coordinates,
  email,
  phoneNumber,
  faxNumber,
  websiteURL,
  intersected,
}) => {
  const intl = useIntl();
  const { isServer } = useHost();

  return (
    <Styled.MapsWrapper>
      {!isServer && intersected && coordinates && (
        <Map lat={coordinates?.latitude} lng={coordinates?.longitude} />
      )}
      <Styled.MapsButton
        variant={'secondary'}
        size="small"
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          `${address}, ${postcode} ${city}, ${country?.localizationValue}`
        )}`}
        onClick={() => trackContactOpenMaps()}
        // @ts-expect-error FIXME: SC6
        target="_blank"
        data-cy="open_maps_btn"
        data-testid="locations-map-external"
        rel="nofollow"
      >
        {intl.formatMessage({
          id: 'EP_LOCATIONS_OPEN_MAP',
          defaultMessage: 'EP_LOCATIONS_OPEN_MAP',
        })}
      </Styled.MapsButton>

      <Styled.AddressWrapper data-testid="locations-address-card">
        <Styled.MapHeadline
          fontWeight="bold"
          lineHeight="dense"
          size={'xlarge'}
          noMargin
        >
          {label}
        </Styled.MapHeadline>
        <Styled.Address size="small">
          {`${address}, ${postcode} ${city}, ${
            country &&
            intl.formatMessage({
              id: country.localizationKey,
              defaultMessage: country.localizationKey,
            })
          }`}{' '}
        </Styled.Address>
        <div className="allContactsWrapper">
          <Styled.ContactNumbers>
            {phoneNumber && (
              <Styled.LinkableContactWrapper>
                <Styled.PhoneButton
                  // @ts-expect-error FIXME: SC6
                  fontWeight="regular"
                  lineHeight="dense"
                  size="small"
                  noMargin
                  onClick={() =>
                    trackContactClickPhoneNumber(trackingModuleType)
                  }
                  href={`tel:+${phoneNumber.countryCode}${phoneNumber.number}`}
                >
                  {`${intl.formatMessage({
                    id: 'EP_TELEPHONE',
                    defaultMessage: 'EP_TELEPHONE',
                  })}: ${buildContactNumber(
                    phoneNumber.countryCode,
                    phoneNumber.number
                  )}`}
                </Styled.PhoneButton>
              </Styled.LinkableContactWrapper>
            )}
            {faxNumber && (
              <BodyCopy noMargin size="small">
                {`${intl.formatMessage({
                  id: 'EP_FAX',
                  defaultMessage: 'EP_FAX',
                })}: ${buildContactNumber(
                  faxNumber.countryCode,
                  faxNumber.number
                )}`}
              </BodyCopy>
            )}
          </Styled.ContactNumbers>
          {email && (
            <Styled.LinkableContactWrapper>
              <Styled.WebContactsButton
                size="small"
                href={`mailto:${email}`}
                onClick={() => trackContactClickEmail(trackingModuleType)}
              >
                {email}
              </Styled.WebContactsButton>
            </Styled.LinkableContactWrapper>
          )}
          {websiteURL && (
            <Styled.WebsiteLink>
              <Styled.WebContactsButton
                size="small"
                // @ts-expect-error FIXME: SC6
                target={'_blank'}
                href={websiteURL}
                onClick={() => trackContactClickWebpage(trackingModuleType)}
                rel={'nofollow'}
              >
                {websiteURL}
              </Styled.WebContactsButton>
            </Styled.WebsiteLink>
          )}
        </div>
      </Styled.AddressWrapper>
    </Styled.MapsWrapper>
  );
};
