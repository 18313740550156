import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import {
  ModuleHeader,
  UpsellBanner,
  usePageContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-entity-pages-common';

import { ContactsGrid } from '../components/contacts-grid/contacts-grid';
import { EntityPagesContactsListDocument } from '../graphql/queries/entity-pages-contacts-list.gql-types';
import { createContactsCards } from '../models/contact-card/contact-card';

export const ContactsDetail: FC = () => {
  const { pageContext } = usePageContext() ?? {};
  const upsellActive = pageContext?.upsellActive;

  const { data, loading, refetch } = useQuery(EntityPagesContactsListDocument, {
    variables: {
      id: pageContext?.pageSlug as string,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return (
      <>
        <ModuleHeader
          editTestId="EDIT_BUTTON_ON_CONTACTS"
          headlineCopyKey="EP_WHO_TO_CONTACT"
          editTo={`${pageContext?.basePath}/${pageContext?.pageSlug}/edit/contacts`}
          small
        />
        <ContactsGrid.Skeleton />
      </>
    );
  }

  const contactsNodes = data?.entityPageContactUsers?.collection ?? [];

  if (contactsNodes.length === 0) {
    return (
      <>
        <ModuleHeader
          editTestId="EDIT_BUTTON_ON_CONTACTS"
          headlineCopyKey="EP_WHO_TO_CONTACT"
          editTo={`${pageContext?.basePath}/${pageContext?.pageSlug}/edit/contacts`}
          small
        />
        <div data-testid={'errorContainer'}>
          <Error
            headerText="EP_ERROR_HEADER"
            bodyText="EP_ERROR_BODY"
            buttonText="EP_ERROR_RELOAD_CTA"
            onClick={refetch}
          />
        </div>
      </>
    );
  }

  const contactsCards = createContactsCards(contactsNodes);

  return (
    <>
      {upsellActive && (
        <UpsellBanner
          headerCopyKey="EP_UPSELL_CONTACT_HEADER"
          bodyCopyKey="EP_UPSELL_CONTACT_BODY"
          moduleName="contactsmodule"
        />
      )}

      <ModuleHeader
        editTestId="EDIT_BUTTON_ON_CONTACTS"
        headlineCopyKey="EP_WHO_TO_CONTACT"
        editTo={`${pageContext?.basePath}/${pageContext?.pageSlug}/edit/contacts`}
        small
      />

      <ContactsGrid
        contactCards={contactsCards}
        messageEntryPoint="entity_pages_sub_contact_message"
        trackingModuleType="Subpage"
      />
    </>
  );
};
