import type React from 'react';

// import Jobs from '@xing-com/pages-jobs/jobs-module/jobs-module';
import {
  AboutUsDetail,
  AboutUsContainer,
} from '@xing-com/crate-entity-pages-about-us';
import { CompanyRecommendations } from '@xing-com/crate-entity-pages-company-recommendations';
import {
  ContactsModule,
  ContactsDetail,
} from '@xing-com/crate-entity-pages-contacts';
import {
  EmployeesModule,
  EmployeesDetail,
} from '@xing-com/crate-entity-pages-employees';
import { IdealEmployer } from '@xing-com/crate-entity-pages-ideal-employer';
import {
  JobsDetailContainer,
  JobsModule,
} from '@xing-com/crate-entity-pages-jobs';
import { KununuContainer } from '@xing-com/crate-entity-pages-kununu';
import { LocationsContainer } from '@xing-com/crate-entity-pages-location';
import { NewsModule, NewsDetail } from '@xing-com/crate-entity-pages-news';

type modulesMapProps = {
  [name: string]: {
    module: React.ComponentType<any>;
    subpageModule?: React.ComponentType<any>;
    seoIndexSubpage?: boolean;
    featureSwitch?: string;
    tabs?: {
      [tabName: string]: React.ComponentType<any>;
    };
  };
};

export const modulesMap: modulesMapProps = {
  about_us: {
    module: AboutUsContainer,
    subpageModule: AboutUsDetail,
  },
  jobs: {
    module: JobsModule,
    subpageModule: JobsDetailContainer,
  },
  ideal_employer: {
    module: IdealEmployer,
  },
  news: {
    module: NewsModule,
    subpageModule: NewsDetail,
  },
  contacts: {
    module: ContactsModule,
    subpageModule: ContactsDetail,
    seoIndexSubpage: false,
  },
  kununu: {
    module: KununuContainer,
  },
  employees: {
    module: EmployeesModule,
    subpageModule: EmployeesDetail,
  },
  company_recommendations: {
    module: CompanyRecommendations,
  },
  sampleModule: {
    module: () => <h1>Sample Module</h1>,
    subpageModule: () => <h1>Sample Module Detail</h1>,
    tabs: {
      tab1: () => <h1>tab 1</h1>,
      tab2: () => <h1>tab 2</h1>,
      tab3: () => <h1>tab 3</h1>,
    },
  },
  locations: {
    // module: Locations,
    module: LocationsContainer,
  },
};
