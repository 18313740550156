import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { Carousel as InnerCarousel } from '@xing-com/crate-entity-pages-common';
import { mediaSmallOnly, mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const CardWrapper = styled(Card)`
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
`;

export const CardReviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const starRating = styled.div`
  margin-right: 8px;
`;

export const Date = styled(BodyCopy)`
  font-weight: normal;
  font-size: 12px;
  color: #b3b3b3;
  margin: 0;
`;

export const Quote = styled(BodyCopy)`
  font-size: 12px;
  margin-top: ${spaceL};
  height: 68px;
  overflow: hidden;
`;

export const ReviewsFooter = styled.div`
    display: flex;  
    flex-direction: row;
    justify-content: space-between;
}
`;

export const EmployeeStatus = styled(BodyCopy)`
  font-size: 12px;
  font-weight: normal;
  color: #b3b3b3;
  margin: 0 8px 0 0;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Carousel = styled(InnerCarousel)`
  > div > div {
    @media ${mediaSmallOnly} {
      & > * {
        padding-left: 20px;
        margin-right: 0;
        box-sizing: content-box;
      }

      & > *:last-child {
        padding-right: 20px;
        box-sizing: border-box;
        width: calc(80% + 20px);
      }

      .carousel {
        margin-left: -20px;
        margin-right: -20px;
      }
    }

    @media ${mediaWideNavless} {
      & > * {
        width: calc((100% - 2 * 16px) / 3);
      }
    }
  }
`;
