import type { FC } from 'react';

import {
  SkeletonProfileImage,
  SkeletonBody,
  SkeletonButton,
} from '@xing-com/skeleton';

import * as Styled from './employees-card-vertical.styles';

export const EmployeesCardVerticalSkeleton: FC = () => (
  <Styled.SkeletonCard>
    <SkeletonProfileImage size="xxlarge" />
    {/* @ts-expect-error FIXME: SC6 */}
    <SkeletonBody size="small" />
    <SkeletonButton size="small" />
  </Styled.SkeletonCard>
);
