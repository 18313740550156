import { useMutation } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import type { FC } from 'react';

import type { PostingsVisibility } from '@xing-com/crate-common-graphql-types';
import {
  EntityPageContractType,
  EntityPageFocusType,
} from '@xing-com/crate-common-graphql-types';
import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import {
  trackCommboxAudienceSelect,
  trackCommboxOpenActorSwitch,
  trackCommboxOpenAudienceSelection,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import type { CommboxProps } from '@xing-com/crate-entity-pages-news-commbox';
import {
  ATTACHMENT_TYPES,
  Commbox,
  COMMBOX_ACTOR_TYPES,
} from '@xing-com/crate-entity-pages-news-commbox';

import { COMMBOX_SELECTABLE_AUDIENCES } from '../../config/constants';
import type { EntityPageFeedCollectionFragment } from '../../graphql/fragments/entity-page-feed-collection-fragment.gql-types';
import type { NewsCommboxUpdatePostingMutationVariables } from '../../graphql/mutations/update-posting-mutation.gql-types';
import { NewsCommboxUpdatePostingDocument } from '../../graphql/mutations/update-posting-mutation.gql-types';
import { mapAudienceTrackingValue } from '../../utils';

const COMMBOX_SUCESS_DIALOG_VISIBILITY_TIMEOUT = 7000;

type UpdatePostButtonProps = {
  children: (onClick: () => void) => JSX.Element;
  defaultPostVisibility?: PostingsVisibility;
  post: EntityPageFeedCollectionFragment;
  onClose?: () => void;
  onUpdateStart?: () => void;
  onUpdate?: () => void;
};

export const UpdatePostButton: FC<UpdatePostButtonProps> = ({
  post,
  children,
  onClose = () => undefined,
  onUpdateStart = () => undefined,
  onUpdate = () => undefined,
}) => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};

  const [isUploadModalTriggered, setUploadModalTrigger] = useState(false);
  const [postWasSent, setPostWasSent] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isAllowedToPost, setIsAllowedPost] = useState(true);
  const [isVideoUploadOpen, setIsVideoUploadOpen] = useState(false);
  const [attachmentType, setAttachmentType] = useState('EMPTY');
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [successPostHeadLine, setSuccessPostHeadLine] = useState(
    'COMMBOX_SUCCESS_HEADLINE'
  );
  const [successPostHeadLineValues, setSuccessPostHeadLineValues] = useState(
    {}
  );
  const [successPostDescription, setSuccessPostDescription] = useState(
    'COMMBOX_SUCCESS_DESC'
  );
  const [successPostDescriptionValues, setSuccessPostDescriptionValues] =
    useState({});

  const canEditLinkPreview =
    pageContext?.focusType === EntityPageFocusType.Publisher && isEditing;

  const isPaidPage =
    pageContext?.contractType === EntityPageContractType.PaidPlus ||
    pageContext?.contractType === EntityPageContractType.Paid;
  const isNewsPage = (type: EntityPageFocusType) =>
    type === EntityPageFocusType.TopicPage ||
    type === EntityPageFocusType.Publisher ||
    type === EntityPageFocusType.IndustryPage;

  const [
    postingUpdatePosting,
    { loading: isUpdatingPosting, error: updatePostingError },
  ] = useMutation(NewsCommboxUpdatePostingDocument, {
    onCompleted: (data) => {
      const error = data?.postingsUpdatePosting?.error;

      if (!error) {
        setShowSuccessMessage(true);
        setPostWasSent(true);

        onUpdate();
      }
    },
    onError: () => setIsAllowedPost(true),
  });

  const setBannerWhenScheduling = (publishAt?: string | null) => {
    let successMessageValues = {};
    let successMessage = 'COMMBOX_SUCCESS_DESC';
    if (publishAt) {
      setSuccessPostHeadLine('COMMBOX_SUCCESS_HEADLINE');
      setSuccessPostHeadLineValues({});
      successMessage = 'COMMBOX_SUCCESS_SCHEDULED_DESC';
      const publishedDate = new Date(publishAt);
      successMessageValues = {
        date: `${publishedDate.getDate()}/${
          publishedDate.getMonth() + 1
        }/${publishedDate.getFullYear()}`,
        time: `${publishedDate.getHours()}:${
          publishedDate.getMinutes() < 10
            ? `0${publishedDate.getMinutes()}`
            : publishedDate.getMinutes()
        }`,
      };
      setSuccessPostDescription(successMessage);
      setSuccessPostDescriptionValues(successMessageValues);
    }
  };

  const updatePosting = async ({
    id,
    commentArticleV1,
    publishAt,
  }: NewsCommboxUpdatePostingMutationVariables) => {
    setBannerWhenScheduling(publishAt);
    onUpdateStart();
    const { data } = await postingUpdatePosting({
      variables: {
        id,
        commentArticleV1,
        publishAt,
      },
    });

    return {
      error: data?.postingsUpdatePosting?.error,
      success: data?.postingsUpdatePosting?.success,
    };
  };

  useEffect(() => {
    if (postWasSent) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      timeoutRef.current = setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessPostDescription('COMMBOX_SUCCESS_DESC');
        setSuccessPostDescriptionValues({});
        timeoutRef.current = null;
      }, COMMBOX_SUCESS_DIALOG_VISIBILITY_TIMEOUT);
      setPostWasSent(false);
    }
  }, [postWasSent]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const commboxSharedProps: CommboxProps = {
    attachmentType,
    canEditLinkPreview,
    globalId: pageContext?.globalId as string,
    isAllowedToPost,
    isUploadModalTriggered,
    isVideoUploadOpen,
    setAttachmentType,
    setIsAllowedPost,
    setIsVideoUploadOpen,
    setShowSuccessMessage,
    setUploadModalTrigger,
    showSuccessMessage,
    successPostDescription,
    successPostDescriptionValues,
    successPostHeadLine,
    successPostHeadLineValues,
    application: 'ENTITY_PAGES',
    onOpenAudienceSelection: () => trackCommboxOpenAudienceSelection(),
    onOpenActorSwitch: () => trackCommboxOpenActorSwitch(),
    // @ts-expect-error Commbox will be removed in Q2
    onSelectAudience: (id: 'PUBLIC' | 'PRIVATE') =>
      trackCommboxAudienceSelect(mapAudienceTrackingValue(id)),
    onClose: () => {
      onClose();
      setIsVideoUploadOpen(false);
    },
    pageId: pageContext?.globalId,
    actors: [
      {
        logo: pageContext?.logo as string,
        // @ts-expect-error Commbox will be removed in Q2
        actorName: pageContext?.pageTitle,
        globalId: pageContext?.globalId as string,
        type: COMMBOX_ACTOR_TYPES.PAGE,
        features: [ATTACHMENT_TYPES.IMAGE, ATTACHMENT_TYPES.VIDEO],
      },
    ],
    // @ts-expect-error Commbox will be removed in Q2
    selectableAudiences: COMMBOX_SELECTABLE_AUDIENCES,
    successMessage: {
      description: successPostDescription,
      descriptionValues: successPostDescriptionValues,
      headline: successPostHeadLine,
      headlineValues: successPostHeadLineValues,
      setShowSuccessMessage,
      showSuccessMessage,
    },
    featurePermissions: {
      enableMentions: true,
      enableVideo: true,
      enableScheduling: isPaidPage,
      enablePolls: false,
      enableMultiImage: false,
      enableSchedulingLock:
        Boolean(pageContext?.companyId) ||
        isNewsPage(pageContext?.focusType as EntityPageFocusType),
    },
  };

  return (
    <Commbox
      {...commboxSharedProps}
      posting={post.object}
      tagManagerId="btn_postnews"
      createPostingError={updatePostingError}
      isCreatingPosting={isUpdatingPosting}
      postMutation={updatePosting}
    >
      {children}
    </Commbox>
  );
};
