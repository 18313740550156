import { useNavigate } from '@reach/router';
import type React from 'react';
import type { FC } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import type {
  DisplayFlag,
  UserFlagsDisplayFlag,
} from '@xing-com/crate-common-graphql-types';
import {
  capitalizeString,
  redirectToLogin,
} from '@xing-com/crate-entity-pages-common';
import {
  getTrackingUrl,
  trackEmployeesAddContactButtonAction,
  ENTITY_PAGE_MAIN_EMPLOYEES,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useViewerData } from '@xing-com/hub';

import {
  BASIC_FLAG,
  MODERATOR_PREMIUM_FLAG,
  EXECUTIVE,
} from '../../constants/employees-module';
import {
  FOLLOW_EMPLOYEE_ACTION,
  FOLLOW_EMPLOYEE_ID_PARAM,
  addContactButtonDisabled,
} from '../../helpers';
import { useNetworkRequestMutation } from '../../hooks/use-network-request-mutation';
import type { EmployeeCard } from '../../types/employee-card';
import { EmployeesCardVerticalSkeleton } from './employees-card-vertical.skeleton';
import * as Styled from './employees-card-vertical.styles';

type EmployeesCardVerticalProps = {
  card: EmployeeCard;
  className?: string;
  onAddContact?: () => void;
};
export const EmployeesCardVertical: FC<EmployeesCardVerticalProps> & {
  Skeleton: typeof EmployeesCardVerticalSkeleton;
} = ({ card, className, onAddContact = () => undefined }) => {
  const { data: viewerData } = useViewerData();
  const currentUser = viewerData?.user;
  const { isLoggedIn } = useLoginState();
  const navigate = useNavigate();

  const [executeNetworkRequest, { loading: loadingNetworkRequest }] =
    useNetworkRequestMutation({
      onCompleted: () => {
        onAddContact();
      },
    });

  const getFlag = (flag?: UserFlagsDisplayFlag | DisplayFlag) => {
    if (!flag || flag === BASIC_FLAG || flag === EXECUTIVE) return;
    if (flag === MODERATOR_PREMIUM_FLAG)
      return {
        size: 'small',
        children: 'Moderator',
        variant: 'moderator',
      };
    return {
      size: 'small',
      children: flag && capitalizeString(flag),
      variant: flag?.toLowerCase(),
    };
  };

  const handleClickProfile = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const trackingUrl = getTrackingUrl({
      to: card.profileUrl,
      trackingKey: `viewEmployeesProfileMain`,
    });
    window.location.href = trackingUrl;
  };
  const handleAddContact = () => {
    if (!isLoggedIn) {
      redirectToLogin(`${FOLLOW_EMPLOYEE_ACTION}`, {
        [FOLLOW_EMPLOYEE_ID_PARAM]: card.id,
      });
    }
    return executeNetworkRequest(card.id);
  };
  const handleSendMessage = () => {
    navigate(
      `/chats/new/${card.pageName}?contextId=entity_pages&entryPoint=entity_pages_main_employees_message`
    );
  };

  const Headline = card.flag !== BASIC_FLAG ? Styled.Headline : Dotdotdot;

  return (
    <Styled.Card
      className={className}
      data-testid={`EMPLOYEES-CARD-VERTICAL-${card.id}`}
    >
      <Styled.ProfileInfo
        alignment={'vertical'}
        profileImage={{
          src: card.profileImage.src,
          type: card.profileImage.type as any,
          profileName: card.profileImage.profileName,
          // @ts-expect-error seems like the alt is not defined anymore, we should check this
          alt: `${card.profileImage.profileName}'s profile picture`,
          connectionDegree: isLoggedIn ? card.profileImage.connectionDegree : 0,
          style: { fill: 'none' },
          variant: 'responsive',
        }}
        flag={getFlag(card.flag) as any}
        profileUrl={card.profileUrl}
        headline={{
          size: 'small',
          as: 'strong',
          noMargin: true,
          children: (
            <Headline clamp={2} tagName="span">
              {card.displayName}
            </Headline>
          ),
        }}
        textBody={[
          {
            size: 'small',
            children: (
              <Styled.TextBody tagName="span" clamp={1}>
                {card.position}
              </Styled.TextBody>
            ),
          },
        ]}
        onClick={handleClickProfile}
      />
      {currentUser && currentUser.id !== card.id && (
        <Styled.ButtonsContainer>
          {card.relationship === 'CONTACT' ? (
            <Button
              className="employees-button"
              size="small"
              variant="secondary"
              onClick={handleSendMessage}
            >
              <FormattedMessage id="EP_EMPLOYEES_BUTTON_SEND_MESSAGE" />
            </Button>
          ) : (
            <Button
              className="employees-button"
              size="small"
              variant="secondary"
              loading={loadingNetworkRequest}
              onClick={() => {
                handleAddContact();
                trackEmployeesAddContactButtonAction(
                  ENTITY_PAGE_MAIN_EMPLOYEES
                );
              }}
              disabled={
                card.relationship && addContactButtonDisabled(card.relationship)
              }
              data-testid="add-employee"
            >
              <FormattedMessage id="EP_EMPLOYEES_BUTTON_ADD_CONTACT" />
            </Button>
          )}
        </Styled.ButtonsContainer>
      )}
    </Styled.Card>
  );
};

EmployeesCardVertical.Skeleton = EmployeesCardVerticalSkeleton;
