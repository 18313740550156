import styled from 'styled-components';

import { ProfileInfoAdapter } from '@xing-com/crate-entity-pages-common';
import { SkeletonProfileImage, SkeletonBody } from '@xing-com/skeleton';
import {
  spaceXXS,
  spaceXS,
  spaceL,
  scale050,
  spaceM,
  xdlColorBackgroundTertiary,
  spaceS,
  scale080,
} from '@xing-com/tokens';

export const ProfileInfo = styled(ProfileInfoAdapter)`
  width: auto;
  padding: ${spaceM};

  & p {
    margin-bottom: 0;
  }

  & h2 {
    margin-bottom: 0;
  }

  & strong {
    margin-right: ${spaceS};
  }

  &:hover {
    background-color: ${xdlColorBackgroundTertiary};
  }

  &: first-child {
    &:hover {
      border-top-left-radius: ${scale080};
      border-top-right-radius: ${scale080};
    }
  }
`;

// Sekeleton
export const Container = styled.div`
  display: flex;
  margin-bottom: ${spaceL};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex: 1;

  &:hover {
    text-decoration: none;
  }
`;

export const LogoImageContainer = styled(SkeletonProfileImage)`
  display: inline-block;
  width: 48px;
  height: 48px;
  margin-right: ${scale050};

  & > div {
    height: 100%;
  }
`;

export const UserInfo = styled.div`
  flex: 1;
`;

export const TitleSkeleton = styled(SkeletonBody)`
  margin-bottom: ${spaceXXS};
`;

export const BodySkeleton = styled(SkeletonBody)`
  width: 85%;

  & div:first-child {
    margin-bottom: ${spaceXS};
  }
`;
