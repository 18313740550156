import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useState } from 'react';

type DataChangedType = boolean | null;

type DialogConfirmationType = {
  dialogAction: () => void;
  [key: string]: string | (() => void);
} | null;

type DialogPreActionType = {
  current: () => void;
} | null;

type executeWithDialogType = (action: () => void) => void;

export type DialogContextType = {
  dataChanged: DataChangedType;
  setDataChanged: Dispatch<SetStateAction<DataChangedType>>;
  dialogConfirmation: DialogConfirmationType;
  setDialogConfirmation: Dispatch<SetStateAction<DialogConfirmationType>>;
  dialogPreAction: DialogPreActionType;
  setDialogPreAction: Dispatch<SetStateAction<DialogPreActionType>>;
  executeWithDialog: executeWithDialogType;
};
export const DialogContext = createContext<DialogContextType | undefined>(
  undefined
);

export const DialogContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dataChanged, setDataChanged] = useState<DataChangedType>(false);
  const [dialogConfirmation, setDialogConfirmation] =
    useState<DialogConfirmationType>(null);
  const [dialogPreAction, setDialogPreAction] =
    useState<DialogPreActionType>(null);

  const executeWithDialog = (action: () => void) => {
    if (dataChanged) {
      setDialogConfirmation({
        dialogAction: () => {
          setDialogConfirmation(null);
          setDataChanged(false);
          action();
        },
      });
    } else {
      action();
    }
  };

  return (
    <DialogContext.Provider
      value={{
        dataChanged,
        setDataChanged,
        dialogConfirmation,
        setDialogConfirmation,
        dialogPreAction,
        setDialogPreAction,
        executeWithDialog,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
