import Dotdotdot from 'react-dotdotdot';
import styled from 'styled-components';

import { Card as InternalCard } from '@xing-com/card';
import { ProfileInfoAdapter as ProfileInfo } from '@xing-com/crate-entity-pages-common';
import { mediaSmallOrTiny, mediaWideNavless } from '@xing-com/layout-tokens';
import { SkeletonButton as InternalSkeletonButton } from '@xing-com/skeleton';
import {
  spaceM,
  spaceL,
  spaceXL,
  spaceXXL,
  scale110,
  xdlColorBorder,
  xdlColorBorderSoft,
} from '@xing-com/tokens';

export const Wrapper = styled.div`
  padding: 0 0 ${spaceXXL} 0;

  @media ${mediaSmallOrTiny} {
    min-height: 0;
  }
`;

export const AboutUsDetailsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr);
  row-gap: ${spaceL};
  margin-bottom: ${scale110};

  @media (min-width: 1113px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${spaceXL};
  }
`;

export const UpsellLayout = styled.div`
  margin-bottom: ${spaceXXL};
`;

export const Card = styled(InternalCard)`
  width: 100%;
  padding: ${spaceM};
  border-radius: 4px;
  border: 2px solid ${xdlColorBorderSoft};
  border-radius: 8px;

  @media (min-width: 1113px) {
    margin-right: ${spaceL};

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media ${mediaWideNavless} {
    width: 100%;
    margin-right: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: ${spaceL};
  }
`;

export const HeadlineWrapper = styled.div`
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeadlineClamp = styled(Dotdotdot)`
  display: none;
  margin-right: 0;

  @media ${mediaSmallOrTiny} {
    display: flex;

    & button {
      display: none !important;
    }
  }
`;

export const Headline = styled.div`
  display: flex;
  margin-right: 0;
  text-overflow: ellipsis;

  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;

export const AffiliatesProfileInfo = styled(ProfileInfo)`
  margin-bottom: 0;
  & h2 {
    margin-bottom: 0;
  }
`;

export const FollowButton = styled.div`
  display: flex;

  @media (max-width: 320px) {
    display: none;
  }
`;

export const FollowIconButton = styled.div`
  display: none;

  @media (max-width: 320px) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  width: 100%;
  border: ${xdlColorBorder} ${xdlColorBorderSoft};
  border-radius: 4px;
  padding: 20px;
  align-items: center;

  @media ${mediaWideNavless} {
    width: 100%;
  }
`;

export const SkeletonProfileImage = styled.div`
  height: 68px;
  width: 68px;
  flex-shrink: 0;
  margin-right: ${spaceM};
`;

export const SkeletonProfileInfo = styled.div`
  flex-grow: 1;
  margin-right: ${spaceXL};
`;

export const SkeletonButton = styled(InternalSkeletonButton)`
  width: 100px;
  height: ${spaceXL};
`;
