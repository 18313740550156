import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { PostingPublicationState } from '@xing-com/crate-common-graphql-types';
import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { IconReaction } from '@xing-com/icons';

import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';
import * as Styled from './news-item-actions.styles';

export const ActionPromote: FC = () => {
  const { $t } = useIntl();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const { post, publicationState } = useNewsItemContext();

  const attachment =
    post.object?.__typename === 'EntityPageContentFeedPostingPost'
      ? post.object.attachment
      : {};
  const attachmentType = attachment?.__typename || '';

  const hasLink =
    isEditing &&
    publicationState === PostingPublicationState.Published &&
    (attachmentType === 'PostingsImageAttachment' ||
      attachmentType === 'PostingsLinkAttachment');

  const xamUrl = hasLink
    ? `${window.location.origin}/xam/aac?entity_urn=${pageContext?.globalId}&asset_urn=${post.globalId}&sc_o=entity_page_news_module_promote`
    : '';

  const disabled = !hasLink;

  return (
    <Styled.PromoteButton
      icon={IconReaction}
      ariaLabel="Reaction"
      data-testid="PROMOTE_POSTING"
      variant="primary"
      size="small"
      disabled={disabled}
      href={xamUrl}
      // @ts-expect-error FIXME: SC6
      target="_blank"
    >
      <>{$t({ id: `NEWS_MODULE_PROMOTE_BUTTON` })}</>
    </Styled.PromoteButton>
  );
};
