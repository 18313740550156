// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { kununuLogo } from '@xing-com/crate-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';
import { StarRating } from '@xing-com/star-rating';

import * as Styled from './company-recommendations.styles';

const shouldRenderKununu = (kununuRating?: number): boolean =>
  !!kununuRating && kununuRating > 0;

type Props = {
  profileName: string;
  numberOfRatings: number;
  link?: string;
  backgroundImageUrl?: string;
  profileImage?: string;
  kununuRating?: number;
};

const CompanyRecommendationCard: React.FC<Props> = ({
  profileImage,
  backgroundImageUrl,
  profileName,
  numberOfRatings,
  kununuRating,
  link,
}: Props) => {
  const intl = useIntl();

  return (
    <Styled.CardWrapper>
      <Styled.Card
        href={getTrackingUrl({
          to: link,
          trackingKey: 'viewCompanyRecommendation',
        })}
        linkProps={{
          'aria-label': profileName + ' link',
        }}
      >
        <Styled.ProfileBackground
          style={
            backgroundImageUrl
              ? { backgroundImage: `url(${backgroundImageUrl}) ` }
              : {}
          }
        >
          <Styled.ProfileImage
            size="large"
            type="company"
            profileName="xing"
            src={profileImage}
          />
        </Styled.ProfileBackground>
        <Styled.Headline fontWeight="bold" lineHeight="dense" size={'large'}>
          {profileName}
        </Styled.Headline>
        {shouldRenderKununu(kununuRating) && (
          <>
            <Styled.Body className={Styled.Body}>
              <FormattedMessage
                id="CP_KUNUNU_RATED_BY"
                values={{
                  kununu_ratings_num: intl.formatNumber(numberOfRatings),
                }}
              />
              <Styled.KununuImg src={kununuLogo} alt="Kununu" />
            </Styled.Body>
            <StarRating rating={kununuRating} />
          </>
        )}
      </Styled.Card>
    </Styled.CardWrapper>
  );
};

export default CompanyRecommendationCard;
