import Dotdotdot from 'react-dotdotdot';
import styled from 'styled-components';

import '@xing-com/layout-tokens';

import { Card as InnerCard } from '@xing-com/card';
import { ProfileInfoAdapter as OriginalProfileInfo } from '@xing-com/crate-entity-pages-common';
import type { ProfileInfoAdapterProps } from '@xing-com/crate-entity-pages-common/src/components/profile-info-adapter/profile-info-adapter';
import { lineClamp } from '@xing-com/crate-utils-line-clamp';
import { Link as OriginalLink } from '@xing-com/link';
import {
  space4XL,
  spaceL,
  spaceM,
  spaceS,
  spaceXS,
  spaceXXL,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import cardBlurImage from '../../assets/card-blur.png';

export const Card = styled(InnerCard)`
  width: 100%;
  height: 100%;
  & > div {
    height: 100%;
  }
`;

export const SkeletonCard = styled(Card)`
  padding: 10px;

  svg {
    width: 100%;
  }
`;

export const Name = styled.div`
  margin-bottom: ${spaceXS};
  margin-top: ${spaceL};
`;

export const Role = styled.div`
  margin-bottom: ${spaceXXL};
  margin-top: 0;
`;

export const RegisterText = styled(BodyCopy)`
  margin-bottom: ${space4XL};
  color: #1d2124;
`;

export const CardBlurBackground = styled(InnerCard)`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  background: url(${cardBlurImage});
  background-position: center;
  background-size: cover;
  border: none;
  padding: ${spaceL};
`;

export const Headline = styled(Dotdotdot)`
  margin-top: ${spaceS};
  display: block;
  white-space: nowrap;
`;

export const Flag = styled.div`
  text-transform: capitalize;
`;

export const ButtonsContainer = styled.div`
  margin-top: ${spaceM};
  .employees-button {
    width: 100%;
  }
`;

export const Link = styled(OriginalLink)`
  color: inherit;
  display: block;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const ProfileInfo: React.FC<ProfileInfoAdapterProps> = styled(
  OriginalProfileInfo
)`
  height: 100%;
`;

export const TextBody = styled(Dotdotdot)`
  ${lineClamp(1)}
`;
