import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import type { EntityMedia } from '@xing-com/crate-common-graphql-types';
import {
  useEditContext,
  SmallGrid,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { trackOpenGallery } from '@xing-com/crate-entity-pages-common/src/tracking';
import { IconVideo } from '@xing-com/icons';
import { OmView } from '@xing-com/platform-layout-om'; // eslint-disable-line
import Video from '@xing-com/video';

import type { ItemType } from '../../../types';
import { GalleryEditButton } from '../../about-us-edit/gallery-edit/gallery-edit-button/gallery-edit-button';
import { GalleryContainer } from '../../gallery/gallery-container';
import * as Styled from './gallery-preview.styles';

const ExternalIcon: FC = () => (
  <Styled.ExternalIcon>
    <IconVideo width={24} height={24} />
  </Styled.ExternalIcon>
);

type MediaProps = {
  imageUrl?: string;
  videoId?: string;
  videoButtonSize?: number;
  externalUrl?: string;
};
const Media: FC<MediaProps> = ({
  imageUrl,
  videoId,
  videoButtonSize,
  externalUrl,
}) => {
  // used to set images to 100% width on first render and visibility none
  // this fixes SSR first render issue of setting the Carousel with a bigger width due to big images
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  return (
    <>
      {videoId && (
        <Video
          id={videoId}
          onError={() => undefined}
          previewModeAction={() => undefined}
          previewModeButtonSize={videoButtonSize}
          section={'entity_pages_subpage_preview_gallery'}
        />
      )}
      {externalUrl && <ExternalIcon />}
      {imageUrl && (
        <>
          <Styled.BlurredImageWrapper>
            <Styled.Gradient />
            <Styled.BlurredImage
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
          </Styled.BlurredImageWrapper>
          <Styled.Image
            src={imageUrl}
            firstRender={firstRender} // Pass the firstRender prop
            loading="lazy"
            alt="Gallery image"
          />
        </>
      )}
    </>
  );
};

type CarouselContainerProps = {
  galleryItems: Partial<EntityMedia>[];
  setItemClicked: (index: number) => void;
};
const CarouselContainer: FC<CarouselContainerProps> = ({
  galleryItems,
  setItemClicked,
}) => {
  return (
    <Styled.SliderPreviewMediaWrapper>
      <Styled.Carousel>
        {galleryItems.map((item: ItemType, index: number) => (
          <Styled.SliderItemWrapper key={index}>
            <Styled.SliderItem
              onClick={() => {
                setItemClicked(index);
              }}
              data-testid="gallery-item"
            >
              <Media
                imageUrl={item?.media?.url}
                videoId={item?.media?.videoReferenceV2}
                videoButtonSize={64}
                externalUrl={item?.media?.externalLink}
              />
            </Styled.SliderItem>
          </Styled.SliderItemWrapper>
        ))}
      </Styled.Carousel>
    </Styled.SliderPreviewMediaWrapper>
  );
};

type GalleryPreviewProps = {
  pageSlug: string;
  galleryItems: Partial<EntityMedia>[];
};
export const GalleryPreview: FC<GalleryPreviewProps> = ({
  pageSlug,
  galleryItems,
}) => {
  const location = useLocation();
  const locationParams = new URLSearchParams(location.search);
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};
  const isFreePage = pageContext?.contractType === 'FREE';

  const openOmView = useRef<any | undefined>();
  const closeOmView = useRef<any | undefined>();

  const openGallery = locationParams.get('action') === 'openGallery';
  const locationSearchItem = locationParams.get('item');

  const [itemClicked, setItemClicked] = useState<any>(
    openGallery && locationSearchItem ? parseInt(locationSearchItem) : null
  );

  useEffect(() => {
    if (openGallery) {
      trackOpenGallery('entity_page_subpage');
      openOmView.current && openOmView.current();
    }
  }, [openOmView]);
  useEffect(() => {
    return () => closeOmView.current && closeOmView.current();
  }, []);

  return (
    <>
      {isEditing &&
        galleryItems.findIndex((item: ItemType) => item?.media?.externalLink) >=
          0 && (
          <Styled.StatusBanner variant="info" display="inline">
            <FormattedMessage
              id="EP_EDIT_ABOUTUS_EXTERNALVIDEO_INFO"
              defaultMessage="EP_EDIT_ABOUTUS_EXTERNALVIDEO_INFO"
            />
          </Styled.StatusBanner>
        )}
      <Styled.GalleryPreviewContainer>
        {isEditing && !isFreePage && <GalleryEditButton />}
        <OmView
          onClose={() => {
            setItemClicked(undefined);
          }}
          trigger={(activateOmView: any) => {
            openOmView.current = activateOmView;
            return (
              <CarouselContainer
                setItemClicked={(item) => {
                  setItemClicked(item);
                  openOmView.current && openOmView.current();
                }}
                galleryItems={galleryItems}
              />
            );
          }}
        >
          {({ handleClose }) => {
            closeOmView.current = handleClose;
            return (
              <Styled.OmViewSpan>
                <SmallGrid>
                  <GalleryContainer
                    pageSlug={pageSlug}
                    itemClicked={itemClicked}
                  />
                </SmallGrid>
              </Styled.OmViewSpan>
            );
          }}
        </OmView>
      </Styled.GalleryPreviewContainer>
    </>
  );
};
export default GalleryPreview;
