import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  getMarketingPageUrl,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { BodyCopy } from '@xing-com/typography';

import * as Styled from './news-premium-banner.styles';

type NewsPremiumBannerProps = {
  buttonTextKey?: string;
  className?: string;
  flagTextKey?: string;
  headlineCopyKey: string;
  href?: string;
  moduleName: string;
  testId?: string;
  textCopyKey: string;
};
export const NewsPremiumBanner: FC<NewsPremiumBannerProps> = ({
  buttonTextKey,
  flagTextKey,
  headlineCopyKey,
  href,
  moduleName,
  testId,
  textCopyKey,
}) => {
  const { locale } = useIntl();
  const { pageContext } = usePageContext() ?? {};

  if (href && href.length) {
    href = getMarketingPageUrl({
      locale,
      focusType: pageContext?.focusType,
      moduleName,
      component: 'teaser',
    });
  }

  return (
    <Styled.PremiumWrapper data-testid={testId}>
      <>
        <Styled.TextWrapper>
          {flagTextKey && (
            <Styled.Flag>
              <FormattedMessage id={flagTextKey} defaultMessage={flagTextKey} />
            </Styled.Flag>
          )}
          <Styled.HeaderText size={'large'}>
            <FormattedMessage
              id={headlineCopyKey}
              defaultMessage={headlineCopyKey}
            />
          </Styled.HeaderText>
          <BodyCopy noMargin size={'small'}>
            <FormattedMessage id={textCopyKey} defaultMessage={textCopyKey} />
          </BodyCopy>
        </Styled.TextWrapper>
        {href && (
          <Styled.Button
            href={href}
            rel="noopener nofollow"
            size={'medium'}
            // @ts-expect-error FIXME: SC6
            target="_blank"
            variant="proBusiness"
          >
            <FormattedMessage
              id={buttonTextKey}
              defaultMessage={buttonTextKey}
            />
          </Styled.Button>
        )}
      </>
    </Styled.PremiumWrapper>
  );
};
