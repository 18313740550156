import { useEffect, useRef, type FC } from 'react';
import { useIntl } from 'react-intl';

import { formatAmount } from '@xing-com/crate-entity-pages-common';
import { OmView } from '@xing-com/platform-layout-om'; // eslint-disable-line

import type { SocialProofUsersType } from '../../../types/types';
import SocialProofList from '../social-proof-list';
import * as Styled from './social-proof-text.styles';

const getTranslationKey = (proofType: string, total: number) => {
  const variant = total === 1 ? 'one' : 'other';

  return proofType === 'EMPLOYEES'
    ? `EP_COMPANY_SOCIAL_PROOF.${variant}`
    : `EP_FOLLOWERS_SOCIAL_PROOF.${variant}`;
};

type SocialProofTextForOneProps = {
  users: SocialProofUsersType[];
  proofType: string;
};
const SocialProofTextForOne: FC<SocialProofTextForOneProps> = ({
  users,
  proofType,
}) => {
  const intl = useIntl();

  return (
    <Styled.AnchorText href={users[0].href}>
      <Styled.NoMarginBodyCopy
        fontWeight="bold"
        lineHeight="dense"
        size={'small'}
        sizeWide="medium"
      >
        {intl.formatMessage(
          { id: getTranslationKey(proofType, 1) },
          { username: users[0].profileName, numberOfContacts: 1 }
        )}
      </Styled.NoMarginBodyCopy>
    </Styled.AnchorText>
  );
};

type SocialProofTextForMultipleProps = {
  total: number;
  proofType: string;
};
const SocialProofTextForMultiple: FC<SocialProofTextForMultipleProps> = ({
  total,
  proofType,
}) => {
  const intl = useIntl();
  const closeOmView = useRef<() => void | null>();

  const socialProofString = intl.formatMessage(
    {
      id: getTranslationKey(
        proofType,
        parseInt(formatAmount({ value: total, intl }))
      ),
    },
    { numberOfContacts: total }
  );

  useEffect(() => {
    return () => {
      closeOmView.current && closeOmView.current();
    };
  }, []);

  return (
    <OmView
      trigger={(activateOmView: () => void) => (
        <Styled.TextButton
          data-testid={'SOCIAL_PROOF_LIST_BUTTON'}
          onClick={activateOmView}
        >
          <Styled.NoMarginBodyCopy
            fontWeight="bold"
            lineHeight="dense"
            size={'small'}
            sizeWide="medium"
          >
            {socialProofString}
          </Styled.NoMarginBodyCopy>
        </Styled.TextButton>
      )}
    >
      {({ handleClose }: { handleClose: () => void }) => {
        closeOmView.current = handleClose;
        return <SocialProofList listTitle={socialProofString} />;
      }}
    </OmView>
  );
};

type SocialProofTextProps = {
  users: SocialProofUsersType[];
  total: number;
  proofType: string;
};
const SocialProofText: FC<SocialProofTextProps> = ({
  users,
  total,
  proofType,
}) => (
  <>
    {total === 1 && (
      <SocialProofTextForOne users={users} proofType={proofType} />
    )}
    {total > 1 && (
      <SocialProofTextForMultiple total={total} proofType={proofType} />
    )}
  </>
);

export default SocialProofText;
