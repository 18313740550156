import { useMutation } from '@apollo/client';
import type React from 'react';

import { useErrorContext } from '@xing-com/crate-entity-pages-common';
import {
  trackJobsBookmarkAction,
  trackJobsSubpageBookmarkAction,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { JobBookmarkDocument } from '../../graphql/mutations/job-bookmark-mutation.gql-types';
import { JobUnbookmarkDocument } from '../../graphql/mutations/job-unbookmark-mutation.gql-types';
import { JobsBookmark } from './jobs-bookmark';

type JobsBookmarkContainerProps = {
  jobId: string;
  bookmarked: boolean;
  isSubpage?: boolean;
  onCreateBookmarkCompleted?: () => void;
  onDeleteBookmarkCompleted?: () => void;
};

export const JobsBookmarkContainer: React.FC<JobsBookmarkContainerProps> = ({
  jobId,
  bookmarked,
  isSubpage,
  onCreateBookmarkCompleted = () => undefined,
  onDeleteBookmarkCompleted = () => undefined,
}) => {
  const { isLoggedIn } = useLoginState();

  const { showError } = useErrorContext();
  const [
    createBookmark,
    {
      error: createBookmarkError,
      loading: createBookmarkLoading,
      data: createBookmarkData,
    },
  ] = useMutation(JobBookmarkDocument);

  const [
    deleteBookmark,
    {
      error: deleteBookmarkError,
      loading: deleteBookmarkLoading,
      data: deleteBookmarkData,
    },
  ] = useMutation(JobUnbookmarkDocument);

  const handleCreateBookmark = () => {
    createBookmark({
      variables: { jobId },
      onCompleted: () => {
        onCreateBookmarkCompleted();
      },
    });
  };
  const handleDeleteBookmark = () => {
    deleteBookmark({
      variables: { jobId },
      onCompleted: () => {
        onDeleteBookmarkCompleted();
      },
    });
  };

  if (!isLoggedIn) return null;

  return (
    <JobsBookmark
      createBookmark={handleCreateBookmark}
      deleteBookmark={handleDeleteBookmark}
      loading={Boolean(createBookmarkLoading || deleteBookmarkLoading)}
      error={{
        showError: Boolean(
          createBookmarkError ||
            deleteBookmarkError ||
            deleteBookmarkData?.jobDeleteBookmark?.error ||
            createBookmarkData?.jobCreateBookmark?.error
        ),
        onError: () =>
          showError({
            message: 'EP_JOBS_FEEDBACK_ERROR',
            // @ts-expect-error TODO: fix this
            error:
              deleteBookmarkError ||
              createBookmarkError ||
              deleteBookmarkData?.jobDeleteBookmark?.error ||
              createBookmarkData?.jobCreateBookmark?.error,
          }),
      }}
      initialBookmark={bookmarked}
      trackingAction={(isEditor, bookmarked) => {
        if (isSubpage) {
          trackJobsSubpageBookmarkAction(isEditor, bookmarked);
        } else {
          trackJobsBookmarkAction(isEditor, bookmarked);
        }
      }}
    />
  );
};
