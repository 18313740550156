import { useState, type FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { getImageGender } from '@xing-com/crate-entity-pages-common';
import { IconInfo } from '@xing-com/icons';
import { BREAKPOINTS, useMediaListener } from '@xing-com/util';

import { useContactsEditContext } from '../../hooks/use-contacts-edit-context/use-contacts-edit-context';
import type { ContactEdit } from '../../types/contacts-edit';
import { ContactsEditCardActions } from '../contacts-edit-card-actions/contacts-edit-card-actions';
import { ContactEditCardForm } from './contact-edit-card-form';
import * as Styled from './contacts-edit-card.styles';

type ContactsEditCardProps = {
  contactData: ContactEdit;
  itemChoosed: boolean;
};
export const ContactsEditCard: FC<ContactsEditCardProps> = ({
  contactData,
  itemChoosed,
}) => {
  const { $t } = useIntl();
  const { setDisablePageActions } = useContactsEditContext();
  const breakpoint = useMediaListener();
  const isSmallScreen = breakpoint === BREAKPOINTS.handheld;

  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);

  return (
    <Styled.Tag
      data-testid={`CONTACTS-EDIT-CARD-${contactData.contact.id}`}
      as={contactData.enabled && !contactData.deleted ? 'div' : 'span'}
      $isBeingEdited={isBeingEdited}
      // $isInactive={isBeingEdited}
      $isDeleted={contactData.deleted}
    >
      <>
        {isBeingEdited ? (
          <ContactEditCardForm
            contactData={contactData}
            onClose={() => {
              setIsBeingEdited(false);
              setDisablePageActions(false);
            }}
            onChangeLabel={() => {
              setIsBeingEdited(false);
              setDisablePageActions(false);
            }}
          />
        ) : (
          <Styled.ProfileInfoActionsWrapper>
            <Styled.ContactsProfileInfo
              alignment={isSmallScreen ? 'vertical' : 'horizontal'}
              profileUrl={`/profile/${contactData.contact.xingId?.pageName}`}
              profileImage={{
                size: isSmallScreen ? 'small' : 'medium',
                src: contactData.contact.xingId?.profileImage?.[0]?.url,
                profileName: 'user profile image',
                type: getImageGender(contactData.contact.xingId?.gender),
                // @ts-expect-error ignore
                key: contactData.contact.id,
              }}
              headline={{
                size: 'medium',
                children: contactData.contact.xingId?.displayName,
                as: 'strong',
              }}
              isDisabled={!contactData.contact.enabled}
              itemChoosed={itemChoosed}
              textBody={[
                {
                  size: 'medium',
                  children:
                    contactData.contact.xingId?.occupations?.[0]?.subline,
                },
              ]}
              textMeta={[
                {
                  size: 'medium',
                  children: contactData.contact.label,
                },
              ]}
            />
            <Styled.ItemsActionWrapper>
              <ContactsEditCardActions
                contactData={contactData}
                disableAllActions={isBeingEdited}
                onEditItem={() => {
                  setIsBeingEdited(true);
                  setDisablePageActions(true);
                }}
              />
            </Styled.ItemsActionWrapper>
          </Styled.ProfileInfoActionsWrapper>
        )}
        {(contactData.default || contactData.deleted) && (
          <Styled.OwnerDisclaimerWrapper
            data-testid="CONTACTS-EDIT-CARD-OWNER-DISCLAIMER"
            isDisabled={!contactData.enabled}
          >
            <IconInfo width={16} height={16} />
            <Styled.OwnerMeta size={'small'}>
              {contactData.deleted ? (
                $t({ id: 'EP_EDIT_CONTACTS_DELETED' })
              ) : (
                <span>
                  <FormattedMessage
                    id="EP_CONTACTS_CHANGE_OWNER_INFO_V2"
                    values={{
                      a: () => (
                        <a href={'mailto:customer.support@xing.com'}>
                          customer.support@xing.com
                        </a>
                      ),
                    }}
                  />
                </span>
              )}
            </Styled.OwnerMeta>
          </Styled.OwnerDisclaimerWrapper>
        )}
      </>
    </Styled.Tag>
  );
};
