import type { FC } from 'react';
import { useIntl } from 'react-intl';

import type { ImageUploadOnPublishData } from '@xing-com/crate-entity-pages-common';
import {
  ExternalEditInfoBanner,
  ImageUpload,
  useDialogContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { trackUpsellBannerForward } from '@xing-com/crate-entity-pages-common/src/tracking';

import { useUpsellBannerContext } from '../../../../hooks/use-upsell-banner-context/use-upsell-banner-context';
import { UpdateCover } from '../update-cover';

type UpsellBannerUploadImageProps = {
  onDiscard?: () => void;
  onCancel?: () => void;
  onDragCrop?: () => void;
  onCropReady?: () => void;
};
export const UpsellBannerUploadImage: FC<UpsellBannerUploadImageProps> = ({
  onDiscard = () => undefined,
  onCancel = () => undefined,
  onDragCrop = () => undefined,
  onCropReady = () => undefined,
}) => {
  const { $t } = useIntl();
  const { pageContext } = usePageContext() ?? {};
  const { nextStep, isEditCrop, setUpsellCover } = useUpsellBannerContext();
  const { setDataChanged } = useDialogContext();

  const handleOnUpdateCover = async ({
    cropped,
    original,
  }: ImageUploadOnPublishData) => {
    if (pageContext?.focusType && pageContext?.pageId) {
      trackUpsellBannerForward({
        focusType: pageContext?.focusType,
        itemId: pageContext?.pageId,
      });
    }

    setUpsellCover({ cropped, original });
    nextStep();
  };

  return (
    <div>
      {isEditCrop ? (
        <UpdateCover
          imageType="cover"
          aspectRatio={8 / 2}
          isCover={true}
          descriptionCopyKey="EP_COVER_CROP_DESCRIPTION"
          onPublish={handleOnUpdateCover}
          onCancel={onCancel}
          onDiscard={onDiscard}
          onDragCrop={onDragCrop}
          onCropReady={onCropReady}
          imageUploadCopy={{
            hint: $t({ id: 'EP_UPLOAD_IMAGE_HINT' }),
            label: $t({ id: 'EP_UPLOAD_COVER_LABEL' }),
            onDragLabel: $t({ id: 'EP_UPLOAD_IMAGE_DRAG_LABEL' }),
            discardButton: $t({
              id: 'EP_UPSELL_BANNER_CONTAINER_DISCARD_BUTTON',
            }),
            publishButton: $t({
              id: 'EP_UPSELL_BANNER_CONTAINER_PUBLISH_BUTTON',
            }),
          }}
        />
      ) : (
        <ImageUpload
          shouldUploadOriginal={true}
          aspectRatio={8 / 2}
          descriptionCopyKey="EP_COVER_CROP_DESCRIPTION"
          onPublish={handleOnUpdateCover}
          onDiscard={onDiscard}
          onCropReady={onCropReady}
          onFileSelected={() => setDataChanged(true)}
          imageUploadCopy={{
            headline: $t({ id: 'EP_CHOOSE_COVER' }),
            hint: $t({ id: 'EP_UPLOAD_IMAGE_HINT' }),
            label: $t({ id: 'EP_UPLOAD_COVER_LABEL' }),
            onDragLabel: $t({ id: 'EP_UPLOAD_IMAGE_DRAG_LABEL' }),
            discardButton: $t({
              id: 'EP_UPSELL_BANNER_CONTAINER_DISCARD_BUTTON',
            }),
            publishButton: $t({
              id: 'EP_UPSELL_BANNER_CONTAINER_PUBLISH_BUTTON',
            }),
          }}
        />
      )}
      <ExternalEditInfoBanner
        copyKey="EP_CHOOSE_COVER_INFO_BANNER"
        ctaUrl={`https://help.onlyfy.com/hc/de/articles/8803872010001-Erstbef%C3%BCllung-des-Employer-Branding-Profils-auf-XING#titel--/-headerbild`}
        ctaCopyKey="EP_CHOOSE_COVER_INFO_BANNER_CTA"
      />
    </div>
  );
};
