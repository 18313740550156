import type React from 'react';
import type { FC } from 'react';
import { useState } from 'react';

import {
  DialogContextProvider,
  TruncatedText,
  useEditContext,
} from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { NewsItemContext } from '../../contexts/news-item-context/news-item-context';
import type { EntityPageFeedCollectionFragment } from '../../graphql/fragments/entity-page-feed-collection-fragment.gql-types';
import { NewsItemActions } from './news-item-actions/news-item-actions';
import { NewsItemActor } from './news-item-actor/news-item-actor';
import { NewsItemAttachment } from './news-item-attachment/news-item-attachment';
import { NewsItemSocial } from './news-item-social/news-item-social';
import { NewsItemSkeleton } from './news-item.skeleton';
import * as Styled from './news-item.styles';

type NewsItemProps = {
  feedUpdate: EntityPageFeedCollectionFragment;
  showActor: boolean;
  videoPlaying: React.RefObject<any>;
  onItemActionCompleted?: (action: 'edit' | 'pin' | 'hide' | 'delete') => void;
  onItemActionStarted?: (action: 'edit' | 'pin' | 'hide' | 'delete') => void;
};
export const NewsItem: FC<NewsItemProps> & {
  Skeleton: typeof NewsItemSkeleton;
} = ({
  feedUpdate: post,
  showActor = true,
  videoPlaying,
  onItemActionCompleted = () => undefined,
  onItemActionStarted = () => undefined,
}) => {
  const { isLoggedIn } = useLoginState();
  const { isEditing } = useEditContext();

  const [isItemLoading, setIsItemLoading] = useState(false);

  const postType = post.object?.__typename;

  if (postType === 'EntityPageContentFeedPlainTextPostingPost') {
    return null;
  }

  const publicationState =
    post.object?.__typename === 'EntityPageContentFeedPostingPost'
      ? post.object.publicationState
      : undefined;

  const comment =
    post.object?.__typename === 'EntityPageContentFeedArticlesArticlePost'
      ? undefined
      : post.object?.comment;

  const Comment = showActor ? Styled.Comment : Styled.NoActorComment;

  return (
    <NewsItemContext.Provider
      value={{
        post: post,
        publicationState,
      }}
    >
      <Styled.Item
        $isItemLoading={isItemLoading}
        data-testid={`NEWS-ITEM-${post.object?.globalId}`}
      >
        <Styled.ItemActor $isNoActor={!!isEditing && !showActor}>
          <>
            <NewsItemActor />
            {isLoggedIn && isEditing && postType && (
              <DialogContextProvider>
                <NewsItemActions
                  onItemActionCompleted={(action) => {
                    setIsItemLoading(false);
                    onItemActionCompleted(action);
                  }}
                  onItemActionStarted={(action) => {
                    setIsItemLoading(true);
                    onItemActionStarted(action);
                  }}
                />
              </DialogContextProvider>
            )}
          </>
        </Styled.ItemActor>

        {comment && (
          <Comment>
            <TruncatedText limit={500}>{comment}</TruncatedText>
          </Comment>
        )}

        <NewsItemAttachment videoPlaying={videoPlaying} />

        {post.globalId && post.metadata && <NewsItemSocial />}
      </Styled.Item>
    </NewsItemContext.Provider>
  );
};

NewsItem.Skeleton = NewsItemSkeleton;
