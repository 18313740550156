import {
  useMutation,
  type ApolloCache,
  type ApolloError,
} from '@apollo/client';
import type { FC } from 'react';
import { useState } from 'react';

import type {
  EntityPageQuery,
  EntitySubpageQuery,
} from '@xing-com/crate-entity-pages-common';
import {
  redirectToLogin,
  useLoggedinAction,
  EntityPageDocument,
  EntitySubpageDocument,
  usePageContext,
  getEntityPageQueryVariables,
  getEntitySubpageQueryVariables,
  useErrorContext,
} from '@xing-com/crate-entity-pages-common';
import { trackEntityPageFollowAction } from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { EntityPageFollowDocument } from '../../../graphql/mutations/entity-page-follow-mutation.gql-types';
import { EntityPageUnfollowDocument } from '../../../graphql/mutations/entity-page-unfollow-mutation.gql-types';
import { Follow } from './follow';
import FollowActionConfirmation from './follow-action-confirmation';

type FollowContainerProps = {
  onCompleted?: () => void;
  className?: string;
};

export const FollowContainer: FC<FollowContainerProps> = ({ ...props }) => {
  const { isLoggedIn } = useLoginState();
  const { showError } = useErrorContext();
  const { pageContext } = usePageContext() ?? {};

  const [followed, setFollowed] = useState(pageContext?.isFollowingPage);
  const [showFollowConfirmationDialog, setShowFollowConfirmationDialog] =
    useState(false);

  const followedId = pageContext?.pageId as string;

  const handleCache = (cache: ApolloCache<any>, follow: boolean) => {
    const dataMainPage: EntityPageQuery | null = cache.readQuery({
      query: EntityPageDocument,
      // @ts-expect-error TODO: fix this type
      variables: getEntityPageQueryVariables(pageContext?.pageSlug),
    });

    if (
      dataMainPage?.entityPageEX?.__typename === 'EntityPage' &&
      dataMainPage?.entityPageEX?.modules?.collection
    ) {
      const [header, ...rest] = dataMainPage.entityPageEX.modules.collection;
      // @ts-expect-error TODO: fix this type
      const followers = header?.content?.followers;
      const newModulesCollection = [
        {
          ...header,
          content: {
            ...(header?.__typename === 'EntityPageHeaderModule' &&
              header.content),
            followers: {
              ...followers,
              total: (followers ? followers?.total : 0) + (follow ? 1 : -1),
            },
          },
        },
        ...rest,
      ];

      cache.writeQuery({
        query: EntityPageDocument,
        // @ts-expect-error TODO: fix this type
        variables: getEntityPageQueryVariables(pageContext?.pageSlug),
        data: {
          entityPageEX: {
            ...dataMainPage.entityPageEX,
            modules: {
              // @ts-expect-error TODO: fix this type
              collection: newModulesCollection,
              __typename: 'EntityPageModules',
            },
            // @ts-expect-error TODO: fix this type
            userPageContext: {
              ...dataMainPage.entityPageEX.userPageContext,
              userInteractions: {
                followState: {
                  isFollowing: follow,
                  __typename: 'FollowContext',
                },
                __typename: 'EntityPageUserInteractionFollow',
              },
            },
          },
        },
      });
    }

    const dataSubpage: EntitySubpageQuery | null = cache.readQuery({
      query: EntitySubpageDocument,
      // @ts-expect-error TODO: fix this type
      variables: getEntitySubpageQueryVariables(pageContext?.pageSlug),
    });

    if (dataSubpage?.entityPageEX) {
      cache.writeQuery({
        query: EntitySubpageDocument,
        // @ts-expect-error TODO: fix this type
        variables: getEntitySubpageQueryVariables(pageContext?.pageSlug),
        data: {
          entityPageEX: {
            ...dataSubpage.entityPageEX,
            // @ts-expect-error TODO: fix this type
            userPageContext: {
              ...(dataSubpage.entityPageEX.__typename === 'EntityPage' &&
                dataSubpage.entityPageEX.userPageContext),
              userInteractions: {
                followState: {
                  isFollowing: follow,
                  __typename: 'FollowContext',
                },
                __typename: 'EntityPageUserInteractionFollow',
              },
            },
          },
        },
      });
    }
  };

  const [followPage, { loading: followPageLoading }] = useMutation(
    EntityPageFollowDocument,
    {
      onCompleted: () => trackEntityPageFollowAction({ hasFollowed: true }),
      onError: (error) => toggleFollow(error),
      update: (cache) => handleCache(cache, true),
    }
  );

  const [unfollowPage, { loading: unfollowPageLoading }] = useMutation(
    EntityPageUnfollowDocument,
    {
      onCompleted: () => trackEntityPageFollowAction({ hasFollowed: false }),
      onError: (error) => toggleFollow(error),
      update: (cache) => handleCache(cache, false),
    }
  );

  const toggleFollow = (error?: ApolloError) => {
    if (!isLoggedIn) {
      redirectToLogin('followEntityPage');
    } else {
      setFollowed(!followed);
      if (error) {
        trackEntityPageFollowAction({ hasFollowed: !!followed, error: true });
        showError({
          message: 'EP_JOBS_FEEDBACK_ERROR',
          error: error.message,
        });
      } else {
        followedId &&
          (followed
            ? unfollowPage({ variables: { followedId } })
            : followPage({ variables: { followedId } }));
      }
    }
  };

  const toggleFollowWithDialog = () => {
    setTimeout(() => setShowFollowConfirmationDialog(true), 1000);
  };

  useLoggedinAction(toggleFollow, 'followEntityPage', !!followed);
  useLoggedinAction(toggleFollowWithDialog, 'followConfirm', false);

  return (
    <>
      <FollowActionConfirmation
        show={showFollowConfirmationDialog}
        setShow={setShowFollowConfirmationDialog}
        onConfirm={toggleFollow}
      />
      <Follow
        loading={Boolean(followPageLoading || unfollowPageLoading)}
        toggleFollow={toggleFollow}
        isFollowing={!!followed}
        {...props}
      />
    </>
  );
};
