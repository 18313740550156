import styled from 'styled-components';

import { PagesFormField } from '@xing-com/crate-entity-pages-common';
import { spaceM, spaceXS } from '@xing-com/tokens';

// .field {
//   margin-top: var(--spaceXXL);
// }

export const FieldGroup = styled.div`
  display: flex;

  @media (max-width: 739px) {
    display: block;
  }
`;

export const PagesFormFieldZipcode = styled(PagesFormField)`
  flex: 1;
  margin-right: ${spaceM};

  @media (max-width: 739px) {
    margin-right: 0;
  }
`;

export const PagesFormFieldCity = styled(PagesFormField)`
  flex: 2;
`;

export const PagesFormFieldPhonePrefix = styled(PagesFormField)`
  font-size: 16px;
  width: 114px;
  margin-right: ${spaceXS};
  align-self: flex-start;
  display: flex;

  & select {
    height: 48px;
  }

  @media (max-width: 739px) {
    width: 100%;
    margin-right: 0;
    display: block;
  }
`;
export const PagesFormFieldFaxPrefix = styled(PagesFormField)`
  font-size: 16px;
  width: 114px;
  margin-right: ${spaceXS};
  align-self: flex-start;
  display: flex;

  & select {
    height: 48px;
  }

  @media (max-width: 739px) {
    width: 100%;
    margin-right: 0;
    display: block;
  }
`;

export const PagesFormFieldPhone = styled(PagesFormField)`
  flex: 1;
  margin-right: ${spaceM};

  @media (max-width: 739px) {
    margin-right: 0;
  }
`;

export const PagesFormFieldFax = styled(PagesFormField)`
  flex: 1;
`;
