import { useQuery } from '@apollo/client';
import { useState } from 'react';
import type React from 'react';

import { WarningMessageBox as Error } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { JobsFilter } from '../components/jobs-filter/jobs-filter';
import { JobsDetailDocument } from '../graphql/queries/jobs-detail-query.gql-types';
import type {
  JobsDetailQuery,
  JobsDetailQueryVariables,
} from '../graphql/queries/jobs-detail-query.gql-types';
import type { FilterStateSelectedItemsType } from '../types';
import { JobsDetail } from './jobs-detail';

/** The error flag of React Apollo is not reliable when using SSR, therefore we check if there are errors like this  */
const isError = (data: JobsDetailQuery | undefined, loading: boolean) =>
  !data?.jobSearchByQuery?.collection && !loading;

type RenderErrorProps = {
  refetch: () => void;
};
const RenderError: React.FC<RenderErrorProps> = ({ refetch }) => (
  <div data-testid={'errorContainer'}>
    <Error
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

const LIST_LIMIT = 10;

const LIST_SORT = 'date/desc';

type JobsDetailContainerProps = {
  companyId: string;
};
export const JobsDetailContainer: React.FC<JobsDetailContainerProps> = ({
  companyId,
}) => {
  const { isLoggedIn } = useLoginState();

  const [offset, setOffset] = useState<number>(LIST_LIMIT);

  const jobsQueryVariables = {
    limit: LIST_LIMIT,
    sort: LIST_SORT,
    query: {
      filter: {
        company: { id: [companyId] },
        publishToCompany: null,
      },
    },
  };

  const { data, loading, refetch, fetchMore, networkStatus } = useQuery(
    JobsDetailDocument,
    {
      variables: jobsQueryVariables,
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadingFirstRender = !data && loading && networkStatus !== 3;

  const handlePagination = async () => {
    await fetchMore({
      variables: {
        first: LIST_LIMIT,
        offset,
      },
      // @ts-expect-error TODO: fix this type
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          jobSearchByQuery: {
            ...prev.jobSearchByQuery,
            collection: [
              ...(prev.jobSearchByQuery?.collection || []),
              ...(fetchMoreResult.jobSearchByQuery?.collection || []),
            ],
          },
        };
      },
    });

    setOffset((offset: number) => offset + LIST_LIMIT);
  };
  const handleFilterChange = async (
    selectedItems: FilterStateSelectedItemsType,
    keywords: string
  ) => {
    const variables: JobsDetailQueryVariables = {
      query: {
        filter: {
          company: { id: [companyId] },
        },
      },
    };

    if (keywords) {
      variables.query.keywords = keywords;
    }
    if (variables.query.filter) {
      if (selectedItems.careerLevels) {
        variables.query.filter.careerLevel = { id: selectedItems.careerLevels };
      }
      if (selectedItems.disciplines) {
        variables.query.filter.discipline = { id: selectedItems.disciplines };
      }
      if (selectedItems.cities) {
        variables.query.filter.city = { id: selectedItems.cities };
      }
    }

    await refetch(variables);

    setOffset(LIST_LIMIT);
  };

  const aggregations = data?.jobSearchByQuery?.aggregations;

  if (isError(data, loading)) return <RenderError refetch={refetch} />;

  return (
    <>
      {isLoggedIn && (
        <JobsFilter
          // @ts-expect-error TODO: fix this type
          aggregations={aggregations}
          onFiltersChanges={handleFilterChange}
        />
      )}
      <JobsDetail
        loading={loadingFirstRender}
        onLazyLoad={handlePagination}
        // @ts-expect-error TODO: fix this type
        jobResults={data?.jobSearchByQuery?.collection}
        totalJobs={data?.jobSearchByQuery?.total}
        searchQuery={data?.jobSearchByQuery?.searchQuery}
        paginationLoading={networkStatus === 3}
      />
    </>
  );
};
