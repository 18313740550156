import { useContext } from 'react';

import { ContactsEditContext } from '../../contexts/contacts-edit-context/contacts-edit-context';

export const useContactsEditContext = () => {
  const context = useContext(ContactsEditContext);

  if (!context) {
    throw new Error(
      'useContactsEditContext must be used within a ContactsEditContextProvider'
    );
  }

  return context;
};
