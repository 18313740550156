import type { FC } from 'react';

import * as Styled from './search-input-card.styles';

export const SearchInputCardSkeleton: FC = () => {
  return (
    <Styled.Container>
      <Styled.InfoWrapper>
        <Styled.LogoImageContainer />
        <Styled.UserInfo>
          {/* @ts-expect-error FIXME: SC6 */}
          <Styled.TitleSkeleton size="small" amountRows={1} />
          {/* @ts-expect-error FIXME: SC6 */}
          <Styled.BodySkeleton size="small" amountRows={2} />
        </Styled.UserInfo>
      </Styled.InfoWrapper>
    </Styled.Container>
  );
};
