import type { FC } from 'react';

import { EmployeesCardHorizontal } from '../employees-card-horizontal/employees-card-horizontal';
import * as Styled from './employees-grid.styles';

export const EmployeesGridSkeleton: FC = () => {
  return (
    <Styled.EmployeesGridContainer>
      <EmployeesCardHorizontal.Skeleton />
      <EmployeesCardHorizontal.Skeleton />
    </Styled.EmployeesGridContainer>
  );
};
