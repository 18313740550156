import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  SkeletonProfileImage as OriginalSkeletonProfileImage,
  SkeletonBody as OriginalSkeletonBody,
  SkeletonImage as OriginalSkeletonImage,
} from '@xing-com/skeleton';
import {
  space3XL,
  spaceL,
  scale110,
  scale100,
  scale060,
  scale160,
} from '@xing-com/tokens';

import { Actor } from './news-item-actor/news-item-actor.styles';
import { Content } from './news-item-attachment/news-item-attachment.styles';

export const Item = styled.article<{ $isItemLoading: boolean }>`
  margin-bottom: ${space3XL};
  opacity: ${({ $isItemLoading }) => ($isItemLoading ? 0.5 : 1)};
`;

export const Comment = styled.section`
  overflow-wrap: break-word;
  position: relative;
  line-height: ${scale110};
  white-space: pre-line;

  & + ${Content} {
    margin-top: ${spaceL};
  }
  @media ${mediaSmallOrTiny} {
    line-height: ${scale100};
    & + ${Content} {
      margin-top: ${scale060};
    }
  }
`;

export const ItemActor = styled.div<{ $isNoActor: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $isNoActor }) => $isNoActor && spaceL};
  & + ${Comment}, & + ${Content} {
    margin-top: ${spaceL};
  }

  @media ${mediaSmallOrTiny} {
    width: 100%;
    max-width: 100%;
    flex: 1 1 auto;
    min-width: 170px;
  }
`;

export const NoActorComment = styled.section`
  overflow-wrap: break-word;
  position: relative;
  line-height: ${scale110};
  white-space: pre-line;
  @media ${mediaSmallOrTiny} {
    line-height: ${scale100};
  }
`;

// Skeleton
export const SkeletonActor = styled(Actor)<{ smallActor: boolean }>`
  margin-bottom: ${spaceL};
  width: ${({ smallActor }) => smallActor && '50px'};
`;
export const SkeletonActorContent = styled.div`
  margin-right: ${spaceL};
  min-width: 0;
  width: 225px;
  display: flex;
  align-items: center;
`;
export const SkeletonProfileImage = styled(OriginalSkeletonProfileImage)`
  width: ${scale160};
`;
export const SkeletonBodyWrapper = styled.div`
  margin-right: 20px;
  flex-grow: 1;
  @media ${mediaSmallOrTiny} {
    margin: 0;
  }
`;
export const SkeletonBody = styled(OriginalSkeletonBody)`
  width: 100%;
  min-width: 100%;
`;
export const SkeletonImage = styled(OriginalSkeletonImage)`
  object-fit: contain;
  max-height: 180px;
  margin-left: auto;
  flex-shrink: 0;
  width: 272px;
  height: 153px !important;
  @media ${mediaSmallOrTiny} {
    flex-basis: 170px;
    height: auto !important;
  }
`;
