import type { FC, PropsWithChildren } from 'react';

import { ErrorBoundary } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
// eslint-disable-next-line monorepo/forbidden-imports
import { ZenView } from '@xing-com/platform-layout-zen';
import { GridContainer } from '@xing-com/xing-grid';

import { TopBar } from '../top-bar/top-bar';

type LayoutProps = {
  isZenView?: boolean;
  isMainPage?: boolean;
  isWebview?: boolean;
};
export const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  isZenView,
  isMainPage,
  isWebview,
}) => {
  const { isLoggedIn } = useLoginState();

  if (isWebview) {
    return <>{children}</>;
  }

  if (isZenView) {
    return (
      <ZenView>
        <ErrorBoundary type={500}>{children}</ErrorBoundary>
      </ZenView>
    );
  }

  if (isLoggedIn) {
    return (
      <GridContainer>
        <TopBar isMainPage={isMainPage} />
        <ErrorBoundary type={500}>{children}</ErrorBoundary>
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      <ErrorBoundary type={500}>
        <TopBar isMainPage={isMainPage} />
        {children}
      </ErrorBoundary>
    </GridContainer>
  );
};
