import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { IconWarning } from '@xing-com/icons';
import { BodyCopy } from '@xing-com/typography';

import * as Styled from './search-input-list-cards.styles';

export const SearchInputListCardsError: FC = () => {
  const { $t } = useIntl();

  return (
    <Styled.SearchErrorContainer>
      <Styled.IconWarning>
        <IconWarning width={16} height={16} />
      </Styled.IconWarning>
      <BodyCopy noMargin size="small">
        {$t({ id: 'EP_CONTACTS_SEARCH_INPUT_ERROR' })}
      </BodyCopy>
    </Styled.SearchErrorContainer>
  );
};
