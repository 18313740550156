import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';
import {
  EntityPageContractType,
  PostingPublicationState,
  type EntityPageFocusType,
} from '@xing-com/crate-common-graphql-types';
import {
  UpsellPopOver,
  useDialogContext,
  useErrorContext,
  useModuleContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import {
  getIDfromURN,
  trackPinAction,
  trackUnpinAction,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { IconStar, IconStarFilled } from '@xing-com/icons';
import { usePopOver } from '@xing-com/pop-over';

import { PinDocument } from '../../../graphql/mutations/pin-activity.gql-types';
import { UnpinDocument } from '../../../graphql/mutations/unpin-activity.gql-types';
import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';
import * as Styled from './news-item-actions.styles';

type ActionPinProps = {
  desktop?: boolean;
  itemId?: string;
  onItemActionCompleted?: () => void;
  onItemActionStarted?: () => void;
};
export const ActionPin: FC<ActionPinProps> = ({
  desktop = true,
  itemId,
  onItemActionCompleted = () => undefined,
  onItemActionStarted = () => undefined,
}) => {
  const { $t } = useIntl();
  const { setDialogConfirmation } = useDialogContext();
  const { showError } = useErrorContext();
  const { moduleContext } = useModuleContext();
  const { post, publicationState } = useNewsItemContext();
  const { pageContext } = usePageContext() ?? {};
  const popOver = usePopOver();

  const isPaidPage =
    pageContext?.contractType === EntityPageContractType.PaidPlus ||
    pageContext?.contractType === EntityPageContractType.Paid;
  const isPinnedOldArticle =
    post.object?.__typename !== 'EntityPageContentFeedPostingPost' &&
    post.isPinned;
  const isPinned = post.isPinned || isPinnedOldArticle;

  const [pinActivity, { loading: pinLoading }] = useMutation(PinDocument);
  const [unpinActivity, { loading: unpinLoading }] = useMutation(UnpinDocument);

  const handleOnClick = () => {
    if (!itemId) {
      return;
    }
    onItemActionStarted();
    if (isPinned) {
      // setActionConfirmDialog(actionsDescriptionObj.unpin);
      setDialogConfirmation({
        headline: $t({ id: 'NEWS_MODULE_DIALOG_UNPIN_HEADLINE' }),
        text: $t({ id: 'NEWS_MODULE_DIALOG_UNPIN_TEXT' }),
        cancel: $t({ id: 'NEWS_MODULE_DIALOG_UNPIN_NO' }),
        confirm: $t({ id: 'NEWS_MODULE_DIALOG_UNPIN_YES' }),
        dialogAction: () => {
          unpinActivity({
            variables: {
              targetGlobalId: moduleContext?.moduleGlobalId,
            },
            onCompleted: (data) => {
              const error = data?.postingsUnpinActivityByTarget?.error;

              if (error) {
                showError(error);
              } else {
                onItemActionCompleted();
                trackUnpinAction({
                  itemId,
                  pageId: pageContext?.pageId as string,
                  focusType: pageContext?.focusType as EntityPageFocusType,
                });
              }
              setDialogConfirmation(null);
            },
          });
        },
      });
    } else {
      pinActivity({
        variables: {
          activityId: getIDfromURN(post?.globalId),
          targetGlobalId: moduleContext?.moduleGlobalId,
        },
        onCompleted: (data) => {
          const error = data.postingsPinActivityByTarget?.error;

          if (error) {
            showError(error);
          } else {
            onItemActionCompleted();

            trackPinAction({
              itemId,
              pageId: pageContext?.pageId as string,
              focusType: pageContext?.focusType as EntityPageFocusType,
            });
          }
        },
      });
    }
  };

  const isLoading = pinLoading || unpinLoading;
  const disabled =
    !post?.globalId ||
    !isPaidPage ||
    isLoading ||
    publicationState === PostingPublicationState.Hidden;

  const handlePopOverAction = (action: () => void) => {
    if (disabled) action();
  };

  if (desktop === false) {
    return (
      <>
        <TextButton
          size="medium"
          icon={isPinned ? IconStarFilled : IconStar}
          // @ts-expect-error FIXME: SC6
          ariaLabel={isPinned ? 'Star filled' : 'Star empty'}
          data-testid="PIN_POSTING"
          onClick={handleOnClick}
          innerRef={popOver.triggerRef}
          onMouseOver={() => handlePopOverAction(popOver.handleShow)}
          onMouseLeave={() => handlePopOverAction(popOver.handleHide)}
          disabled={disabled}
        >
          {$t({
            id: isPinned
              ? 'NEWS_MODULE_UNPIN_BUTTON'
              : 'NEWS_MODULE_PIN_BUTTON',
          })}
        </TextButton>
        <UpsellPopOver moduleName="newsmodule" popOver={popOver} />
      </>
    );
  }

  return (
    <>
      <Styled.EditButton
        size="small"
        icon={isPinned ? IconStarFilled : IconStar}
        ariaLabel={isPinned ? 'Star filled' : 'Star empty'}
        data-testid="PIN_POSTING"
        onClick={handleOnClick}
        innerRef={popOver.triggerRef}
        onMouseOver={() => handlePopOverAction(popOver.handleShow)}
        onMouseLeave={() => handlePopOverAction(popOver.handleHide)}
        disabled={disabled}
      />
      <UpsellPopOver moduleName="newsmodule" popOver={popOver} />
    </>
  );
};
