import { useMutation, type ApolloError } from '@apollo/client';
import type { FC } from 'react';

import * as Types from '@xing-com/crate-common-graphql-types';
import {
  usePageContext,
  PublishPageMutationDocument,
  useErrorContext,
} from '@xing-com/crate-entity-pages-common';
import { IllustrationPremiumSpotWeb } from '@xing-com/illustrations';

import * as Styled from './republish-banner.styles';

export const RepublishBanner: FC = () => {
  const { showError } = useErrorContext();
  const { pageContext } = usePageContext() ?? {};

  const [publishPage] = useMutation(PublishPageMutationDocument, {
    onCompleted: (data) => {
      const errorType =
        data?.entityPageUpdatePublicationStatus?.error?.errorType;

      if (errorType) {
        showError({
          message: 'EP_GENERIC_FEEDBACK_ERROR',
          error: errorType,
        });
      } else {
        window.location.reload();
      }
    },
    onError: (error: ApolloError) => {
      showError({
        message: 'EP_GENERIC_FEEDBACK_ERROR',
        error: error?.message,
      });
    },
  });

  return (
    <Styled.InfoState
      icon={<IllustrationPremiumSpotWeb size="small" />}
      textCopyKey="EP_DRAFT_REPUBLISH_DESCRIPTION"
      buttonProps={[
        {
          // @ts-expect-error FIXME: SC6
          copyKey: 'EP_DRAFT_REPUBLISH_CTA',
          onClick: () =>
            publishPage({
              variables: {
                input: {
                  // @ts-expect-error TODO: fix this type
                  pageId: pageContext?.pageId,
                  publicationStatus:
                    Types.EntityPagePublicationStatus.Published,
                },
              },
            }),
        },
      ]}
    />
  );
};
