import styled, { css } from 'styled-components';

import { ProfileInfoAdapter as ProfileInfo } from '@xing-com/crate-entity-pages-common';
import { mediaSmallOnly } from '@xing-com/layout-tokens';
import {
  spaceXS,
  spaceS,
  spaceL,
  xdlColorBorderSoft,
  xdlColorBackground,
  xdlColorInfoSoft,
} from '@xing-com/tokens';
import { Meta } from '@xing-com/typography';

export const Tag = styled.div<{
  $isBeingEdited?: boolean;
  $isInactive?: boolean;
  $isDeleted?: boolean;
}>`
  padding: ${spaceL};
  border-top: 1px solid ${xdlColorBorderSoft};
  display: block;

  ${({ $isBeingEdited }) =>
    $isBeingEdited &&
    css`
      background-color: ${xdlColorBackground};

      .editText {
        margin-bottom: ${spaceS};
      }
    `}

  ${({ $isInactive }) =>
    $isInactive &&
    css`
      opacity: 0.5;
    `}
  
     ${({ $isDeleted }) =>
    $isDeleted &&
    css`
      background-color: ${xdlColorInfoSoft};
    `}
`;

export const ProfileInfoActionsWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const ContactsProfileInfo = styled(ProfileInfo)<{
  isDisabled?: boolean;
  itemChoosed?: boolean;
}>`
  flex-grow: 1;
  overflow: hidden;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}

  ${({ itemChoosed }) =>
    itemChoosed &&
    css`
      & svg {
        display: block;
      }
    `}
`;

export const ItemsActionWrapper = styled.div`
  margin-left: ${spaceL};
  display: flex;
  flex-shrink: 0;
  min-width: 128px;
  justify-content: flex-end;

  @media ${mediaSmallOnly} {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const OwnerDisclaimerWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${spaceL};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

export const OwnerMeta = styled(Meta)`
  margin-left: ${spaceXS};
  margin-bottom: 0;

  & a {
    color: inherit;
    font-weight: 700;
  }
`;
