import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { ProfileInfoAdapter as DefaultProfileInfo } from '@xing-com/crate-entity-pages-common';

export const ContactCardWrapper = styled.div``;

export const ProfileInfo = styled(DefaultProfileInfo)`
  > p {
    margin-bottom: 0;
  }
`;

export const MessageButton = styled(Button)`
  & span:first-of-type {
    margin-right: 0;
  }
`;
