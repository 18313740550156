import styled from 'styled-components';

import { Button as InternalButton } from '@xing-com/button';
import { EditButton as InternalEditButton } from '@xing-com/crate-entity-pages-common';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceM, spaceXXL, scale100 } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Wrapper = styled.div`
  @media ${mediaConfined} {
    min-height: 0;
  }
`;

export const AboutUsWrapper = styled.div`
  display: flex;
  position: relative;

  @media ${mediaSmallOrTiny} {
    display: block;
  }
`;

export const UpsellLayout = styled.div`
  margin-bottom: ${spaceXXL};
`;

export const SummaryAwardsWrapper = styled.div`
  flex: 1;
`;

export const Headline = styled(BodyCopy)`
  display: flex;
  font-size: ${scale100};
`;

export const EditButton = styled(InternalEditButton)`
  margin-left: ${spaceM};
`;

export const DescriptionText = styled(BodyCopy)`
  line-height: 1.5;
  flex: 1;
  white-space: pre-line;
`;

export const ButtonWrapper = styled.span`
  @media ${mediaConfined} {
    min-height: 0;
  }
`;

export const Button = styled(InternalButton)`
  margin-top: ${spaceXXL};
`;
