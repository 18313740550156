import { createContext } from 'react';

import type { PostingPublicationState } from '@xing-com/crate-common-graphql-types';

import type { EntityPageFeedCollectionFragment } from '../../graphql/fragments/entity-page-feed-collection-fragment.gql-types';

export type NewsItemContextType = {
  post: EntityPageFeedCollectionFragment;
  publicationState?: PostingPublicationState;
};
export const NewsItemContext = createContext<NewsItemContextType | undefined>(
  undefined
);
