import styled from 'styled-components';

import { Button as InnerButton } from '@xing-com/button';
import { ProfileInfoAdapter as ProfileInfo } from '@xing-com/crate-entity-pages-common';
import type { ProfileInfoAdapterProps } from '@xing-com/crate-entity-pages-common/src/components/profile-info-adapter/profile-info-adapter';
import { mediaConfinedOnly, mediaSmallOnly } from '@xing-com/layout-tokens';
import { spaceL, spaceM, spaceS } from '@xing-com/tokens';

const cardWidth = '402px';
export const EmployeesCard: React.FC<ProfileInfoAdapterProps> = styled(
  ProfileInfo
)`
  > a {
    padding: 2px;
  }
`;
export const Card = styled.div`
  width: ${cardWidth};
  margin-bottom: ${spaceL};

  @media ${mediaSmallOnly} {
    width: 100%;
  }

  @media ${mediaConfinedOnly} {
    width: 100%;
  }
`;
export const ClampedText = styled.span`
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${mediaSmallOnly} {
    max-width: 180px;
  }

  @media ${mediaConfinedOnly} {
    max-width: 100%;
  }
`;
export const HeadlineFlagWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  & > ${ClampedText} {
    margin-right: ${spaceS};
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const AddContactButton = styled(InnerButton)`
  padding: 0;
  & span:first-of-type {
    margin-right: 0;
  }
`;

// Skeleton
export const SkeletonWrapper = styled.div`
  display: flex;
  width: ${cardWidth};
  margin-bottom: ${spaceL};
`;
export const SkeletonProfileImage = styled.div`
  height: 96px;
  width: 96px;
  flex-shrink: 0;
  margin-right: ${spaceM};
`;
export const SkeletonProfileInfo = styled.div`
  width: 100%;
`;
