import { usePageContext } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import * as Styled from './entity-info.styles';

type EntityInfoDataType = {
  value?: JSX.Element | string;
  label?: JSX.Element | string;
  className?: string;
  testId?: string;
};

const renderInfoBlock = (block: EntityInfoDataType, key: number) => (
  <Styled.EntityInfoBlock
    key={key}
    singleLine={block?.className === 'singleLine'}
    className={block?.className}
  >
    <Styled.InfoBlockText
      size={'small'}
      sizeWide={'medium'}
      data-testid={block.testId}
    >
      <Styled.CondensedEntityInfoBlockValue>
        {block.value}
      </Styled.CondensedEntityInfoBlockValue>
      <Styled.Label>{block.label}</Styled.Label>
    </Styled.InfoBlockText>
  </Styled.EntityInfoBlock>
);

type EntityInfoProps = {
  data: EntityInfoDataType[];
  className?: string;
};
export const EntityInfo = ({ data, className }: EntityInfoProps) => {
  const { isLoggedOut } = useLoginState();
  const { pageContext } = usePageContext() ?? {};

  const socialProof = pageContext?.socialProof;

  const condensed = isLoggedOut || socialProof?.total === 0;

  if (data.length === 0) {
    return null;
  }

  return (
    <Styled.EntityInfoBlockWrapper
      condensed={!!condensed}
      className={className}
    >
      {data.map((item, key) => renderInfoBlock(item, key))}
    </Styled.EntityInfoBlockWrapper>
  );
};
